import React, { useEffect } from "react";
import * as d3 from "d3";
import "./style.css"

const CongressOverview = () => {
    const mediaList = [
        {
            "totalBills": 398410,
            "legislatorCount": 12075,
            "chinaRelatedBills": 5879,
            "timeRange": "1979-2023",
            "congressionalLevel": "96-118",
            "dimensionCount": 16,
            "sovereigntyBills": 1484,
            "securityBills": 3329,
            "developmentBills": 3708
        },
        {
            "totalBills": 11491,
            "legislatorCount": 525,
            "chinaRelatedBills": 677,
            "timeRange": "2023-2023",
            "congressionalLevel": 118,
            "dimensionCount": 16,
            "sovereigntyBills": 143,
            "securityBills": 393,
            "developmentBills": 425
        },
        {
            "totalBills": 24789,
            "legislatorCount": 525,
            "chinaRelatedBills": 1331,
            "timeRange": "2021-2022",
            "congressionalLevel": 117,
            "dimensionCount": 16,
            "sovereigntyBills": 291,
            "securityBills": 765,
            "developmentBills": 881
        },
        {
            "totalBills": 20253,
            "legislatorCount": 525,
            "chinaRelatedBills": 627,
            "timeRange": "2019-2020",
            "congressionalLevel": 116,
            "dimensionCount": 16,
            "sovereigntyBills": 165,
            "securityBills": 347,
            "developmentBills": 427
        },
        {
            "totalBills": 18732,
            "legislatorCount": 525,
            "chinaRelatedBills": 288,
            "timeRange": "2017-2018",
            "congressionalLevel": 115,
            "dimensionCount": 16,
            "sovereigntyBills": 63,
            "securityBills": 197,
            "developmentBills": 186
        },
        {
            "totalBills": 18746,
            "legislatorCount": 525,
            "chinaRelatedBills": 199,
            "timeRange": "2015-2016",
            "congressionalLevel": 114,
            "dimensionCount": 16,
            "sovereigntyBills": 37,
            "securityBills": 135,
            "developmentBills": 124
        },
        {
            "totalBills": 15704,
            "legislatorCount": 525,
            "chinaRelatedBills": 186,
            "timeRange": "2013-2014",
            "congressionalLevel": 113,
            "dimensionCount": 16,
            "sovereigntyBills": 41,
            "securityBills": 122,
            "developmentBills": 129
        },
        {
            "totalBills": 17254,
            "legislatorCount": 525,
            "chinaRelatedBills": 212,
            "timeRange": "2011-2012",
            "congressionalLevel": 112,
            "dimensionCount": 16,
            "sovereigntyBills": 45,
            "securityBills": 121,
            "developmentBills": 139
        },
        {
            "totalBills": 19393,
            "legislatorCount": 525,
            "chinaRelatedBills": 254,
            "timeRange": "2009-2010",
            "congressionalLevel": 111,
            "dimensionCount": 16,
            "sovereigntyBills": 51,
            "securityBills": 127,
            "developmentBills": 157
        },
        {
            "totalBills": 20931,
            "legislatorCount": 525,
            "chinaRelatedBills": 252,
            "timeRange": "2007-2008",
            "congressionalLevel": 110,
            "dimensionCount": 16,
            "sovereigntyBills": 35,
            "securityBills": 129,
            "developmentBills": 163
        },
        {
            "totalBills": 19534,
            "legislatorCount": 525,
            "chinaRelatedBills": 229,
            "timeRange": "2005-2006",
            "congressionalLevel": 109,
            "dimensionCount": 16,
            "sovereigntyBills": 58,
            "securityBills": 106,
            "developmentBills": 155
        },
        {
            "totalBills": 15569,
            "legislatorCount": 525,
            "chinaRelatedBills": 164,
            "timeRange": "2003-2004",
            "congressionalLevel": 108,
            "dimensionCount": 16,
            "sovereigntyBills": 52,
            "securityBills": 89,
            "developmentBills": 108
        },
        {
            "totalBills": 16407,
            "legislatorCount": 525,
            "chinaRelatedBills": 135,
            "timeRange": "2001-2002",
            "congressionalLevel": 107,
            "dimensionCount": 16,
            "sovereigntyBills": 44,
            "securityBills": 85,
            "developmentBills": 88
        },
        {
            "totalBills": 16274,
            "legislatorCount": 525,
            "chinaRelatedBills": 215,
            "timeRange": "1999-2000",
            "congressionalLevel": 106,
            "dimensionCount": 16,
            "sovereigntyBills": 75,
            "securityBills": 122,
            "developmentBills": 121
        },
        {
            "totalBills": 13914,
            "legislatorCount": 525,
            "chinaRelatedBills": 230,
            "timeRange": "1997-1998",
            "congressionalLevel": 105,
            "dimensionCount": 16,
            "sovereigntyBills": 90,
            "securityBills": 129,
            "developmentBills": 125
        },
        {
            "totalBills": 14802,
            "legislatorCount": 525,
            "chinaRelatedBills": 132,
            "timeRange": "1995-1996",
            "congressionalLevel": 104,
            "dimensionCount": 16,
            "sovereigntyBills": 51,
            "securityBills": 76,
            "developmentBills": 85
        },
        {
            "totalBills": 13417,
            "legislatorCount": 525,
            "chinaRelatedBills": 135,
            "timeRange": "1993-1994",
            "congressionalLevel": 103,
            "dimensionCount": 16,
            "sovereigntyBills": 56,
            "securityBills": 75,
            "developmentBills": 86
        },
        {
            "totalBills": 16349,
            "legislatorCount": 525,
            "chinaRelatedBills": 159,
            "timeRange": "1991-1992",
            "congressionalLevel": 102,
            "dimensionCount": 16,
            "sovereigntyBills": 51,
            "securityBills": 98,
            "developmentBills": 92
        },
        {
            "totalBills": 15927,
            "legislatorCount": 525,
            "chinaRelatedBills": 200,
            "timeRange": "1989-1990",
            "congressionalLevel": 101,
            "dimensionCount": 16,
            "sovereigntyBills": 53,
            "securityBills": 112,
            "developmentBills": 92
        },
        {
            "totalBills": 15968,
            "legislatorCount": 525,
            "chinaRelatedBills": 74,
            "timeRange": "1987-1988",
            "congressionalLevel": 100,
            "dimensionCount": 16,
            "sovereigntyBills": 27,
            "securityBills": 32,
            "developmentBills": 44
        },
        {
            "totalBills": 16362,
            "legislatorCount": 525,
            "chinaRelatedBills": 58,
            "timeRange": "1985-1986",
            "congressionalLevel": 99,
            "dimensionCount": 16,
            "sovereigntyBills": 10,
            "securityBills": 27,
            "developmentBills": 32
        },
        {
            "totalBills": 20568,
            "legislatorCount": 525,
            "chinaRelatedBills": 49,
            "timeRange": "1983-1984",
            "congressionalLevel": 98,
            "dimensionCount": 16,
            "sovereigntyBills": 15,
            "securityBills": 26,
            "developmentBills": 26
        },
        {
            "totalBills": 21436,
            "legislatorCount": 525,
            "chinaRelatedBills": 20,
            "timeRange": "1981-1982",
            "congressionalLevel": 97,
            "dimensionCount": 16,
            "sovereigntyBills": 3,
            "securityBills": 7,
            "developmentBills": 9
        },
        {
            "totalBills": 14590,
            "legislatorCount": 525,
            "chinaRelatedBills": 53,
            "timeRange": "1979-1980",
            "congressionalLevel": 96,
            "dimensionCount": 16,
            "sovereigntyBills": 28,
            "securityBills": 9,
            "developmentBills": 14
        }
    ];
    useEffect(() => {
        const container = d3.select("#congress-overview-container");
        const mediaItems = container.selectAll(".congress-item")
            .data(mediaList)
            .enter()
            .append("div")
            .attr("class", "congress-item");


        mediaItems.filter((d, i) => i === 0)
            .classed("first-item", true);


        const line_one = mediaItems.append("div")
            .attr("class", "line_one");
        const line_two = mediaItems.append("div")
            .attr("class", "line_two");
        const line_three = mediaItems.append("div")
            .attr("class", "line_three");


        line_one.append("div")
            .attr("class", (d, i) => (i === 0 ? "first-item-media-time" : "media-time"))
            .html((d, i) => (i === 0 ? `<div class="media-time-up">${d.totalBills}</div><div class="media-time-low">法案总量</div>` : `<div class="media-time-up">${d.totalBills}</div><div class="media-time-low">当届法案总量</div>`));

        line_one.append("div")
            .attr("class", (d, i) => (i === 0 ? " first-item-media-rate" : "media-rate"))
            .html(d => `<div class="media-time-up">${d.congressionalLevel}</div><div class="media-time-low">国会界别</div>`);


        line_one
            .append("div")
            .attr("class",  "stat-item")
            .html((d, i) => {
                if (i === 0) {
                    return `<p class="first-value">${d.sovereigntyBills}</p><p class="first-unit">主权类法案</p>`;
                } else {
                    return `<p class="value">${d.sovereigntyBills}</p><p class="unit">主权类法案</p>`;
                }
            });
  

        line_two.append("div")
            .attr("class", (d, i) => (i === 0 ? " first-congress_21" : "congress_21"))
            .html(d => `<div class="media-time-up">${d.legislatorCount}</div><div class="media-time-low">议员人次</div>`);

        line_two.append("div")
            .attr("class", (d, i) => (i === 0 ? " first-congress_22" : "congress_22"))
            .html(d => `<div class="media-time-up">${d.chinaRelatedBills}</div><div class="media-time-low">涉华法案数</div>`);

        line_two.append("div")
            .attr("class", (d, i) => (i === 0 ? " first-congress_22" : "congress_22"))
            .html(d => `<div class="media-time-up">${d.dimensionCount}</div><div class="media-time-low">分析维度数量</div>`);

        line_two
            .append("div")
            .attr("class",  "stat-item")
            .html((d, i) => {
                if (i === 0) {
                    return `<p class="first-value">${d.securityBills}</p><p class="first-unit">安全类法案</p>`;
                } else {
                    return `<p class="value">${d.securityBills}</p><p class="unit">安全类法案</p>`;
                }
            });

        const congress_23 = line_three.append("div")
            .attr("class", (d, i) => (i === 0 ? " first-congress_23" : "congress_23"))

        congress_23.append("div")
            .attr("class", "congress_23_container")
            .html(d => `<div class="time_text">时间范围</div><div class="media-time-range">${d.timeRange}</div>`);
        line_three
            .append("div")
            .attr("class", "stat-item")
            .html((d, i) => {
                if (i === 0) {
                    return `<p class="first-value">${d.developmentBills}</p><p class="first-unit">发展类法案</p>`;
                } else {
                    return `<p class="value">${d.developmentBills}</p><p class="unit">发展类法案</p>`;
                }
            });



    }, []);

    return (
        <div id="congress-overview-container" style={{overflow: "auto", maxHeight: "95%" }}>
        </div>
    );
}

export default CongressOverview;