import React from 'react';
import "./style.css"
const picIndex = Array.from({ length: 10 }, (_, i) => (i + 1).toString())
const linkPage = "http://datastorydemo.idvxlab.com/"

function Case() {
    const clickNewPage = () => {
        window.open(linkPage,'__blank')
    }
    return (
        <div className='case-page'>
            {
                picIndex.map((item) => (
                    <img src={"./case/" + item + ".png"} style={{ width: "100%" }}></img>
                ))
            }
            <div className='link-box'>
                <div className='link-box-btn' onClick={clickNewPage}>
                    {linkPage}
                </div>
            </div>
        </div>
    );
}

export default Case;