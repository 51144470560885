import React from 'react';
import { Row, Col } from 'antd';
import ChartCard from '../../component/ChartCart';
import ThinkOverview from '../../charts/ThinkOverview';
import ThinkInfluenceTrend from '../../charts/ThinkInfluenceTrend';
import Spectrum from '../../charts/Spectrum';
import ThinkScatterplot from '../../charts/ThinkScatterplot';
import ThinkList from '../../charts/ThinkList'
import ArticleInfluence from '../../charts/ArticleInfluence';

function Think() {
    return (
        <div>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='1. 智库库数据结构与量级概况'>
                    <ThinkOverview></ThinkOverview>
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '70vh', width: '80wh', margin: '10px' }}>
                <Col span={10} style={{ height: '70vh' }}>
                    <ChartCard title='2. 智库领域影响力排名算法' description='该模块使用了智库领域影响力分析算法。该算法针对智库在外交政策和国际事务、国际经济政策和国际发展政策三大领域的影响力，从组织基础、资金基础、领域声誉、领域知识产出能力以及领域政策影响能力5大维度共11个变量评估得出，并对智库在三大领域及其综合影响进行定序排名。'>
                        <ThinkList />
                    </ChartCard>
                </Col>
                <Col span={14} style={{ height: '70vh' }}>
                    <ChartCard title='3. 智库涉华决策影响力分析算法' description='展示特定时间段智库涉华决策影响力的比较发展。
该模块使用了涉华决策影响力分析算法。该算法根据反映智库在涉华决策上的能力水平、关系渠道和过程效果3大维度的12个指标分析而成。能力水平维度测量了智库的人员规模、经费预算、专家知识和智库声誉，关系渠道测量了智库的机构性质和政府亲疏，过程效果测量了智库在领域圈、媒体圈、知识圈、智库圈以及国会和政府五个政策过程阶段的影响效果。'>
                        <ThinkInfluenceTrend></ThinkInfluenceTrend>
                    </ChartCard>
                </Col>
            </Row>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='4. 智库涉华决策影响力-对华态度坐标系构建' description='根据智库综合影响力和对华态度划分成团结域、支持域、斗争域、风险域四个象限。 该模块使用了智库对华态度影响力算法。该模块使用了精英对华态度算法。该算法由涉华主题领域（主权、安全、发展）和涉华态度（支持、友善、关切、遏制、敌视）两个层次的指标构成，最终经层次分析法得权重整合为对华态度的合成指标。该算法对智库报告对华态度进行累计测量后，最终经标准化处理后分配给对应智库。'>
                    <ThinkScatterplot></ThinkScatterplot>
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '80vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='5. 智库意识形态光谱分析算法' description='该模块使用了智库意识形态光谱分析算法。该算法由代表自由主义-保守主义分野的标题倾向性和素材选择性和代表党派性-独立性的政治倾向性和支持溯源性两个层次的指标构成，最终经层次分析法的权重整合为在当届政府中亲共和党和亲民主党程度形成五分类的合成指标。该算法使用智库报告和政策法案的文本数据与机构属性和政党偏好的历史数据进行计算。'>
                    <Spectrum />
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='6. 智库报告影响力排名推荐算法' description='展示每家智库特定时间段影响力最高的五篇研究报告。 该模块使用了智库报告影响力算法。该算法根据反映报告内容质量、组织质量和政策质量3个维度的6个指标分析而成。内容质量测量了报告的知识含量与作者类型，组织质量测量了该智库的综合影响力及其在报告相关议题上的领域影响力，政策质量测量了该报告相关议题在美国涉华政策中的热度与涉我核心利益的程度。'>
                    <ArticleInfluence></ArticleInfluence>
                </ChartCard>
            </Row>
        </div>
    );
}

export default Think;