import React, { useEffect} from 'react';
import * as d3 from 'd3';

const Spectrum = () => {
    // Create a ref to attach the SVG element to.
  
    useEffect(() => {

    // Define the dimensions and margins for the scatter plot.
    const margin = { top: 50, bottom: 80, left: 55, right: 70 };
    // const margin = { top: 30, bottom: 170, left: 80, right: 80 };
    let width=document.getElementById("spectrum-chart-container").offsetWidth - margin.left - margin.right;
    let height=document.getElementById("spectrum-chart-container").offsetHeight - margin.top - margin.bottom;
    // const width = window.innerWidth - margin.left - margin.right;
    // const height = window.innerHeight*0.84 - margin.top - margin.bottom;

    // Create an SVG element for the scatter plot.
    const svg = d3.select("#spectrum-chart-container")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const tooltip = d3.select("#spectrum-chart-container")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

    // Load data from data.csv and create the scatter plot.
    d3.csv("SpectrumData.csv").then(function(data) {
        data.forEach(function(d) {
            d.ideology = +d.ideology;
            d.significance = +d.significance;
        });

    // Create a linear gradient definition
    const gradient = svg.append("defs")
        .append("linearGradient")
        .attr("id", "background-gradient")
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%");

        // Add the gradient stops
    gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "#9296FF");

    gradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "#FF8E8E");

        // Apply the gradient to a rectangle that covers the entire SVG
    svg.append("rect")
        .attr("x", 0)
        .attr("y",0)
        .attr("width", width)
        .attr("height", height)
        .style("fill", "url(#background-gradient)")
        .style("opacity", 0.2);


        // Create scales for x and y axes.
        const xScale = d3.scaleLinear()
            .domain([-2, 2])
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([0, 1])
            .range([height, 0]);

        // Create x and y axes.
        const xAxis = d3.axisBottom(xScale).ticks(5);
        const yAxis = d3.axisRight(yScale);

        // Append axes to the SVG.
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis)
            .style("font-size", "13px");
            
        // Add grid lines
        svg.selectAll(".x-axis")
            .append("g")
            .attr("class", "grid-lines")
            .selectAll("line")
            .data(xScale.ticks(5))
            .enter()
            .append("line")
            .attr("class", "grid-line")
            .attr("x1", d => xScale(d))
            .attr("x2", d => xScale(d))
            .attr("y1", -height)
            .attr("y2", 0) 
            .style("stroke", "#D3D6DF")
            .style("stroke-dasharray", "2,2");

        svg.append("text")
            .attr("class", "x-label")
            .attr("x", width-20)
            .attr("y", height+40)
            .style("text-anchor", "middle")
            .text("亲共和党")
            .style("font-size", "14px");

        svg.append("text")
            .attr("class", "x-label")
            .attr("x", 20)
            .attr("y", height+40)
            .style("text-anchor", "middle")
            .text("亲民主党")
            .style("font-size", "14px");


        svg.append("g")
            .attr("class", "y-axis")
            .attr("transform", `translate(${width/2}, 0)`)
            .call(yAxis)
            .style("font-size", "13px");
            

        svg.append("text")
            .attr("class", "y-label")
            .attr("x", width/2)
            .attr("y", -30)
            .style("text-anchor", "middle")
            .text("影响力")
            .style("font-size", "14px");;

        // Create circles for the scatter plot.
        const circles = svg.selectAll(".circle")
            .data(data)
            .enter()
            .append("g")
            .attr("class", "circle");

        // Define a clipping path as a circle for each data point.
        circles.append("clipPath")
        .attr("id", (d, i) => "clip2" + i) // Create a unique ID for each clipping path
        .append("circle")
        .attr("cx", d => xScale(d.ideology))
        .attr("cy", d => yScale(d.significance))
        .attr("r", 25); // Set the circle radius

        // Create a circle with the clipped image inside.
        circles.append("circle")
        .attr("cx", d => xScale(d.ideology))
        .attr("cy", d => yScale(d.significance))
        .attr("r", 25) // Adjust the circle radius as needed
        .style("stroke", "#E5E5EA")
        .style("stroke-width", 0.5)
        .style("fill", "white");

        circles.append("image")
        .attr("xlink:href", d => d.profile_picture) // Set the image URL
        .attr("x", d => xScale(d.ideology) - 25)
        .attr("y", d => yScale(d.significance) - 25)
        .attr("width", 50)
        .attr("height", 50)
        .attr("clip-path", (d, i) => `url(#clip2${i})`); // Apply the clipping path

        // Add event listeners for interactivity.
        circles
            .on("mouseover", function (event, d) {
                // Show the tooltip when hovering over a circle
                const [mouseX, mouseY] = d3.pointer(event);
                // Position the tooltip next to the mouse pointer and near the data point
                this.getBoundingClientRect();
                const tooltipWidth = parseFloat(tooltip.style("width"));
                const tooltipHeight = parseFloat(tooltip.style("height"));
                const myData =  d3.select(this).data()[0]

                // Set tooltip position initially at the mouse position.
                let tooltipX = mouseX; // Adjust the horizontal offset
                let tooltipY = mouseY; // Adjust the vertical offset
                console.log("aaa",tooltipX, tooltipY)

                // Check if the tooltip is too close to the right edge.
                if (tooltipX + tooltipWidth > width) {
                    tooltipX = width - tooltipWidth;
                }

                // Check if the tooltip is too close to the bottom edge.
                if (tooltipY + tooltipHeight > height) {
                    tooltipY = height - tooltipHeight;
                }
                if (tooltipY - tooltipHeight < 0) {
                    tooltipY = margin.top;
                }
                console.log("bbb",tooltipX, tooltipY)

                tooltip.transition()
                    .duration(200)
                    .style("opacity", 0.9);

                tooltip.html(`智库名称: ${myData.name}<br>对华态度: ${myData.ideology}<br>影响力: ${myData.significance}`)
                    .style("left", tooltipX + "px")
                    .style("top", tooltipY + "px")
                    .style("font-size", "12px");
            })
            .on("mouseout", function(event, d) {
                    // Hide the tooltip when moving the mouse away from a circle
                    tooltip.transition()
                        .duration(500)
                        .style("opacity", 0);
            });
    });

    }, []); // Empty dependency array to run this effect only once

    return (
        <div id="spectrum-chart-container" style={containerStyle}>
        </div>
    );
};

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height:"100%",
    width:"100%"
  };

export default Spectrum;

