/**
 * Author: chying
 * github: chenying2929
 * createdDate: 2023-10-23
 * lastEditDate: 2023-10-24
 */
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { topicsLineData } from './data';


const draw = (props) => {
    const margin = { top: 10, bottom: 10, left: 10, right: 10 };
    const chartWidth = props.width - margin.left - margin.right;
    let chartHeight = props.height - margin.top - margin.bottom;
    const container = "#topicLineChartContainer";
    const colorScale = d3.scaleOrdinal()
        .domain(["纽约时报", "芝加哥论坛报", "洛杉矶时报", "华尔街日报", "华盛顿邮报"])
        .range(["#D41A24", "#60A2CD", "#D9B52C", "#52B560", "#80C3B2"]);
    const yAxisMaxVal = 2000;

    d3.select("#topic-line-chart").remove()
    const svg = d3.select(container)
        .append("svg")
        .attr("id", "topic-line-chart")
        .attr("viewBox", [0, -10, chartWidth, chartHeight + 40])
        .attr("width", chartWidth)
        .attr("height", chartHeight)
        .attr("style", "max-width: 100%; height: auto; ")

    chartHeight = chartHeight * 0.8
    const chartMain = svg.append("g")
        .attr("width", chartWidth * 0.95)
        .attr("height", chartHeight * 0.85)
        .attr("transform", `translate(${chartWidth * 0.02}, 20)`)

    // set scale and line generator
    const yScale = d3.scaleLinear().domain([0, yAxisMaxVal]).range([chartHeight, 0]);
    const xScale = d3.scaleTime().domain(d3.extent(props.data, d => new Date(d.date))).range([0, chartWidth]);
    const line = d3.line()
        .x(d => xScale(new Date(d.date)))
        .y(d => yScale(d.emotion))
        .curve(d3.curveLinear);

    // draw line chart, category by the "division"
    const path = chartMain.selectAll("path")
        .data(d3.group(props.data, d => d.division))
        .enter()
        .append("path")
        .attr("d", d => line(d[1]))
        .attr("fill", "none")
        .attr("stroke", d => colorScale(d[0]))
        .attr("stroke-width", 2);

    // according to the line chart, draw circle in each (x,y)
    const dot = chartMain.selectAll("circle")
        .data(props.data)
        .enter()
        .append("circle")
        .attr("cx", d => xScale(new Date(d.date)))
        .attr("cy", d => yScale(d.emotion))
        .attr("r", 3)
        .attr("fill", d => colorScale(d.division))
        .attr("class", "data-point");

    // draw x,y axixs
    const xAxis = d3.axisBottom(xScale)
        .ticks(chartWidth / 180)
        .tickFormat(d3.timeFormat('%Y-%m'));

    chartMain.append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${chartHeight})`)
        .call(xAxis)
        .call(g => g.selectAll(".tick text").style("font-size", "16px"))
        .select(".domain")
        .style("stroke", "blue");

    chartMain.append("g")
        .call(d3.axisLeft(yScale).ticks(11))
        .call(g => g.selectAll(".tick text").style("font-size", "16px"))
        .select(".domain")
        .style("stroke", "blue");


    chartMain.append("text")
        .attr("x", chartWidth)
        .attr("y", chartHeight * 1.05)
        .attr("text-anchor", "end")
        .attr("font-size", "20px")
        .text("年份");

    // ---------------------------add captions----------------------------------
    /**
     * add three captions
     * specially, in the first caption block, it also has a svg img
     */
    const externalCaptions = [
        { text: "“主流化”阶段一:<br>特朗普2016竞选", x: chartWidth * 0.27, y: chartHeight * 0.5, width: 150, height: 50 },
        { text: "“主流化”节点二:<br>“团结右翼”集会", x: chartWidth * 0.32, y: chartHeight * 0.34, width: 200, height: 50 },
        { text: "“主流化”节点三:<br>国会山暴乱", x: chartWidth * 0.67, y: chartHeight * 0.05, width: 200, height: 50 }
    ]
    externalCaptions.forEach((d) => {
        let foreignObject = svg.append("foreignObject")
            .attr("x", d.x)
            .attr("y", d.y)
            .attr("width", d.width)
            .attr("height", d.height);
        const div = foreignObject.append("xhtml:div")
            .attr("style", "width: 100%; height: 100%; font-size: 16px; position: relative;");
        div.append("xhtml:div")
            .attr("style", "text-align: center; font-size: 16px;")
            .html(`${d.text}`);
    })

    chartMain.append("image")
        .attr("x", chartWidth * 0.25)
        .attr("y", chartHeight * 0.6)
        .attr("width", 150)
        .attr("height", 30)
        .attr("xlink:href", "./brace.svg");

    // ---------------------------add legend----------------------------------
    /**
     * add legend of line chart category
     */
    const legendData = [
        { color: "#D41A24", label: "纽约时报" },
        { color: "#60A2CD", label: "芝加哥论坛报" },
        { color: "#D9B52C", label: "洛杉矶时报" },
        { color: "#52B560", label: "华尔街日报" },
        { color: "#80C3B2", label: "华盛顿邮报" },
    ];

    const legendGroup = svg.append("g")
        .attr("class", "legend")
        .attr("transform", `translate(${chartWidth * 0.02}, ${chartHeight * 1.2})`);

    // create a obejct to record the state visibility of each category
    let legendState = {};
    const legendItems = legendGroup.selectAll(".legend-item")
        .data(legendData)
        .enter().append("g")
        .attr("class", "legend-item")
        .attr("transform", function (d, i) {
            return "translate(" + ((i % 5) * 225 + 180) + ",0)";
        });

    legendItems.append("circle")
        .attr("class", "legend-color")
        .attr("r", 10)
        .style("cursor", "pointer")
        .style("fill", function (d) {
            return d.color;
        });

    legendItems.append("text")
        .attr("class", "legend-label")
        .attr("x", 22)
        .attr("y", 5)
        .attr("font-size", "20px")
        .style("cursor", "pointer")
        .text(function (d) { return d.label; })

    //-------------------------------add legend-----------------------------
    /**
     * add a legend of period.
     * as shown in the chart, it is the color block of the chart background
     * the legend has two part: rect filled with color + text located in the center
     */
    const legendColorBlockData = [
        { color: "#FFF9E2", label: "奥巴马执政时期" },
        { color: "#FCEFEE", label: "特朗普执政时期" },
        { color: "#EDF0FE", label: "拜登执政时期" },
    ];
    const legendColorBlockGroup = svg.append("g")
        .attr("class", "legend2")
        .attr("transform", `translate(${chartWidth * 0.02}, ${chartHeight * 1.25})`);

    const legendColorBlock = legendColorBlockGroup.selectAll(".legend-item2")
        .data(legendColorBlockData)
        .enter().append("g")
        .attr("class", "legend2-item")
        .attr("transform", function (d, i) {
            return "translate(" + ((i % 5) * 305 + 230) + ",0)";
        });

    legendColorBlock.append("rect")
        .attr("class", "legend2-color")
        .attr("width", 250)
        .attr("height", 30)
        .style("cursor", "default")
        .style("fill", function (d) {
            return d.color;
        });

    legendColorBlock.append("text")
        .attr("class", "legend2-label")
        .attr("x", 60)
        .attr("y", 20)
        .attr("font-size", "20px")
        .text(function (d) {
            return d.label;
        });

    const backgroundColors = [
        { color: "#FFF9E2", startDate: new Date(props.startDate), endDate: new Date('2017-01') },
        { color: "#FCEFEE", startDate: props.startDate > new Date('2017-01') ? props.startDate : new Date('2017-01'), endDate: new Date('2021-01') },
        { color: "#EDF0FE", startDate: props.startDate > new Date('2021-01') ? props.startDate : new Date('2021-01'), endDate: new Date(props.endDate) }
    ]

    backgroundColors.forEach((d) => {
        svg.insert("rect", ":first-child")
            .attr("x", xScale(d.startDate) + chartWidth * 0.02)
            .attr("y", 20)
            .attr("width", xScale(d.endDate) - xScale(d.startDate))
            .attr("height", chartHeight)
            .attr("fill", d.color);
    })

    // -----------------------------------add interactive---------------------
    /**
     * when click the legend(about line category) dot or legend text, change it's visibility
     * when click the blank area, all the line will be shown, as shown in the init.
     */
    legendItems.on('click', function (event, d) {
        // change state
        legendState[d.label] = !legendState[d.label];
        // 1. change the visibility of line path
        path.style('display', function ([i]) {
            return legendState[i] ? 'initial' : 'none';
        });

        // 2. change the color of legend circle
        legendItems.selectAll('.legend-color')
            .style('fill', function (legendData) {
                return legendState[legendData.label] ? colorScale(legendData.label) : '#ccc';
            });

        // 3. change the visibility of the dot in the line
        dot.style('display', (i) => legendState[i.division] ? 'initial' : 'none');
    });
    const wrapperBox = document.getElementById("topicLineChartContainer");
    wrapperBox.addEventListener('click', function (event) {
        if (!event.target.classList.contains('legend-item') && !event.target.classList.contains('legend-label') && !event.target.classList.contains('legend-color')) {
            path.style('display', 'initial');
            legendItems.selectAll('.legend-color').style('fill', function (legendData) {
                return colorScale(legendData.label);
            });
            dot.style('display', 'initial');
            // init the total state
            legendState = {}
        }
    })
}

const TopicsLineChart = () => {
    const topicLineChartContainerRef = useRef(null);
    const [topicLineChartData] = useState(topicsLineData);

    useEffect(() => {
        if (topicLineChartContainerRef.current) {
            const chartProps = {
                width: topicLineChartContainerRef.current.offsetWidth,
                height: topicLineChartContainerRef.current.offsetHeight,
                data: topicLineChartData,
                startDate: new Date('2014-01-01'),
                endDate: new Date('2023-10-01')
            }
            draw(chartProps)
        }
    }, [topicLineChartData]);
    return (
        <div id="topicLineChartContainer" ref={topicLineChartContainerRef} style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}></div>
    );
};
export default TopicsLineChart