import React, { useEffect, useState } from "react";
import { Select, Card, Popover } from "antd";
import * as d3 from "d3";
import "./style.css"

const AuthorOverview = (props) => {
    useEffect(() => {
        const mediaList = [
            {
                "id": "Top1",
                "nameEnglish": "Keith Bradsher",
                "nameChinese": "基思·布拉德舍",
                "logo": "avatars/wps_doc_16.png",
                "position1": "普利策奖得主",
                "position2": "《纽约时报》北京分社社长",
                "introduce": "基思·布拉德舍（Keith Bradsher）是《纽约时报》获得普利策奖的北京分社社长。他曾担任《纽约时报》上海分社社长、《纽约时报》香港分社社长和底特律分社社长。在这些职位之前，他是《泰晤士报》的华盛顿记者，报道美联储和国际贸易，以及《纽约时报》的运输和电信记者。Bradsher先生出生于1964年，在北卡罗来纳大学教堂山分校获得经济学学位，在那里他是莫尔黑德学者。他获得了普林斯顿大学伍德罗·威尔逊学院的公共政策硕士学位，主修经济学。在加入《纽约时报》之前，布拉德舍先生从1987年到1989年为《洛杉矶时报》撰稿。",
                "media": "纽约时报"
            },
            {
                "id": "Top2",
                "nameEnglish": "David E. Sanger",
                "nameChinese": "大卫·E·桑格",
                "logo": "avatars/wps_doc_15.png",
                "position1": "阿斯彭战略小组成员",
                "position2": "《纽约时报》白宫和国家安全记者",
                "introduce": "大卫·E·桑格（David E. Sanger）是白宫和国家安全记者，也是《纽约时报》的资深作家。在40年的报道生涯中，他曾在三个获得普利策奖的时代团队中任职，最近一次是在2017年的国际报道。他的最新著作《完美武器：网络时代的战争、破坏和恐惧》（The Perfect Weapon： War， Sabotage and Fear in the Cyber Age）探讨了网络冲突的出现，这是大国和小国相互竞争和削弱，改变全球力量本质的主要方式。桑格先生曾担任两部HBO国家安全纪录片的执行制片人：获得艾美奖提名的《完美武器》（2020年）和讲述拜登政府第一年的《第一年》（2022年）。他还是《泰晤士报》关于外交政策和国家安全的两本畅销书的作者：《继承：奥巴马面对的世界和美国权力的挑战》和《面对和隐瞒：奥巴马的秘密战争和美国权力的惊人使用》。桑格先生曾担任东京分社社长、华盛顿经济记者、克林顿和布什政府期间的白宫记者以及华盛顿首席记者。他是调查1986年挑战者号灾难原因的团队的主要成员，该团队在次年的国家报告中获得了普利策奖。1999年，第二个普利策奖被授予一个调查克林顿政府内部控制对中国技术出口的斗争的团队。他是CNN的撰稿人，也是肯尼迪政府学院的兼职讲师，在那里，他与格雷厄姆·艾利森（Graham Allison）共同教授“美国国家安全，战略和新闻界的核心挑战”。他于1982年毕业于哈佛大学，是阿斯彭战略小组的成员。",
                "media": "纽约时报"
            },
            {
                "id": "Top3",
                "nameEnglish": "Nicholas D Kristof",
                "nameChinese": "古拉斯·克里斯托夫",
                "logo": "avatars/wps_doc_17.png",
                "position1": "《纽约时报》专栏作家",
                "position2": "",
                "introduce": "1981年，哈佛大学荣誉学士;牛津大学法学（荣誉）学士和硕士（荣誉）和罗德学者;1984年，开罗美国大学阿拉伯语言研究文凭;1987-88，台北语言学院中文系学习。1984年至今，任《纽约时报》北京分社社长;1988-93，东京分社社长;自1995年以来，专栏作家。合著者：半边天;中国觉醒;来自东方的雷声。董事会成员：哈佛大学监督员;美国罗德学者协会。兴趣：背包旅行，长跑。获奖者：普利策奖（99年和2001年）。古拉斯·克里斯托夫（Nicholas Kristof）是两届普利策奖得主，他的专栏每周在《纽约时报》上出现两次。曾是《纽约时报》驻香港、北京和东京的驻外记者，然后报道总统选举，并担任《纽约时报》的编辑。9/11之后，他成为一名专栏作家。他获得了两项普利策奖，一项是关于中国的报道，另一项是关于达尔富尔的专栏，以及许多人道主义奖项，如安妮·弗兰克奖和代顿文学和平奖。他与妻子谢丽尔·吴邓恩（Sheryl WuDunn）合著了五本畅销书，包括2020年的《Tightrope》和排名第一的畅销书《半边天》。 克里斯托夫曾在四大洲生活过，去过 1 多个国家，是《纽约时报》的第一位博主、第一位视频制作者和第一位 Snapchat 撰稿人，他在社交媒体上拥有超过 160 万粉丝。",
                "media": "纽约时报"
            },
            {
                "id": "Top1",
                "nameEnglish": "Uli Schmetzer",
                "nameChinese": "乌利·施迈策",
                "logo": "avatars/wps_doc_0.jpg",
                "position1": "曾任《芝加哥论坛报》罗马分社社长",
                "position2": "曾任《芝加哥论坛报》东京分社社长",
                "introduce": "Uli Schmetzer于1960年在墨尔本的《太阳画报》开始了他的记者生涯。1963年，他加入了《悉尼太阳报》，在1965-66年间，他撰写了一个名为Uli Schmetzer on the Fringe的每日专栏。1966年，Schmetzer前往墨西哥，在那里他加入了路透社，成为墨西哥和拉丁美洲中部的当地雇员。他被路透社派往哈瓦那。1970年，他成为路透社的记者。他曾担任委内瑞拉分社社长，后来在里约热内卢负责南美。1977年，他从路透社辞职，在罗马为美国，南非，英国和澳大利亚的报纸担任自由职业者。\n1986年，《芝加哥论坛报》为他提供了罗马分社社长的工作，负责阿拉伯世界和定期的以色列事务。1988年，他被派往北京担任亚洲记者，在马尼拉设有第二个办事处，负责东南亚和太平洋地区，直到1996年。同年，他成为驻马尼拉的东南亚记者（1996-98）。他被任命为新德里分社社长（1998-2000），负责印度次大陆。2000年，他被任命为东京分社社长。",
                "media": "芝加哥论坛报"
            },
            {
                "id": "Top2",
                "nameEnglish": "Michael A Lev",
                "nameChinese": "迈克尔·列夫",
                "logo": "avatars/wps_doc_1.jpg",
                "position1": "前《芝加哥论坛报》亚洲记者",
                "position2": "商业编辑",
                "introduce": "屡获殊荣的记者，在《芝加哥论坛报》度过了精彩的职业生涯后，他已转为自由撰稿人、编辑和传播策略师。我在论坛报的角色包括编辑委员会成员和负责商业新闻的副总编辑。早些时候，我在东京和北京担任了近十年的论坛报驻外记者。",
                "media": "芝加哥论坛报"
            },
            {
                "id": "Top3",
                "nameEnglish": "Philip Hersh",
                "nameChinese": "菲利普·赫什",
                "logo": "avatars/wps_doc_2.png",
                "position1": "奥林匹克体育专家。曾任职于《芝加哥论坛报》（1984-2015）。报道了20届奥运会。",
                "position2": "",
                "introduce": "菲利普·赫什（Philip Hersh）在《芝加哥论坛报》（Chicago Tribune）担任了28年的奥林匹克体育作家，作为该领域的国际公认专家，赫什曾出现在 NBC 今日节目和晚间新闻中;NBC2010年和2018年的“冬奥日报”节目;美国广播公司晚间新闻;哥伦比亚广播公司周日上午和哥伦比亚广播公司奥运报道;ESPN的《体育周刊》;美国有线电视新闻网;今晚芝加哥;监控广播，PBS广播和NPR。他曾四次获得普利策奖提名，并多次获得美联社体育编辑年度写作奖、芝加哥头条俱乐部奖和芝加哥黑人记者协会奖。德国出版物SportIntern将他列为100次国际体育界最具影响力的15人之一。赫什获得了美国体育学院颁发的2016年杰出服务奖，“以表彰他对报道全球体育世界的承诺，包括他对奥运会的著名报道。",
                "media": "芝加哥论坛报"
            },
            {
                "id": "Top1",
                "nameEnglish": "Mann, James (Jim Mann)",
                "nameChinese": "曼·詹姆斯（吉姆·曼）",
                "logo": "avatars/wps_doc_3.jpg",
                "position1": "曾担任《洛杉矶时报》北京分社社长",
                "position2": "",
                "introduce": "詹姆斯·曼（James Mann）是华盛顿的作家，撰写了一系列关于美国外交政策和美国与中国关系的获奖书籍。他曾是报纸记者、外国记者和专栏作家，曾在《洛杉矶时报》工作了二十多年。他曾在加利福尼亚州洛杉矶的《洛杉矶时报》担任最高法院记者，并在1978年至1983年期间他担任了北京分社的社长一职。",
                "media": "洛杉矶时报"
            },
            {
                "id": "Top2",
                "nameEnglish": "David Holley",
                "nameChinese": "大卫·霍利",
                "logo": "avatars/wps_doc_4.jpg",
                "position1": "自由撰稿人",
                "position2": "",
                "introduce": "David Holley是《朱利安尼：美国市长的崛起与悲惨衰落》一书的的研究员。",
                "media": "洛杉矶时报"
            },
            {
                "id": "Top3",
                "nameEnglish": "Don Lee",
                "nameChinese": "唐·李",
                "logo": "avatars/wps_doc_5.jpg",
                "position1": "自由撰稿人",
                "position2": "",
                "introduce": "Don Lee（唐·李）是一位备受赞誉的韩裔美国作家，他的文学作品跨足小说和短篇故事，多年来一直在探索亚裔美国人在多元文化社会中的身份认同和多样性。他的首部作品，2001年的短篇故事集《Yellow》，以多元的角度呈现了亚洲裔美国人的生活，涵盖了来自不同族裔背景和职业的主人公，强调了亚洲裔美国人并非单一的群体。他的小说作品如《Country of Origin》（讲述20世纪80年代日本的亚洲裔间谍）和《The Collective》（描写剑桥的亚裔艺术家生活）延续了他的核心观点，即亚裔美国人的多样性不可忽视。",
                "media": "洛杉矶时报"
            },
            {
                "id": "Top4",
                "nameEnglish": "Barbara Demick",
                "nameChinese": "芭芭拉·德米克",
                "logo": "avatars/wps_doc_6.png",
                "position1": "《洛杉矶时报》的前驻外记者，曾在北京、首尔和纽约担任分社社长",
                "position2": "",
                "introduce": "芭芭拉·德米克（Barbara Demick）是一位长期的驻外记者和作家。在《洛杉矶时报》工作的15年里，她在纽约、北京和首尔担任分社社长。她之前曾在中东、东欧、华盛顿和华尔街为《费城问询报》报道。德米克是三本书的获奖作家：《吃佛：西藏小镇的生与死》、《没什么可羡慕的：朝鲜的普通生活》和《洛加维纳街：萨拉热窝社区的生与死》。",
                "media": "洛杉矶时报"
            },
            {
                "id": "Top1",
                "nameEnglish": "Chao Deng",
                "nameChinese": "邓超",
                "logo": "avatars/wps_doc_7.png",
                "position1": "《华尔街日报》北非记者",
                "position2": "",
                "introduce": "邓超常驻开罗，为《华尔街日报》报道北非。她撰写有关该地区经济、政治、人权和气候变化的文章。十年前，她报道了中国。她专注于其经济和金融体系，撰写了经济放缓如何影响企业和消费者的文章。2021年，她因在武汉境内报道Covid-19大流行的早期而获得利文斯顿国际报道奖。她是《华尔街日报》团队的一员，该团队入围普利策总统关于习近平总统加强对中国控制的系列节目。她还撰写了有关中国文化、社会和政治的文章。2020年，她被禁止以记者身份回国，曾在台湾工作了一段时间。于2012年首次加入《华尔街日报》，负责上海的金融报道。",
                "media": "华尔街日报线上"
            },
            {
                "id": "Top2",
                "nameEnglish": "Lingling Wei",
                "nameChinese": "魏玲玲",
                "logo": "avatars/wps_doc_8.png",
                "position1": "《华尔街日报》首席驻中国记者",
                "position2": "",
                "introduce": "Lingling Wei，中文名叫魏玲玲，是美国《华尔街日报》中国报道高级记者。魏玲玲的祖父是毛泽东的助手，长达13年。她去了复旦大学和纽约大学学习新闻学，并作为《华尔街日报》的驻外记者回到中国。是《华尔街日报》的中国记者。在2020年3月，一直在北京报道中国经济。她和她在《华尔街日报》的同事、《华盛顿邮报》的《纽约时报》一起被驱逐出境，所有被驱逐的记者都是美国记者。作为归化的美国公民，她就是其中之一。所以现在她在纽约，仍然为该杂志撰稿，仍然报道中国。",
                "media": "华尔街日报线上"
            },
            {
                "id": "Top3",
                "nameEnglish": "James Freeman",
                "nameChinese": "詹姆斯·弗里曼",
                "logo": "avatars/wps_doc_9.png",
                "position1": "《华尔街日报》编辑版助理编辑",
                "position2": "",
                "introduce": "詹姆斯·弗里曼（James Freeman）是《华尔街日报》社论版的助理编辑，也是工作日最佳网络专栏的作者。他是《借来的时间：花旗的两个世纪的繁荣、萧条和救助》一书的合著者，该书被公认为《纽约时报》编辑之选和《金融时报》本月商业书籍。他是福克斯新闻频道的撰稿人，也是福克斯国家“深度潜水”的主持人。毕业于耶鲁大学，是小威廉·巴克利（William F. Buckley， Jr. Program）的董事会成员。",
                "media": "华尔街日报线上"
            },
            {
                "id": "Top1",
                "nameEnglish": "James T Areddy",
                "nameChinese": "詹姆斯·T·阿雷迪",
                "logo": "avatars/wps_doc_10.jpg",
                "position1": "《华尔街日报》驻华记者",
                "position2": "",
                "introduce": "詹姆斯·T·阿雷迪（James T. Areddy）是一名为《华尔街日报》撰稿的记者。他经常报道与中国有关的话题，包括中国在海外的投资、中国政策对公司董事会的影响，以及纽约市中国异议的声音。他还写过关于车臣中国制造的装甲卡车、好莱坞罢工作家造成的破坏以及美国对中国官员的制裁。James T Areddy先生于1980年代后期开始为美国报纸和道琼斯公司（Dow Jones & Co.）报道，并为《华尔街日报》及其附属出版物的许多版块撰写故事。他在上海为《华尔街日报》和《道琼斯报》工作了大约 18 年，直到 2020 年，并在 1990 年代的大部分时间里在香港报道。他也一直在纽约市工作。Areddy先生对中国的报道侧重于商业、政治、安全、社会、环境方面的趋势，以及中国大规模经济转型及其对更广阔世界的巨大影响所带来的压力。Areddy先生是2007年普利策国际报道奖和2010年美国海外新闻俱乐部马尔科姆·福布斯奖的联合获奖者，并获得了亚洲出版商协会的奖项。",
                "media": "华尔街日报"
            },
            {
                "id": "Top2",
                "nameEnglish": "Bob Davis",
                "nameChinese": "鲍勃·戴维斯",
                "logo": "avatars/wps_doc_11.jpg",
                "position1": "自由撰稿人",
                "position2": "前《华尔街日报》高级编辑",
                "introduce": "在《华尔街日报》工作39年后成为自由职业者。2022 年，戴维斯从《华尔街日报》退休，担任华盛顿特区的高级编辑，此前他在该报工作了几十年。他继续为《华尔街日报》、《外交政策》、《政治杂志》、《电讯中国》和其他出版物撰稿。\n他2020年与魏玲玲合著的《超级大国对决》一书，讲述了中美经贸战，被图书权威评选为年度最佳经济书籍之一。在写这本书的时候，他是伍德罗·威尔逊中心基辛格中国和美国研究所的公共政策研究员。戴维斯和魏也是《华尔街日报》团队的一员，该团队因报道“特朗普的贸易动荡”而在 2019 年获得纽约新闻俱乐部奖。",
                "media": "华尔街日报"
            },
            {
                "id": "Top3",
                "nameEnglish": "Lingling Wei",
                "nameChinese": "魏玲玲",
                "logo": "avatars/wps_doc_12.png",
                "position1": "《华尔街日报》首席驻中国记者",
                "position2": "",
                "introduce": "Lingling Wei，中文名叫魏玲玲，是美国《华尔街日报》中国报道高级记者。\n魏玲玲的祖父是毛泽东的助手，长达13年。她去了复旦大学和纽约大学学习新闻学，并作为《华尔街日报》的驻外记者回到中国。是《华尔街日报》的中国记者。在2020年3月，一直在北京报道中国经济。她和她在《华尔街日报》的同事、《华盛顿邮报》的《纽约时报》一起被驱逐出境，所有被驱逐的记者都是美国记者。作为归化的美国公民，她就是其中之一。所以现在她在纽约，仍然为该杂志撰稿，仍然报道中国。",
                "media": "华尔街日报"
            },
            {
                "id": "Top1",
                "nameEnglish": "Steven Mufson",
                "nameChinese": "史蒂文·穆夫森",
                "logo": "avatars/wps_doc_13.jpg",
                "position1": "《华盛顿邮报》商业作家",
                "position2": "记者报道气候变化业务",
                "introduce": "史蒂文·穆夫森（Steven Mufson）为《华盛顿邮报》报道气候变化业务。自1989年加入《华盛顿邮报》以来，他报道了经济政策、中国、外交、能源和白宫。他还曾担任Outlook部分的编辑。此前，他曾在纽约、伦敦和约翰内斯堡的《华尔街日报》工作了六年。2020年，他因气候变化系列《2C：超越极限》分享了普利策奖。Mufson于1980年毕业于耶鲁大学，获得经济学和政治学联合学位。在耶鲁期间，穆夫森担任《耶鲁日报》的主编。",
                "media": "华盛顿邮报"
            },
            {
                "id": "Top2",
                "nameEnglish": "John Pomfret",
                "nameChinese": "约翰·庞弗雷特",
                "logo": "avatars/wps_doc_14.png",
                "position1": "作家",
                "position2": "《华盛顿邮报》北京分社社长",
                "introduce": "Pomfret 先生是《华盛顿邮报》全球观点部分的特约撰稿人，并担任驻加利福尼亚州的顾问。 他经常谈论中国的国内政治和外交政策、中国文化、对中国人的营销以及中国的环境、人口和政治挑战。庞弗雷特先生因其对亚洲的报道而获奖，其中包括奥斯本艾略特奖。庞弗雷特是中美关系正常化后第一批赴华的美国学生之一1989 年天安门广场大屠杀后，他被驱逐出中国。",
                "media": "华盛顿邮报"
            },
            {
                "id": "Top3",
                "nameEnglish": "Simon Denyer",
                "nameChinese": "西蒙·丹耶",
                "logo": "avatars/wps_doc_15.jpg",
                "position1": "曾担任《华盛顿邮报》日本和朝鲜分社社长\n介绍：",
                "position2": "",
                "introduce": "西蒙·丹尼尔（三位一体，1984年）是《华盛顿邮报》日本和朝鲜分社社长。他曾在世界各地担任《华盛顿邮报》和路透社的外国记者和分社社长，包括北京、新德里、华盛顿、伊斯兰堡、内罗毕、纽约和伦敦。他是《流氓大象：在新印度利用民主的力量》一书的作者，也是《外国记者：南亚报道五十年》的联合编辑。\n他是《华盛顿邮报》2020 年普利策奖获奖团队的一员，因其对全球气候变化的报道。他还因在中国和日本的报道而获得海外新闻俱乐部奖、两项国家头条新闻奖和人权新闻奖。他还经常在电视和广播中露面，包括BBC，CNN，NPR，PBS，Fox News，MSNBC和Sky News。2011年至13年，他担任南亚外国记者俱乐部主席。\nSimon Denyer于1984年至1987年在剑桥大学三一学院学习经济学，1987年毕业，获得经济学学士学位。",
                "media": "华盛顿邮报"
            }
        ];

        const container = d3.select("#author-overview-app").
            style("height", (window.innerHeight * 0.65 - 50) + "px")

        const mediaItems = container.selectAll(".author-overview-media-item")
            .data(mediaList)
            .enter()
            .append("div")
            .attr("class", "author-overview-media-item");

        const mediaNames = ["纽约时报", "芝加哥论坛报", "洛杉矶时报", "华尔街日报线上", "华尔街日报", "华盛顿邮报"];

        let currentMediaIndex = 0;

        const mediaSelector = document.getElementById("mediaSelector");
        const prevMediaButton = document.getElementById("prevMedia");
        const nextMediaButton = document.getElementById("nextMedia");
        const currentMediaElement = document.getElementById("currentMedia");

        function filterMedia(mediaName) {
            mediaItems.style("display", function (d) {
                return d.media === mediaName ? "block" : "none";
            });
        }


        function updateCurrentMedia() {
            currentMediaElement.textContent = mediaNames[currentMediaIndex];
        }

 
        function prevMedia() {
            currentMediaIndex = (currentMediaIndex - 1 + mediaNames.length) % mediaNames.length;
            updateCurrentMedia();
            filterMedia(mediaNames[currentMediaIndex]);
        }


        function nextMedia() {
            currentMediaIndex = (currentMediaIndex + 1) % mediaNames.length;
            updateCurrentMedia();
            filterMedia(mediaNames[currentMediaIndex]);
        }


        prevMediaButton.addEventListener("click", prevMedia);
        nextMediaButton.addEventListener("click", nextMedia);


        updateCurrentMedia();
        filterMedia(mediaNames[currentMediaIndex]);

        const line_three = mediaItems.append("div")
            .attr("class", "line_three");
        const line_one = mediaItems.append("div")
            .attr("class", "line_one");
        const line_two = mediaItems.append("div")
            .attr("class", "line_two");


        line_one.append("div")
            .attr("class", "media-logo")
            .append("img")
            .attr("src", d => d.logo)
            .style("border-radius", "50%");;


        const mediaName = line_one.append("div")
            .attr("class", "media-name");

        mediaName.append("p")
            .attr("class", "chinese")
            .text(d => d.nameChinese);

        mediaName.append("p")
            .attr("class", "english")
            .text(d => d.nameEnglish);

        const typeWrapper = mediaName.append("div")
            .attr("class", "type-wrapper");

        typeWrapper.each(function (d) {
            if (d.position1 !== undefined && d.position1 !== null && d.position1 !== "") {
                d3.select(this).append("div")
                    .attr("class", "type")
                    .text(d => d.position1); // 设置type元素的文本内容为position1的值
            }
            if (d.position2 !== undefined && d.position2 !== null && d.position2 !== "") {
                d3.select(this).append("div")
                    .attr("class", "position")
                    .text(d => d.position2); // 设置position元素的文本内容为position2的值
            }
        });

        line_two.append("div")
            .attr("class", "value")
            .text(d => d.introduce);


        line_three.append("p")
            .attr("class", "number")
            .text(d => d.id);
    }, [])

    return (
        <div id="avatar_container" style={{height: "100%"}}>
            <div id="author-overview-app"></div>
            <div id="mediaSelector">
                <button id="prevMedia">&lt;</button>
                <span id="currentMedia">纽约时报</span>
                <button id="nextMedia">&gt;</button>
            </div>
        </div>
    );
}

export default AuthorOverview;