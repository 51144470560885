import React, { useEffect, useState } from "react";
import { Card, Popover, Select } from "antd";
import * as d3 from "d3";

const MediaAttitude = (props) => {
    const items = [
        {
            label: "1980-至今",
            value: "All"
        },
        {
            label: "2009-2017",
            value: "2009-2017"
        },
        {
            label: "2017-2021",
            value: "2017-2021"
        },
        {
            label: "2021-至今",
            value: "2021"
        }
    ]

    const [data, setData] = useState();
    const [selectedOption, setSelectedOption] = useState();

    function LineChart(data, {
        x = ([x]) => x, // given d in data, returns the (temporal) x-value
        y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
        z = () => 1, // given d in data, returns the (categorical) z-value
        title, // given d in data, returns the title text
        defined, // for gaps in data
        curve = d3.curveLinear, // method of interpolation between points
        marginTop = 50, // top margin, in pixels
        marginRight = -50, // right margin, in pixels
        marginBottom = -0, // bottom margin, in pixels
        marginLeft = 0, // left margin, in pixels
        width = 640, // outer width, in pixels
        height = 400, // outer height, in pixels
        xType = d3.scaleUtc, // type of x-scale
        xDomain, // [xmin, xmax]
        xRange = [marginLeft, width - marginRight], // [left, right]
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat, // a format specifier string for the y-axis
        yLabel, // a label for the y-axis
        xLabel,
        zDomain, // array of z-values
        color = "currentColor", // stroke color of line, as a constant or a function of *z*
        strokeLinecap, // stroke line cap of line
        strokeLinejoin, // stroke line join of line
        strokeWidth = 1.5, // stroke width of line
        strokeOpacity, // stroke opacity of line
        mixBlendMode = "multiply", // blend mode of lines
        voronoi,// show a Voronoi overlay? (for debugging)
        backgroundColors = {
            "#FFF9E2": {
                startDate: new Date('2012-01'),
                endDate: new Date('2017-01')
            },
            "#FCEFEE": {
                startDate: new Date('2017-01'),
                endDate: new Date('2021-01')
            },
            "#EDF0FE": {
                startDate: new Date('2021-01'),
                endDate: new Date('2023-11')
            }
        }
    } = {}) {
        // Compute values.
        const divisionColors = {
            "纽约时报": "#D41A24",
            "芝加哥论坛报": "#60A2CD",
            "洛杉矶时报": '#D9B52C',
            "华尔街日报": "#52B560",
            "华尔街日报（在线版）": "#AD6DB1",
            "华盛顿邮报": "#80C3B2",
            "亚利桑那共和报": "#48D9E2",
            "匹兹堡邮报": "#F9BFDF",
            "明星论坛报": "#F76E69",
            "坦帕湾时报": "#94AA26",
            "亚特兰大宪法报": "#FDB462",
            "今日美国": "#9A6AFF"
        };

        const X = d3.map(data, x);
        const Y = d3.map(data, y);
        const Z = d3.map(data, z);
        const O = d3.map(data, d => d);
        if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
        const D = d3.map(data, defined);

        // Compute default domains, and unique the z-domain.
        if (xDomain === undefined) xDomain = d3.extent(X);
        if (yDomain === undefined) yDomain = d3.extent(Y);;
        if (zDomain === undefined) zDomain = Z;
        zDomain = new d3.InternSet(zDomain);

        // Omit any data not present in the z-domain.
        const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

        const xScale = xType()
            .domain(xDomain)
            .range(xRange)
            .clamp(true);

        const absMax = Math.max(Math.abs(yDomain[0]), Math.abs(yDomain[1]));
        const symmetricXDomain = [-absMax, absMax];
        const yScale = d3.scaleLinear()
            .domain(symmetricXDomain)
            .range([height - marginBottom, marginTop])
            ;

        const timeFormat = d3.timeFormat('%Y-%m');
        const xAxis = d3.axisBottom(xScale)
            .ticks(width / 180)
            .tickFormat(timeFormat);
        const yAxis = d3.axisLeft(yScale).ticks(height / 60, yFormat);


        // Compute titles.
        const T = title === undefined ? Z : title === null ? null : d3.map(data, title);

        // Construct a line generator.
        const line = d3.line()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y(i => yScale(Y[i]));

        const svg = d3.create("svg")
            .attr("width", width)
            .attr("height", height * 1.2)
            .attr("viewBox", [-width * 0.04, height * 0.06, width + 100, height + 100])
            .attr("style", "max-width: 100%; ")
            .style("-webkit-tap-highlight-color", "transparent")

        if (voronoi) svg.append("path")
            .attr("fill", "none")
            .attr("stroke", "#ccc")
            .attr("d", d3.Delaunay
                .from(I, i => xScale(X[i]), i => yScale(Y[i]))
                .voronoi([0, 0, width, height])
                .render());
        const auxiliaryScaleContainer = svg.append("g")
            .attr("class", "auxiliary-scale")
            .attr("stroke", "#ccc");

        const numberOfAuxiliaryTicks = 10;

        const auxiliaryTickValues = xScale.ticks(numberOfAuxiliaryTicks);

        auxiliaryScaleContainer.selectAll(".auxiliary-tick")
            .data(auxiliaryTickValues)
            .enter().append("line")
            .attr("class", "auxiliary-tick")
            .attr("x1", d => xScale(d))
            .attr("x2", d => xScale(d))
            .attr("y1", marginTop) // 刻度线的起始位置，根据需要调整
            .attr("y2", height - marginBottom); // 刻度线的结束位置，根据需要调整

        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height / 2 - (marginBottom - marginTop) / 2})`)//迁移后可能还需要微调
            .call(xAxis)
            .call(g => g.selectAll(".tick text")
                .style("font-size", "18px"))
            .select(".domain")
            .style("stroke", "blue")
            ;

        svg.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(yAxis)
            .call(g => g.selectAll(".tick text")
                .style("font-size", "18px"))
            .select(".domain")
            .style("stroke", "blue")
            .call(g => g.select(".domain").remove())
            .call(voronoi ? () => { } : g => g.selectAll(".tick line").clone()
                .attr("x2", width - marginLeft - marginRight)
                .attr("stroke-opacity", 0.1))
            .call(g => g.append("text")
                .attr("x", marginLeft)
                .attr("y", 20)
                .attr("font-size", "18px")
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .text(yLabel));

        svg.append("text")
            .attr("x", marginLeft + 4)
            .attr("y", 28)
            .attr("text-anchor", "end")
            .attr("font-size", "20px")
            .text(yLabel);

        svg.append("text")
            .attr("x", width + 49)
            .attr("y", height / 2 + 20)
            .attr("text-anchor", "end")
            .attr("font-size", "20px")
            .text(xLabel);

        var legendData = [
            { color: "#D41A24", label: "纽约时报" },
            { color: "#60A2CD", label: "芝加哥论坛报" },
            { color: "#D9B52C", label: "洛杉矶时报" },
            { color: "#52B560", label: "华尔街日报" },
            { color: "#AD6DB1", label: "华尔街日报（在线版）" },
            { color: "#80C3B2", label: "华盛顿邮报" },

            { color: "#48D9E2", label: "亚利桑那共和报" },
            { color: "#F9BFDF", label: "匹兹堡邮报" },
            { color: "#F76E69", label: "明星论坛报" },
            { color: "#94AA26", label: "坦帕湾时报" },
            { color: "#FDB462", label: "亚特兰大宪法报" },
            { color: "#9A6AFF", label: "今日美国" }


        ];

        var legendGroup = svg.append("g")
            .attr("class", "legend")

        var legendItems = legendGroup.selectAll(".legend-item")
            .data(legendData)
            .enter().append("g")
            .attr("class", "legend-item")
            .attr("transform", function (d, i) {
                return "translate(" + ((i % 6) * 225 * width / 1500 + (width - 205 * 6 * width / 1500) / 2) + "," + (Math.floor((i) / 6) * height * 0.05 + height * 1.05) + ") scale( " + width / 1500 + ")"; // 一排显示六个，调整每个图例的位置
            })

        legendItems.append("circle")
            .attr("class", "legend-color")
            .attr("r", 10)
            .style("fill", function (d) {
                return d.color;
            });

        legendItems.append("text")
            .attr("class", "legend-label")
            .attr("x", 22)
            .attr("y", 5)
            .attr("font-size", "20px")
            .text(function (d) {
                return d.label;
            });
        var legend2Data = [
            { color: "#FFF9E2", label: "奥巴马执政时期" },
            { color: "#FCEFEE", label: "特朗普执政时期" },
            { color: "#EDF0FE", label: "拜登执政时期" },
        ];
        var legend2Group = svg.append("g")
            .attr("class", "legend2")


        var legend2Items = legend2Group.selectAll(".legend-item2")
            .data(legend2Data)
            .enter().append("g")
            .attr("class", "legend2-item")
            .attr("transform", function (d, i) {
                return "translate(" + ((i % 6) * 305 * width / 1500 + (width - 305 * 3 * width / 1500) / 2) + "," + (height * 1.15) + ") scale( +" + width / 1500 + ")"; // 一排显示六个，调整每个图例的位置
            });

        legend2Items.append("rect")
            .attr("class", "legend2-color")
            .attr("width", 250) // 设置矩形的宽度
            .attr("height", 30) // 设置矩形的高度
            .style("fill", function (d) {
                return d.color;
            });


        legend2Items.append("text")
            .attr("class", "legend2-label")
            .attr("x", 60)
            .attr("y", 20)
            .attr("font-size", "20px")
            .text(function (d) {
                return d.label;
            });


        const legendVisibility = {};
        // 创建一个对象来跟踪每个图例的可见性状态和颜色
        const legendState = {};

        legendItems.on('click', function (event, d) {
            const selectedLegend = d.label;

            // 切换图例的可见性状态
            legendState[selectedLegend] = !legendState[selectedLegend];

            // 根据图例的可见性状态返回相应的显示值
            path.style('display', function ([z]) {
                return legendState[z] ? 'initial' : 'none';
            });

            // 更改所有图例元素的颜色
            legendItems.selectAll('.legend-color')
                .style('fill', function (legendData) {
                    return legendState[legendData.label] ? divisionColors[legendData.label] : '#ccc';
                });

            // 切换点的可见性
            dot.selectAll('.data-point')
                .style('display', function (i) {
                    return legendState[Z[i]] ? 'initial' : 'none';
                });
        });


        const container = document.getElementById("media-attitude-chart-container");

        container.addEventListener('click', function (event) {
            if (!event.target.classList.contains('legend-item') &&
                !event.target.classList.contains('legend-label')) {
                path.style('display', 'initial');
                legendItems.selectAll('.legend-color').style('fill', function (legendData) {
                    return divisionColors[legendData.label];
                });
                dot.selectAll('.data-point').style('display', 'initial');
            }
        });


        const path = svg.append("g")
            .attr("fill", "none")
            .attr("stroke", typeof color === "string" ? color : null)
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
            .selectAll("path")
            .data(d3.group(I, i => Z[i]))
            .join("path")
            .style("mix-blend-mode", mixBlendMode)
            .attr("stroke", ([z]) => divisionColors[z])
            .attr("d", ([, I]) => line(I));
        const dotGroup = svg.append("g");

        const dot = dotGroup
            .selectAll("g")
            .data(I)
            .enter()
            .append("g")
            .attr("transform", i => `translate(${xScale(X[i])},${yScale(Y[i])})`);

        dot.append("circle")
            .attr("r", 2)
            .attr("fill", i => divisionColors[Z[i]])
            .attr("class", "data-point");

        dot.append("text")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("text-anchor", "middle")
            .attr("y", -8);

        function addBackgroundRects() {
            // 遍历配置的背景颜色范围，为每个范围添加矩形
            Object.entries(backgroundColors).forEach(([color, { startDate, endDate }]) => {
                // 使用 insert 方法将背景矩形插入到图表的底层
                svg.insert("rect", ":first-child")
                    .attr("x", xScale(startDate))
                    .attr("y", marginTop)
                    .attr("width", xScale(endDate) - xScale(startDate))
                    .attr("height", height - marginTop - marginBottom)
                    .attr("fill", color);
            });
        }

        // 在创建图表时添加背景矩形
        addBackgroundRects();

        return Object.assign(svg.node(), { value: null });

    }

    function handleTimeChange(value) {
        setSelectedOption(value);
    }

    function update() {
        if (!data) {
            return;
        }
        let startDate, endDate;
        switch (selectedOption) {
            case "2009-2017":
                startDate = new Date('2009-01-01');
                endDate = new Date('2017-01-01');
                break;
            case "2017-2021":
                startDate = new Date('2017-01-01');
                endDate = new Date('2021-01-01');
                break;
            case "2021":
                startDate = new Date('2021-01-01');
                endDate = new Date('2023-11-01');
                break;

            case "All":
            default:
                startDate = new Date('1980-06-30');
                endDate = new Date('2023-11-01');
                break;
        }

        const filteredData = data.filter(function (d) {
            return d.date >= startDate && d.date <= endDate;
        });
        const container = document.getElementById("media-attitude-chart-container");
        container.innerHTML = "";

        let chart = LineChart(filteredData, {
            x: (d) => d.date,
            y: (d) => d.emotion,
            z: (d) => d.division,
            xLabel: "年份",
            yLabel: "情感",
            width: window.innerWidth * 0.7,
            height: window.innerHeight * 0.55,
            color: "steelblue",
            xDomain: [startDate, endDate],
            backgroundColors: {
                "#FFF9E2": {
                    startDate: startDate > new Date('2009-01') ? startDate : new Date('2009-01'),
                    endDate: new Date('2017-01')
                },
                "#FCEFEE": {
                    startDate: startDate > new Date('2017-01') ? startDate : new Date('2017-01'),
                    endDate: new Date('2021-01')
                },
                "#EDF0FE": {
                    startDate: startDate > new Date('2021-01') ? startDate : new Date('2021-01'),
                    endDate: new Date(endDate)
                }
            }
        });
        container.appendChild(chart);
    }

    useEffect(() => {
        update()
    }, [data, selectedOption])

    useEffect(() => {
        d3.csv('linechart-data.csv').then(function (data_) {
            data_.forEach(function(d) {
              d.date = new Date(d.date); 
              d.emotion = +d.emotion; 
            });
            setData(data_)
        }).catch(function (error) {
            // 处理加载数据失败的情况
            console.error('Error loading CSV:', error);
        });
    }, [])

    return (
        <Card title={
            <Popover placement="bottom" content={props.description}>
                <div style={{ width: '100%', cursor: 'pointer' }}>
                    {props.title}
                </div>
            </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue={'All'}
                    options={items}
                    onChange={handleTimeChange}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
        >
            <div id="media-attitude-chart-container"></div>
        </Card>
    )
}

export default MediaAttitude;