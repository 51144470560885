import React, { useEffect, useState, useRef } from "react";
import { Card, Popover,Space, Select } from 'antd'
import * as d3 from "d3";

const MediaKeywordTrend = (props) => {
    const [data, setData] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState('纽约时报');
    const [selectedCSV, setSelectedCSV] = useState('output_count.csv');
    const [selectedYear, setSelectedYear] = useState('all');
    const container = useRef();

    function LineChart(data_, {
        x = ([x]) => x, // given d in data, returns the (temporal) x-value
        y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
        z = () => 1, // given d in data, returns the (categorical) z-value
        title, // given d in data, returns the title text
        defined, // for gaps in data
        curve = d3.curveLinear, // method of interpolation between points
        marginTop = 10, // top margin, in pixels
        marginRight = 40, // right margin, in pixels
        marginBottom = 50, // bottom margin, in pixels
        marginLeft = 30, // left margin, in pixels
        width = 640, // outer width, in pixels
        height = 400, // outer height, in pixels
        xType = d3.scaleUtc, // type of x-scale
        xDomain, // [xmin, xmax]
        xRange = [marginLeft, width - marginRight], // [left, right]
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat, // a format specifier string for the y-axis
        yLabel, // a label for the y-axis
        xLabel,
        zDomain, // array of z-values
        color = "currentColor", // stroke color of line, as a constant or a function of *z*
        strokeLinecap, // stroke line cap of line
        strokeLinejoin, // stroke line join of line
        strokeWidth = 1.5, // stroke width of line
        strokeOpacity, // stroke opacity of line
        mixBlendMode = "multiply", // blend mode of lines
        voronoi,// show a Voronoi overlay? (for debugging)
        backgroundColors = {
            "#FFF9E2": {
                startDate: new Date('2012-01'),
                endDate: new Date('2017-01')
            },
            "#FCEFEE": {
                startDate: new Date('2017-01'),
                endDate: new Date('2021-01')
            },
            "#EDF0FE": {
                startDate: new Date('2021-01'),
                endDate: new Date('2023-11')
            }
        }
    } = {}) {
        // Compute values.
        const divisionColors = {
            "上海+人工智能": '#D9B52C',
            "上海+生物医学": "#D41A24",
            "上海+集成电路": "#60A2CD",
            "人工智能": "#80C3B2",
            "生物医学": "#52B560",
            "集成电路": "#AD6DB1",
            
            "上海市市长": "#48D9E2",
            "北京": "#F9BFDF",
            "上海": "#F76E69",
            "香港": "#94AA26",
            "上海综合指数": "#FDB462",
            "上海证券交易所": "#9A6AFF"
        };


        const X = d3.map(data_, x);
        const Y = d3.map(data_, y);
        const Z = d3.map(data_, z);
        const O = d3.map(data_, d => d);
        if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
        const D = d3.map(data_, defined);

        // Compute default domains, and unique the z-domain.
        if (xDomain === undefined) xDomain = d3.extent(X);
        if (yDomain === undefined) yDomain = d3.extent(Y);;
        if (zDomain === undefined) zDomain = Z;
        zDomain = new d3.InternSet(zDomain);

        // Omit any data not present in the z-domain.
        const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

        const xScale = xType()
            .domain(xDomain)
            .range(xRange)
            .clamp(true);

        const absMax = Math.max(Math.abs(yDomain[0]), Math.abs(yDomain[1]));
        const symmetricXDomain = [-absMax, absMax];
        const yScale = d3.scaleLinear()
            .domain(symmetricXDomain)
            .range([height - marginBottom, marginTop])
            ;

        const timeFormat = d3.timeFormat('%Y-%m');
        const tickValues = xScale.ticks(30)
        .concat(new Date(2023, 9, 1)); 

        // 创建 x 轴并指定刻度值
        const xAxis = d3.axisBottom(xScale)
        .tickValues(tickValues)
        .tickFormat(timeFormat);

        const yAxis = d3.axisLeft(yScale).ticks(height / 60, yFormat);


        // Compute titles.
        const T = title === undefined ? Z : title === null ? null : d3.map(data, title);

        // Construct a line generator.
        const line = d3.line()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y(i => yScale(Y[i]));

            const svg = d3.create("svg")
                .attr("width", width)
                .attr("height", height * 1.1)
                .attr("viewBox", [-width * 0.01, 0, width+20, height * 1.03])
                .attr("style", "max-width: 100%;")
                .style("-webkit-tap-highlight-color", "transparent")

        if (voronoi) svg.append("path")
            .attr("fill", "none")
            .attr("stroke", "#ccc")
            .attr("d", d3.Delaunay
                .from(I, i => xScale(X[i]), i => yScale(Y[i]))
                .voronoi([0, 0, width, height])
                .render());

        const auxiliaryScaleContainer = svg.append("g")
            .attr("class", "auxiliary-scale")
            .attr("stroke", "#ccc");

        const numberOfAuxiliaryTicks = 30;

        const auxiliaryTickValues = xScale.ticks(numberOfAuxiliaryTicks);

        auxiliaryScaleContainer.selectAll(".auxiliary-tick")
            .data(auxiliaryTickValues)
            .enter().append("line")
            .attr("class", "auxiliary-tick")
            .attr("x1", d => xScale(d))
            .attr("x2", d => xScale(d))
            .attr("y1", marginTop) // 刻度线的起始位置，根据需要调整
            .attr("y2", height - marginBottom); // 刻度线的结束位置，根据需要调整

        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height / 2 - (marginBottom - marginTop) / 2})`)//迁移后可能还需要微调
            .call(xAxis)
            .call(g => g.selectAll(".tick text")
        .style("font-size", "14px")
        .attr("transform", "rotate(-45)")
        .attr("x", -30)
        .attr("y", 0)) 
                
            .select(".domain")
            .style("stroke", "blue")
            ;



            svg.append("g")
                .attr("transform", `translate(${marginLeft},0)`)
                .call(yAxis)
                .call(g => g.selectAll(".tick text")
                    .style("font-size", "18px"))
                .select(".domain")
                .style("stroke", "blue")
                .call(g => g.select(".domain").remove())
                .call(voronoi ? () => { } : g => g.selectAll(".tick line").clone()
                    .attr("x2", width - marginLeft - marginRight)
                    .attr("stroke-opacity", 0.1))
                .call(g => g.append("text")
                    .attr("x", marginLeft)
                    .attr("y", 10)
                    .attr("font-size", "18px")
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .text(yLabel));

            svg.append("text")
                .attr("x", marginLeft + 2)
                .attr("y", -5)
                .attr("text-anchor", "end")
                .attr("font-size", "18px")
                .text(yLabel);

            svg.append("text")
                .attr("x", width + 2-3)
                .attr("y", height / 2-19)
                .attr("text-anchor", "end")
                .attr("font-size", "18px")
                .text(xLabel);

        var legendData = [
            { color: "#D9B52C", label: "上海+人工智能" },
            { color: "#D41A24", label: "上海+生物医学" },
            { color: "#60A2CD", label: "上海+集成电路" },
             { color: "#80C3B2", label: "人工智能" },
            { color: "#52B560", label: "生物医学" },
            { color: "#AD6DB1", label: "集成电路" },
           
            { color: "#48D9E2", label: "上海市市长" },
            { color: "#F9BFDF", label: "北京" },
            { color: "#F76E69", label: "上海" },
            { color: "#94AA26", label: "香港" },
            { color: "#FDB462", label: "上海综合指数" },
            { color: "#9A6AFF", label: "上海证券交易所" },
        ];


        var legendGroup = svg.append("g")
            .attr("class", "legend")


            var legendItems = legendGroup.selectAll(".legend-item")
                .data(legendData)
                .enter().append("g")
                .attr("class", "legend-item")
                .attr("transform", function (d, i) {
                    return "translate(" + ((i % 6) * 280 * width / 2000 + (width - (260 * 6 * width / 2000)) / 2) + "," + (Math.floor(i / 6) * height * 0.05 + height * 0.99) + ") scale(" + width / 1500 + ")"; // 一排显示六个，调整每个图例的位置
                });

       

            legendItems.append("text")
                .attr("class", "legend-label")
                .attr("x", 22)
                .attr("y", 5)
                .attr("font-size", "18px")
                .text(function (d) {
                    return d.label;
                });
            legendItems.append("circle")
            .attr("class", "legend-color")
            .attr("r", 10)
            .style("fill", function (d) {
                return d.color;
            });

        const legendVisibility = {};
        // 创建一个对象来跟踪每个图例的可见性状态和颜色
        const legendState = {};

        legendItems.on('click', function (event, d) {
            const selectedLegend = d.label;

            // 切换图例的可见性状态
            legendState[selectedLegend] = !legendState[selectedLegend];

            // 根据图例的可见性状态返回相应的显示值
            path.style('display', function ([z]) {
                return legendState[z] ? 'initial' : 'none';
            });

            // 更改所有图例元素的颜色
            legendItems.selectAll('.legend-color')
                .style('fill', function (legendData) {
                    return legendState[legendData.label] ? divisionColors[legendData.label] : '#ccc';
                });

            // 切换点的可见性
            dot.selectAll('.data-point')
                .style('display', function (i) {
                    return legendState[Z[i]] ? 'initial' : 'none';
                });
        });


        const container = document.getElementById("media-keyword-trend-chart-container");

        container.addEventListener('click', function (event) {
            if (!event.target.classList.contains('legend-item') &&
                !event.target.classList.contains('legend-label')&& !event.target.classList.contains('legend-color')) {
                path.style('display', 'initial');
                legendItems.selectAll('.legend-color').style('fill', function (legendData) {
                    return divisionColors[legendData.label];
                });
                dot.selectAll('.data-point').style('display', 'initial');
            }
        });


        const path = svg.append("g")
            .attr("fill", "none")
            .attr("stroke", typeof color === "string" ? color : null)
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
            .selectAll("path")
            .data(d3.group(I, i => Z[i]))
            .join("path")
            .style("mix-blend-mode", mixBlendMode)
            .attr("stroke", ([z]) => divisionColors[z])
            .attr("d", ([, I]) => line(I));
        const dotGroup = svg.append("g");

        const dot = dotGroup
            .selectAll("g")
            .data(I)
            .enter()
            .append("g")
            .attr("transform", i => `translate(${xScale(X[i])},${yScale(Y[i])})`);

        dot.append("circle")
            .attr("r", 2)
            .attr("fill", i => divisionColors[Z[i]])
            .attr("class", "data-point");

        dot.append("text")
            .attr("font-family", "sans-serif")
            .attr("font-size", 16)
            .attr("text-anchor", "middle")
            .attr("y", -8);



        return Object.assign(svg.node(), { value: null });
    }

    function handleMediaChange(media_filter) {
        setSelectedMedia(media_filter);
        updateChart(data, media_filter, selectedCSV,selectedYear);
      }

    function handleCSVChange(csv_filter) {
        setSelectedCSV(csv_filter);
        updateData(csv_filter);
      }
    function handleYearChange(year_filter) {
        setSelectedYear(year_filter);
        updateChart(data, selectedMedia, selectedCSV,year_filter);
      }
      function updateChart(data, media_filter, csv_filter,year_filter) {
        if (data == null) {
          return;
        }
    
        let startDate = new Date('1980-06-30');
        let endDate = new Date('2023-11');
        switch (year_filter) {
            case "2013-2023":
                startDate = new Date('2013-01-01');
                endDate = new Date('2023-11-01');
                break;
            case "2018-2023":
                startDate = new Date('2018-01-01');
                endDate = new Date('2023-11-01');
                break;
            case "all":
            default:
                startDate = new Date('1980-06-30');
                endDate = new Date('2023-11-01');
                break;
        }
        const filteredData = data.filter(function (d) {
          return d.date >= startDate && d.date <= endDate && d.media === media_filter;
        });

        let yLabel = '';
    
        if (csv_filter === 'output_emotion.csv') {
          yLabel = '情感';
        } else if (csv_filter === 'output_count.csv') {
          yLabel = '数量';
        }
    
        let chart = LineChart(filteredData, {
          x: d => d.date,
          y: d => d.emotion,
          z: d => d.division,
          xLabel: '时间',
          yLabel, // You can customize the Y label here
          width: window.innerWidth *0.67,
          height: window.innerHeight * 0.55,
          color: 'steelblue',
          xDomain: [startDate, endDate],
        });
    
        container.current.innerHTML = '';
        container.current.appendChild(chart);
      }
    
      function updateData(csv_filter) {
        d3.csv(csv_filter)
          .then(function (data_) {
            data_.forEach(function (d) {
              d.date = new Date(d.date);
              d.emotion = +d.emotion;
            });
            setData(data_);
            updateChart(data_, selectedMedia, csv_filter);
          })
          .catch(function (error) {
            console.error('加载 CSV 数据时出错:', error);
          });
      }
    
      useEffect(() => {
        updateData(selectedCSV);
      }, [selectedCSV]);
    
      useEffect(() => {
        handleMediaChange(selectedMedia);
      }, [data]);

      useEffect(() => {
        handleYearChange(selectedYear);
      }, [data]);

    return (
        <Card title={
            <Popover placement="bottom" content={props.description}>
                <div style={{ width: '100%', cursor: 'pointer' }}>
                    {props.title}
                </div>
            </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue={'all'}
                    options={[
                        {
                            label: "全部",
                            value: "all"
                        },
                        {
                            label: "近10年",
                            value: "2013-2023"
                        },
                        {
                            label: "近5年",
                            value: "2018-2023"
                        }
                    ]}
                    onChange={handleYearChange}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
        >
        <div>
            <div id="content">        
                <Space wrap>
                <Select
                    size='large'
                    defaultValue={'纽约时报'}
                    options={[
                        {
                            label: "纽约时报",
                            value: "纽约时报"
                        },
                        {
                            label: "华盛顿邮报",
                            value: "华盛顿邮报"
                        },
                        {
                            label: "芝加哥论坛报",
                            value: "芝加哥论坛报"
                        },
                        {
                            label: "洛杉矶时报",
                            value: "洛杉矶时报"
                        },
                        {
                            label: "华尔街日报",
                            value: "华尔街时报"
                        },
                        {
                            label: "华尔街日报（线上）",
                            value: "华尔街时报（线上）"
                        },
                        {
                            label: "所有媒体",
                            value: "所有媒体"
                        },
                    ]}
                    onChange={handleMediaChange}
                >
                </Select>
                <Select
                    size="large"
                    defaultValue={'output_count.csv'}
                    options={[
                    {
                        label: "数量",
                        value: "output_count.csv"
                    },
                    {
                        label: "情感",
                        value: "output_emotion.csv"
                    }]}
                    onChange={handleCSVChange}
                ></Select>

                </Space>
                <div id="media-keyword-trend-chart-container" ref={container}>
                </div>
            </div>
        </div>
        </Card>
    )
}

export default MediaKeywordTrend;