import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import * as d3Sankey from "d3-sankey";
import "./style.css"

function splitAndMergeString(inputString) {
    const words = inputString.split(' '); // 通过空格切分字符串
    const result = [];
    let currentLine = '';

    for (const word of words) {
        if ((currentLine + ' ' + word).length <= 15) {
            // 如果将当前单词添加到当前行后不超过15字符，就添加到当前行
            if (currentLine !== '') {
                currentLine += ' ';
            }
            currentLine += word;
        } else {
            // 否则，将当前行添加到结果数组，并重新开始一行
            result.push(currentLine);
            currentLine = word;
        }
    }

    // 添加最后一行到结果数组
    if (currentLine !== '') {
        result.push(currentLine);
    }
    return result;
}
const draw = (props) => {
    const margin = { top: 10, bottom: 10, left: 10, right: 10 };
    const chartWidth = props.width - margin.left - margin.right;
    const chartHeight = props.height - margin.top - margin.bottom;
    const customColors = ["#FEB462", "#87CBBF", "#BEBADA", "#E9EB94", "#B3DE69", "#FCCDE5", "#FB8072", "#D9D9D9", "#BC80BD", "#9BC9EA", "#9BEAB6", "#B1BA78"];
    const colorScale = d3.scaleOrdinal().range(customColors);
    let nodeWidth = 100;
    let container = '#rankflow2ChartContainer';

    // Creates sources <svg> element
    d3.select("#rankflow2-chart").remove()
    const svg = d3.select(container)
        .append("svg")
        .attr("id", "rankflow2-chart")
        .attr("viewBox", [0, 0, chartWidth + chartWidth / 10 + 40, chartHeight + 10])
        .attr("width", chartWidth)
        .attr("height", chartHeight)
        .attr("style", "max-width: 100%; height: auto; ")

    const chart = svg.append("g")
        .attr("transform", `translate(${chartWidth / 10 + 10}, -10)`);
    const titleG = svg.append("g")
        .attr("transform", `translate(${chartWidth / 10 + 10}, -14)`);

    // Group used to enforce margin
    let nameIndex = {};

    const tooltip = d3.select(container)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0); // Initially hide the tooltip

    // Global variable for all data
    d3.csv("right.csv").then((csv) => {
        const nodes = [];
        const links = [];
        var grouped = {};
        csv.forEach((obj) => {
            var name = obj.name;
            if (!grouped[name]) {
                grouped[name] = [];
            }
            grouped[name].push(obj);
            nameIndex[name] = obj;
        });

        let maxYearNum = 0;
        let count = 1;
        for (var name in grouped) {
            var group = grouped[name];
            maxYearNum = Math.max(maxYearNum, group.length);
            group.sort((a, b) => {
                return a.time - b.time;
            })
            for (let i = 0; i < group.length - 1; i++) {
                var source = group[i];
                var target = group[i + 1];
                var link = {
                    source: source.name + "|" + source.time,
                    target: target.name + "|" + target.time,
                    value: target.value
                };
                links.push(link);
                nodes.push({
                    name: source.name + "|" + source.time,
                    fixedValue: parseFloat(source.value)
                });
            }
            nodes.push({
                name: group[group.length - 1].name + "|" + group[group.length - 1].time,
                fixedValue: parseFloat(group[group.length - 1].value)
            });
            count += 1;
            if (count > 10) {
                break;
            }
        }
        nodeWidth = (chartWidth / maxYearNum) * (2 / 3);
        update(nodes, links);
    });
    function update(nodes_, links_) {
        // Constructs and configures a Sankey generator.
        const sankey = d3Sankey.sankey()
            .nodeId(d => d.name)
            .nodeAlign(d3Sankey.sankeyLeft) // d3.sankeyLeft, etc.
            .nodeWidth(nodeWidth)
            .nodeSort((a, b) => {
                return b.value - a.value;
            })
            .extent([[1, 5], [chartWidth - 0, chartHeight + 10]]);

        // Applies it to the data. We make a copy of the nodes and links objects
        // so as to avoid mutating the original.
        const { nodes, links } = sankey({
            nodes: nodes_.map(d => Object.assign({}, d)),
            links: links_.map(d => Object.assign({}, d))
        });
        for (var l of links) {
            l.widthStart = l.source.y1 - l.source.y0;
            l.widthEnd = l.target.y1 - l.target.y0;
        }
        // Adds labels on the column.
        let columnTitles = {};
        let rowTitles = {};
        let nodeStartX = 100000;
        let nodeEndX = 0;
        let yearStart = 100000;
        let yearEnd = 0;
        let accumulatorY = {}
        nodes.sort((a, b) => a.y0 - b.y0).forEach(node => {
            let curYear = node.name.split("|")[1];
            let name = node.name.split("|")[0];
            let height = chartHeight / 8.5 // Math.max(10, node.y1 - node.y0);
            if (!(curYear in accumulatorY)) {
                accumulatorY[curYear] = 7;
            }
            node.y0 = accumulatorY[curYear] + 7;
            node.y1 = accumulatorY[curYear] + 7 + height;
            accumulatorY[curYear] = node.y1;
            if (!columnTitles[node.x0 + node.x1]) {
                columnTitles[node.x0 + node.x1] = { title: curYear, x: (node.x0 + node.x1) / 2, y: node.y0 };
            } else {
                columnTitles[node.x0 + node.x1].y = Math.min(columnTitles[node.x0 + node.x1].y, node.y0);
            }
            if (!rowTitles[name]) {
                rowTitles[name] = { url: node.url, x: node.x0 + 20, y: (node.y0 + node.y1) / 2 + 10, year: curYear, title: name };
            } else {
                rowTitles[name].x = Math.min(rowTitles[name].x, node.x0 + 20);
                if (curYear < rowTitles[name].year) {
                    rowTitles[name].y = (node.y0 + node.y1) / 2 + 10;
                    rowTitles[name].year = curYear;
                }
            }
            nodeStartX = Math.min(nodeStartX, node.x0);
            nodeEndX = Math.max(nodeEndX, node.x1);
            yearStart = Math.min(yearStart, parseInt(curYear));
            yearEnd = Math.max(yearEnd, parseInt(curYear));
        });

        // Creates the rects that represent the nodes.
        const rectStrokeWidth = 3;
        const container = chart.append("g")
        const rect = container
            .selectAll()
            .data(nodes)
            .join("rect")
            .attr("x", d => d.x0)
            .attr("y", d => d.y0)
            .attr("height", d => d.y1 - d.y0)
            .attr("width", d => d.x1 - d.x0)
            .attr("fill", d => colorScale(d.name.split("|")[0]))
            .attr("fill-opacity", 0.8)
            .attr("stroke", "white")
            .attr("stroke-width", rectStrokeWidth)
            .on("mouseover", function (d) {
                const myData = d3.select(this).data()[0]
                var hoveredRect = d3.select(this)
                // 获取矩形的位置和尺寸
                var rectX = parseFloat(hoveredRect.attr("x"));
                var rectY = parseFloat(hoveredRect.attr("y"));
                var rectWidth = parseFloat(hoveredRect.attr("width"));
                var rectHeight = parseFloat(hoveredRect.attr("height"));

                // 计算文本的位置
                var textX = rectX + rectWidth / 2;
                var textY = rectY + rectHeight / 2;

                // 在容器中添加文本
                container.append("text")
                    .attr("x", textX)
                    .attr("y", textY)
                    .style("font-size", "12px")
                    .attr("text-anchor", "middle") // 文本居中对齐
                    .attr("alignment-baseline", "middle") // 文本垂直居中对齐
                    .text(parseFloat(myData.fixedValue).toFixed(3))
                    .style("user-select", "none"); // 替换为你想要显示的文本
            })
            .on("mouseout", function (event, d) {
                const [mouseX, mouseY] = d3.pointer(event);
                let element = d3.select(this).data()[0];
                if (mouseX < element.x0 || mouseX > element.x1 || mouseY < element.y0 || mouseY > element.y1) {
                    container.selectAll("text").remove();
                }
            });

        // Creates the paths that represent the links.
        const link = chart.append("g")
            .attr("fill", "none")
            .attr("fill-opacity", 0.8)
            .selectAll()
            .data(links)
            .enter()
            .append("polygon")
            .attr("points", function (d) {
                return `${d.source.x1},${d.source.y0 + rectStrokeWidth / 2} ${d.source.x1},${d.source.y1 - rectStrokeWidth / 2} ${d.target.x0},${d.target.y1 - rectStrokeWidth / 2} ${d.target.x0},${d.target.y0 + rectStrokeWidth / 2}`;
            })
            .attr("fill", d => colorScale(d.source.name.split("|")[0]));

        Object.entries(columnTitles).forEach(([x, title]) => {
            titleG.append("text")
                .attr("x", title.x)
                .attr("y", title.y - 1)
                .attr("text-anchor", "middle")
                .style("font-size", "13px")
                .text(title.title);
        });
        let rowTitlesList = [];
        Object.entries(rowTitles).forEach(([x, title]) => {
            rowTitlesList.push(title);
        });
        rowTitlesList = rowTitlesList.sort((a, b) => a.y - b.y)
        let titleElements = titleG.selectAll('rowTitle2')
            .data(rowTitlesList)
            .enter()
            .append('g')
            .attr("class", "rowTitle2")
            .on("click", function (d) {
                const myData = d3.select(this).data()[0]
                rect.filter(d => d.name.split("|")[0] !== myData.title).attr("fill", "grey").attr("fill-opacity", 0.1)
                link.filter(d => d.source.name.split("|")[0] !== myData.title).attr("fill", "grey").attr("fill-opacity", 0.1)
                rect.filter(d => d.name.split("|")[0] === myData.title).attr("fill", d => colorScale(d.name.split("|")[0])).attr("fill-opacity", 0.8).raise()
                link.filter(d => d.source.name.split("|")[0] === myData.title).attr("fill", d => colorScale(d.source.name.split("|")[0])).attr("fill-opacity", 0.8).raise()
            });
        let accumulatorRowTitleY = 0;
        titleElements.append("text")
            .attr("class", "rowTitle2")
            .attr("x", title => title.x - 46)
            .attr("y", title => {
                if (title.y - accumulatorRowTitleY < 30) {
                    title.y = accumulatorRowTitleY + 25;
                }
                accumulatorRowTitleY = Math.max(accumulatorRowTitleY, title.y)
                let splittedName = splitAndMergeString(title.title);
                return title.y - 7 * (splittedName.length - 1)
            })
            .attr("text-anchor", "end")
            .html(title => {
                let splittedName = splitAndMergeString(title.title);
                let result = "";
                for (let i = 0; i < splittedName.length; i++) {
                    if (i == 0) {
                        result += `<tspan class="rowTitle2" x=${title.x - 46}>`;
                    } else {
                        result += `<tspan class="rowTitle2" x=${title.x - 46} dy='1.2em'>`;
                    }
                    result += splittedName[i];
                    result += "</tspan>";
                }
                return result;
            });
        svg.on("click", function (d) {
            if (!d.target.classList.contains('rowTitle2')) {
                rect.attr("fill", d => colorScale(d.name.split("|")[0])).attr("fill-opacity", 0.8)
                link.attr("fill", d => colorScale(d.source.name.split("|")[0])).attr("fill-opacity", 0.8)
            }
        })
    }
}

const Rankflow2Chart = () => {
    const rankflow2ChartContainerRef = useRef(null);
    useEffect(() => {
        if (rankflow2ChartContainerRef.current) {
            const chartProps = {
                width: rankflow2ChartContainerRef.current.offsetWidth,
                height: rankflow2ChartContainerRef.current.offsetHeight
            }
            draw(chartProps)
        }
    }, []);
    return (
        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div id="infoCard"></div>
            <div id="rankflow2ChartContainer" ref={rankflow2ChartContainerRef} style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}></div>
        </div>
    );
};

export default Rankflow2Chart;