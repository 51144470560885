/**
 * Author: chying
 * github: chenying2929
 * createdDate: 2023-10-21
 * lastEditDate: 2023-10-24
 */
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { stackData } from "./data";

const draw = (props) => {
    const margin = { top: 10, bottom: 10, left: 10, right: 10 };
    const chartWidth = props.width - margin.left - margin.right;
    const chartHeight = props.height - margin.top - margin.bottom;
    const container = "#stackChartContainer";

    d3.select("#stack-chart").remove()
    const svg = d3.select(container)
        .append("svg")
        .attr("id", "stack-chart")
        .attr("viewBox", [0, 0, chartWidth, chartHeight])
        .attr("width", chartWidth)
        .attr("height", chartHeight)
        .attr("style", "max-width: 100%; height: auto; ")

    const chartMain = svg.append("g")
        .attr("width", chartWidth * 0.95)
        .attr("height", chartHeight * 0.85)
        .attr("transform", `translate(${chartWidth * 0.05}, 0)`)

    // creat scales
    const xScale = d3.scaleBand()
        .domain(props.data.map(d => d.date))
        .range([0, chartWidth * 0.95])
        .padding(0.1);
    const yScale = d3.scaleLinear()
        .domain([0, d3.max(props.data, d => d.action + d.cause + d.others + 600)])
        .range([chartHeight * 0.85, 0]);
    const colorScale = d3.scaleOrdinal()
        .domain(["action", "cause", "others"])
        .range(["#836F93", "#60A2CD", "#FDB462"]);

    // plot the X axes
    chartMain.append("g")
        .attr("transform", `translate(0, ${chartHeight * 0.85})`)
        .call(d3.axisBottom(xScale)
            .tickValues(props.data.map((d, i) => i % 2 === 0 ? d.date : null).filter(d => d !== null))
            .tickSize(0)
        )
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("transform", "rotate(-45)")

    // plot the Y axes
    chartMain.append("g")
        .call(d3.axisLeft(yScale).tickSize(-chartWidth))
        .selectAll("line")
        .style("stroke", "#ccc")
    
    // modification of horizontal and vertical axis line colors
    chartMain.selectAll("path")
        .filter(function () {return d3.select(this).attr("stroke") === "currentColor";})
        .style("stroke", "none")
    
    // modification of text size in x and y aixs
    chartMain.selectAll("text")
            .style("font-size","14px");

    // organize stacked data
    const stack = d3.stack()
        .keys(["action", "cause", "others"])
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);
    const stackedData = stack(props.data);
    const groups = chartMain.selectAll().data(stackedData)
    const heaps = groups.enter()
        .append('g')
        .attr('class', (d) => 'g ' + d.key)
        .attr('fill', (d, i) => colorScale(d.key))
    const bars = heaps.selectAll().data((d) => {
        return d.map((item) => {
            item.index = d.index
            item.name = d.key
            return item
        })
    })
    // draw stacked bars
    bars.enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => xScale(d.data.date))
        .attr('y', (d) => yScale(d[1]))
        .attr('width', xScale.bandwidth())
        .attr('height', (d) => yScale(d[0]) - yScale(d[1]))

    // add legends
    const legendData = [
        { name: "右翼政治行动", width: 100, colorTag: "action" },
        { name: "右翼引发的政治行动", width: 230, colorTag: "cause" },
        { name: "其他政治行动", width: 400, colorTag: "others" }
    ]
    const legend = svg.append("g")
        .attr("transform", `translate(${chartWidth * 0.3}, ${chartHeight * 0.95})`);

    legend.selectAll("rect")
        .data(legendData)
        .enter()
        .append("rect")
        .attr("x", (d, i) => d.width)
        .attr("y", 0)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", d => colorScale(d.colorTag));

    legend.selectAll("text")
        .data(legendData)
        .enter()
        .append("text")
        .attr("x", (d, i) => d.width + 18)
        .attr("y", 12)
        .text(d => d.name);

    // add tooltips
    const tooltip = d3.select(container)
        .append("div")
        .attr("class", "tooltip")
        .style("width", "160px")
        .style("height", "auto")
        .style("opacity", 0)
        .style("font-size","14px");

    d3.selectAll('.bar').on("mouseover", function (event, d) {
        const [mouseX, mouseY] = d3.pointer(event);
        const tooltipX = mouseX + 100;
        const tooltipY = mouseY + 10;
        let tooltipContent = "";
        if (d.data.date === "201803" || d.data.date === "201804") {
            tooltipContent = "“激进化” 节点一：2018 美国学生控枪大游行"
        } else if (d.data.date === "202006") {
            tooltipContent = "“激进化” 节点二：2020 “黑人命贵” 运动"
        } else if (d.data.date === "202210") {
            tooltipContent = "“激进化” 节点三：2022 右翼反堕胎大游行"
        }
        if (tooltipContent !== "") {
            tooltip.transition()
                .duration(200)
                .style("opacity", 0.9);

            tooltip.html(`${tooltipContent}`)
                .style("font-family", "Source Han Sans")
                .style("left", tooltipX + "px")
                .style("top", tooltipY + "px");
        }
    })
        .on("mouseout", function (d) {
            tooltip.transition()
                .duration(500)
                .style("opacity", 0);
        })
    return svg;
}

const StackChart = () => {
    const stackChartContainerRef = useRef(null);
    const [stackChartData] = useState(stackData);

    useEffect(() => {
        if (stackChartContainerRef.current) {
            const chartProps = {
                width: stackChartContainerRef.current.offsetWidth,
                height: stackChartContainerRef.current.offsetHeight,
                data: stackChartData
            }
            draw(chartProps)
        }
    }, [stackChartData]);
    return (
        <div id="stackChartContainer" ref={stackChartContainerRef} style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}></div>
    );
};

export default StackChart;