import React, { useEffect } from "react";
import * as d3 from "d3";
import "./style.css"

var RadarChart = {
	draw: function(id, d, options){
	var cfg = {
	   radius: 0,
	   w: 100,
	   h: 100,
	   factor: 1,
	   factorLegend: .85,
	   levels: 3,
	   maxValue: 0,
	   radians: 2 * Math.PI,
	   opacityArea: 0.5,
	   ToRight: 5,
	   TranslateX: 140,
	   TranslateY: 40,
	   ExtraWidthX: 100,
	   ExtraWidthY: 100,

	  };
	  
	  if('undefined' !== typeof options){
		for(var i in options){
		  if('undefined' !== typeof options[i]){
			cfg[i] = options[i];
		  }
		}
	  }
	  cfg.maxValue = Math.max(cfg.maxValue, d3.max(d, function(i){return d3.max(i.map(function(o){return o.value;}))}));
	  var allAxis = (d[0].map(function(i, j){return i.axis}));
	  var total = allAxis.length;
	  var radius = cfg.factor*Math.min(cfg.w/2, cfg.h/2);
	  var Format = d3.format('');
	  d3.select(id).select("svg").remove();
	  
	  var g = d3.select(id)
			  .append("svg")
			  .attr("width", 160)
			  .attr("height", 160)
              .attr("style", "max-width: 100%; height: auto; ")
              .attr("viewBox", [-60, -30, 210, 170])
			  .append("g")
			  ;
  
	  var tooltip;
	  
	  //Circular segments
	  for(var j=0; j<cfg.levels; j++){
		var levelFactor = cfg.factor*radius*((j+1)/cfg.levels);
		g.selectAll(".levels")
		 .data(allAxis)
		 .enter()
		 .append("svg:line")
		 .attr("x1", function(d, i){return levelFactor*(1-cfg.factor*Math.sin(i*cfg.radians/total));})
		 .attr("y1", function(d, i){return levelFactor*(1-cfg.factor*Math.cos(i*cfg.radians/total));})
		 .attr("x2", function(d, i){return levelFactor*(1-cfg.factor*Math.sin((i+1)*cfg.radians/total));})
		 .attr("y2", function(d, i){return levelFactor*(1-cfg.factor*Math.cos((i+1)*cfg.radians/total));})
		 .attr("class", "line")
		 .style("stroke", "rgb(176,176,176)")
		 .style("stroke-opacity", "0.75")
		 .style("stroke-width", "1px")
		 .attr("transform", "translate(" + (cfg.w/2-levelFactor) + ", " + (cfg.h/2-levelFactor) + ")");
	  }
  
	  //Text indicating at what % each level is
	  for(var j=0; j<cfg.levels; j++){
		var levelFactor = cfg.factor*radius*((j+1)/cfg.levels);
		g.selectAll(".levels")
		 .data([1]) //dummy data
		 .enter()
		 .append("svg:text")
		 .attr("x", function(d){return levelFactor*(1-cfg.factor*Math.sin(0));})
		 .attr("y", function(d){return levelFactor*(1-cfg.factor*Math.cos(0));})
		 .attr("class", "legend")
		 .style("font-family", "sans-serif")
		 .style("font-size", "8px")
		 .attr("transform", "translate(" + (cfg.w/2-levelFactor - cfg.ToRight) + ", " + (cfg.h/2-levelFactor) + ")")
		  .attr("fill", "rgb(71,71,71)")
		 .text(Format((j+1)*cfg.maxValue/cfg.levels.toFixed(1)));
	  }
	  
	  let series = 0;
  
	  var axis = g.selectAll(".axis")
			  .data(allAxis)
			  .enter()
			  .append("g")
			  .attr("class", "axis");
  
	  axis.append("line")
		  .attr("x1", cfg.w/2)
		  .attr("y1", cfg.h/2)
		  .attr("x2", function(d, i){return cfg.w/2*(1-cfg.factor*Math.sin(i*cfg.radians/total));})
		  .attr("y2", function(d, i){return cfg.h/2*(1-cfg.factor*Math.cos(i*cfg.radians/total));})
		  .attr("class", "line")
		  .style("stroke", "rgb(176,176,176)")
		  .style("stroke-opacity", "0.75")
		  .style("stroke-width", "1px");
  
	  axis.append("text")
		  .attr("class", "legend")
		  .text(function(d){return d})
		  .style("font-family", "sans-serif")
		  .style("font-size", "12px")
		  .attr("text-anchor", "middle")
		  .attr("dy", "1.5em")
		  .attr('fill','rgb(71,71,71)')
		  .attr("transform", function(d, i){return "translate(0, -10)"})
		  .attr("x", function(d, i){return cfg.w/2*(1-cfg.factorLegend*Math.sin(i*cfg.radians/total))-40*Math.sin(i*cfg.radians/total);})
		  .attr("y", function(d, i){return cfg.h/2*(1-Math.cos(i*cfg.radians/total))-18*Math.cos(i*cfg.radians/total);});
  
  
          let dataValues = [];
	  d.forEach(function(y, x){
		g.selectAll(".nodes")
		  .data(y, function(j, i){
			dataValues.push([
			  cfg.w/2*(1-(parseFloat(Math.max(j.value, 0))/cfg.maxValue)*cfg.factor*Math.sin(i*cfg.radians/total)), 
			  cfg.h/2*(1-(parseFloat(Math.max(j.value, 0))/cfg.maxValue)*cfg.factor*Math.cos(i*cfg.radians/total))
			]);
		  });
		dataValues.push(dataValues[0]);
		g.selectAll(".area")
					   .data([dataValues])
					   .enter()
					   .append("polygon")
					   .attr("class", "radar-chart-serie"+series)
					   .style("stroke-width", "1px")
					   .style("stroke", "rgb(153,199,224)")
					   .attr("points",function(d) {
						   var str="";
						   for(var pti=0;pti<d.length;pti++){
							   str=str+d[pti][0]+","+d[pti][1]+" ";
						   }
						   return str;
						})
					   .style("fill", "rgb(204,227,239)")
					   .style("fill-opacity", cfg.opacityArea)
					   .on('mouseover', function (d){
										  let z = "polygon."+d3.select(this).attr("class");
										  g.selectAll("polygon")
										   .transition(200)
										   .style("fill-opacity", 0.1); 
										  g.selectAll(z)
										   .transition(200)
										   .style("fill-opacity", .7);
										})
					   .on('mouseout', function(){
										  g.selectAll("polygon")
										   .transition(200)
										   .style("fill-opacity", cfg.opacityArea);
					   });
		series++;
	  });
	  series=0;
  
  
	  d.forEach(function(y, x){
		g.selectAll(".nodes")
		  .data(y).enter()
		  .append("svg:circle")
		  .attr("class", "radar-chart-serie"+series)
		  .attr('r', cfg.radius)
		  .attr("alt", function(j){return Math.max(j.value, 0)})
		  .attr("cx", function(j, i){
			dataValues.push([
			  cfg.w/2*(1-(parseFloat(Math.max(j.value, 0))/cfg.maxValue)*cfg.factor*Math.sin(i*cfg.radians/total)), 
			  cfg.h/2*(1-(parseFloat(Math.max(j.value, 0))/cfg.maxValue)*cfg.factor*Math.cos(i*cfg.radians/total))
		  ]);
		  return cfg.w/2*(1-(Math.max(j.value, 0)/cfg.maxValue)*cfg.factor*Math.sin(i*cfg.radians/total));
		  })
		  .attr("cy", function(j, i){
			return cfg.h/2*(1-(Math.max(j.value, 0)/cfg.maxValue)*cfg.factor*Math.cos(i*cfg.radians/total));
		  })
		  .attr("data-id", function(j){return j.axis})
		//   .style("fill", cfg.color(series))
          .style("fill-opacity", .9)
		  .on('mouseover', function (d){
					  let newX =  parseFloat(d3.select(this).attr('cx')) - 10;
					  let newY =  parseFloat(d3.select(this).attr('cy')) - 5;
					  
					  tooltip
						  .attr('x', newX)
						  .attr('y', newY)
						  .text(Format(d.value))
						  .transition(200)
						  .style('opacity', 1);
						  
					  let z = "polygon."+d3.select(this).attr("class");
					  g.selectAll("polygon")
						  .transition(200)
						  .style("fill-opacity", 0.1); 
					  g.selectAll(z)
						  .transition(200)
						  .style("fill-opacity", .7);
					})
		  .on('mouseout', function(){
					  tooltip
						  .transition(200)
						  .style('opacity', 0);
					  g.selectAll("polygon")
						  .transition(200)
						  .style("fill-opacity", cfg.opacityArea);
					})
		  .append("svg:title")
		  .text(function(j){return Math.max(j.value, 0)});
  
		series++;
	  });
	  //Tooltip
	  tooltip = g.append('text')
				 .style('opacity', 0)
				 .style('font-family', 'sans-serif')
				 .style('font-size', '13px');
	}
  };

function radar(container,divid,csv_type,csv_data,name,date){
	
	var w = 90,
		h = 90;
	var dd=[];
	var LegendOptions =[];
	var type=[];
	d3.csv(csv_type).then(function(ty) {
		const tyArray = Array.from(ty);
		tyArray.forEach(function(ty_d){
				type.push(ty_d.typename);
			});	
		
			d3.csv(csv_data).then(function(data) {
		const dataArray = Array.from(data);	
		dataArray.forEach(function(d){
				
				if(d.name==name && d.date==date){
					// console.log(d)
				LegendOptions.push(d.name);	
				var tmp=[
					{axis:type[0],value:d.Vis_media},
					{axis:type[1],value:d.Vis_issue},
					{axis:type[2],value:d.Vis_whitehouse},
					{axis:type[3],value:d.Vis_domain},
					{axis:type[4],value:d.Vis_thinktank},
				  ];
				dd.push(tmp);
	
				var totalValue = parseFloat(d.total);
				var influenceValueDiv =container.querySelector(".influence_value");
				influenceValueDiv.textContent = totalValue.toFixed(2); 
	
				var partyValue = d.party;
				var partyValueDiv = container.querySelector(".party_name");
				partyValueDiv.textContent = partyValue;
	
				var cnValue = d.chinesename;
				var cnDiv =container.querySelector(".chinese_name");
				cnDiv.textContent = cnValue;
	
				var typeValue = d.type;
				var typeDiv =container.querySelector(".type");
				typeDiv.textContent = typeValue;

	
	
	
				}
			}
			);
			
		
		//Options for the Radar chart, other than default
		var mycfg = {
		  w: w,
		  h: h,
		  maxValue: 1,
		  levels: 5,
		  ExtraWidthX: 200
		}
	
	//Call function to draw the Radar chart
	//Will expect that data is in %'s
	RadarChart.draw(divid, dd, mycfg);
	
	
	var svg = d3.select(divid)
		.selectAll('svg')
		.append('svg:g')
		.attr("width", 160)
		.attr("height", 200)
		});
	});
	}

const RadarChartList = () => {
    useEffect(() => {
        const radarContainers = document.querySelectorAll(".radar-container");

        radarContainers.forEach(container => {

          const nameDiv = container.querySelector(".radar-container .name");
          const name = nameDiv.textContent;
          const nameWithBrackets = name.replace(/(.+)/, '($1)');
          nameDiv.textContent = nameWithBrackets;
  
          const prevDateButton = container.querySelector(".prevDate");
          const nextDateButton = container.querySelector(".nextDate");
          const dateValueSpan = container.querySelector(".date_value");
  
          d3.csv('radardata.csv').then(function(data) {
            // 创建一个对象来存储每个人名对应的日期列表
            const nameToDateMap = {};
            const dataArray = Array.from(data);
            // 遍历CSV数据，将日期按人名分组存储
            dataArray.forEach(item => {
              
              const name = item.name;
              const date = item.date;
              if (!nameToDateMap[name]) {
                nameToDateMap[name] = [];
              }
  
              nameToDateMap[name].push(date);
            });
  
            const nameDiv = container.querySelector(".radar-container .name"); // 修改为你想要获取的类名
            
            // 获取名字的文本内容
            const name = nameDiv.textContent.replace(/[()]/g, '');
            console.log(name)
            let currentDateIndex = 0;
          
            radar(container,container.querySelector(".chart"), 'type.csv', 'radardata.csv', name, nameToDateMap[name][currentDateIndex]);
              console.log(container.querySelector(".chart"))
            prevDateButton.addEventListener("click", () => {
              // 切换到上一个日期
              currentDateIndex = (currentDateIndex - 1 + nameToDateMap[name].length) % nameToDateMap[name].length;
              const selectedDate = nameToDateMap[name][currentDateIndex];
  
              // 更新日期显示
              dateValueSpan.textContent = selectedDate;
  
              // 更新雷达图
              radar(container,container.querySelector(".chart"), 'type.csv', 'radardata.csv', name, selectedDate);
            });
  
            nextDateButton.addEventListener("click", () => {
              // 切换到下一个日期
              currentDateIndex = (currentDateIndex + 1) % nameToDateMap[name].length;
              const selectedDate = nameToDateMap[name][currentDateIndex];
  
              // 更新日期显示
              dateValueSpan.textContent = selectedDate;
  
              // 更新雷达图
              radar(container,container.querySelector(".chart"), 'type.csv', 'radardata.csv', name, selectedDate);
            });
          });
        });
    }, []);

    return (
        <div class="radar-container-container"style={{overflow: "auto"}}>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Joe_Biden_presidential_portrait.jpg/220px-Joe_Biden_presidential_portrait.jpg" alt="Avatar"/>

                        <div class="name">Joseph Robinette Biden Jr.</div>

                        <div class="chinese_name"></div>

                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>

                        <div class="party_name"></div>
                        <div class="party">政党</div>

                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>

                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>


                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Donald_Trump_official_portrait.jpg/220px-Donald_Trump_official_portrait.jpg" alt="Avatar"/>

                        <div class="name">Donald John Trump</div>

                        <div class="chinese_name"></div>

                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>

                        <div class="party_name"></div>
                        <div class="party">政党</div>

                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>

                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Secretary_Blinken%27s_Official_Department_Photo.jpg/220px-Secretary_Blinken%27s_Official_Department_Photo.jpg" alt="Avatar"/>

                        <div class="name">Antony John Blinken</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Kamala_Harris_Vice_Presidential_Portrait.jpg/220px-Kamala_Harris_Vice_Presidential_Portrait.jpg" alt="Avatar"/>

                        <div class="name">Kamala Devi Harris</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Jake-Sullivan-WH.png/220px-Jake-Sullivan-WH.png" alt="Avatar"/>

                        <div class="name">Jake Sullivan</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/President_Barack_Obama.jpg/220px-President_Barack_Obama.jpg" alt="Avatar"/>

                        <div class="name">Barack Obama</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Mike_Pence_official_Vice_Presidential_portrait.jpg/220px-Mike_Pence_official_Vice_Presidential_portrait.jpg" alt="Avatar"/>

                        <div class="name">Michael Richard Pence</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Secretary_Janet_Yellen_portrait.jpg/1200px-Secretary_Janet_Yellen_portrait.jpg" alt="Avatar"/>

                        <div class="name">Janet Yellen</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Secretary_of_Defense_Lloyd_Austin%2C_official_portrait%2C_2023.jpg/220px-Secretary_of_Defense_Lloyd_Austin%2C_official_portrait%2C_2023.jpg" alt="Avatar"/>

                        <div class="name">Lloyd James Austin III</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Gina_Raimondo.jpg/440px-Gina_Raimondo.jpg" alt="Avatar"/>

                        <div class="name">Gina Raimondo</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="radar-container">
                <div class="radar">
                    <div class="chart"></div>

                    <img class="avatar" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Katherine_Tai%2C_official_portrait.jpg/220px-Katherine_Tai%2C_official_portrait.jpg" alt="Avatar"/>

                        <div class="name">Katherine Tai</div>
                        <div class="chinese_name"></div>
                        <div class="type-wrapper">
                            <div class="type"></div>
                            </div>
                        <div class="party_name"></div>
                        <div class="party">政党</div>
                        <div class="influence_value"></div>
                        <div class="influence">综合影响力</div>
                        <script type="text/javascript" src="ra2.js"></script>

                        <div class="divider-line"></div>

                        <div class="datePicker">
                            <div class="dateButtons">
                                <button class="prevDate">&lt;</button>
                                <span class="date_value">2023-01</span>
                                <button class="nextDate">&gt;</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default RadarChartList;