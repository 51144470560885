export const stackData=[
    { date: "201702", action: 98, cause: 665,others:23 },
    { date: "201703", action: 93, cause: 397,others:108 },
    { date: "201704", action: 26, cause: 843,others:183 },
    { date: "201705", action: 28, cause: 280,others:200 },
    { date: "201706", action: 62, cause: 542,others:221 },
    { date: "201707", action: 48, cause: 439,others:262 },
    { date: "201708", action: 71, cause: 527,others:249 },
    { date: "201709", action: 25, cause: 337,others:224 },
    { date: "201710", action: 30, cause: 167,others:359 },
    { date: "201711", action: 30, cause: 350,others:313 },
    { date: "201712", action: 26, cause: 505,others:278 },

    { date: "201801", action: 103, cause: 753,others:208 },
    { date: "201802", action: 41, cause: 632,others:457 },
    { date: "201803", action: 120, cause: 5660,others:339 },
    { date: "201804", action: 134, cause: 3071,others:585 },
    { date: "201805", action: 199, cause: 357,others:483 },
    { date: "201806", action: 43, cause: 1475,others:229 },
    { date: "201807", action: 70, cause: 516,others:184 },
    { date: "201808", action: 36, cause: 340,others:207 },
    { date: "201809", action: 60, cause: 647,others:147 },
    { date: "201810", action: 123, cause: 429,others:387 },
    { date: "201811", action: 74, cause: 1035,others:198 },
    { date: "201812", action: 7, cause: 127,others:139 },

    { date: "201901", action: 34, cause: 474,others:107 },
    { date: "201902", action: 45, cause: 403,others:207 },
    { date: "201903", action: 33, cause: 352,others:268 },
    { date: "201904", action: 45, cause: 171,others:330 },
    { date: "201905", action: 68, cause: 641,others:176 },
    { date: "201906", action: 55, cause: 365,others: 229},
    { date: "201907", action: 23, cause: 994,others:192 },
    { date: "201908", action: 11, cause: 146,others:35 },
    { date: "201909", action: 18, cause: 276,others:185 },
    { date: "201910", action: 59, cause: 163,others:146 },
    { date: "201911", action: 27, cause: 187,others:268 },
    { date: "201912", action: 61, cause: 1189,others: 146},

    { date: "202001", action: 86, cause: 940,others:194 },
    { date: "202002", action: 63, cause: 190,others: 164},
    { date: "202003", action: 29, cause: 113,others: 142},
    { date: "202004", action:203, cause: 48,others:222},
    { date: "202005", action:313 , cause: 1509,others:364 },
    { date: "202006", action: 321, cause: 6823,others:175 },
    { date: "202007", action: 316, cause: 1619,others: 425},
    { date: "202008", action: 286, cause: 1932,others: 659},
    { date: "202009", action: 456, cause: 1496,others: 489},
    { date: "202010", action: 741, cause: 1881,others: 586},
    { date: "202011", action: 544, cause: 1058,others: 354},
    { date: "202012", action: 286, cause: 530,others: 378},

    { date: "202101", action: 391, cause: 902,others: 540},
    { date: "202102", action: 58, cause: 619,others: 455},
    { date: "202103", action: 134, cause: 1246,others: 649},
    { date: "202104", action: 174, cause: 1517,others: 491},
    { date: "202105", action: 233, cause: 1296,others:1054 },
    { date: "202106", action: 208, cause: 1097,others:580 },
    { date: "202107", action: 296, cause: 996,others: 600},
    { date: "202108", action: 864, cause: 871,others: 597},
    { date: "202109", action: 684, cause: 837,others: 874},
    { date: "202110", action: 690, cause:1440 ,others: 1411},
    { date: "202111", action: 518, cause: 656,others: 1559},
    { date: "202112", action: 327, cause: 527,others: 966},

    { date: "202201", action: 430, cause: 818,others: 1787},
    { date: "202202", action: 547, cause: 604,others: 1130},
    { date: "202203", action: 826, cause: 949,others:1756 },
    { date: "202204", action: 548, cause: 796,others: 1232},
    { date: "202205", action: 411, cause: 1994,others: 1094},
    { date: "202206", action: 453, cause: 2206,others: 933},
    { date: "202207", action: 294, cause: 1302,others: 925},
    { date: "202208", action: 287, cause: 775,others: 1225},
    { date: "202209", action: 287, cause: 907,others: 1430},
    { date: "202210", action: 1039, cause: 1336,others: 1627},
    { date: "202211", action: 345, cause: 887,others: 1526},
    { date: "202212", action: 268, cause: 646,others: 1830},

    { date: "202301", action: 527, cause: 1243,others: 1248},
    { date: "202302", action: 596, cause: 826,others: 1479},
    { date: "202303", action: 458, cause: 1247,others: 1687},
    { date: "202304", action: 465, cause: 1145,others: 817},
    { date: "202305", action: 455, cause: 954,others:806 },
    { date: "202306", action: 547, cause: 1472,others: 673},
    { date: "202307", action: 433, cause: 847,others: 739},
    { date: "202308", action: 418, cause: 781,others:750 }
  ]