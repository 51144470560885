import video from './video.mp4';
import React from 'react';
import { Player,BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';

const VideoComponent = () => {
  return (
    <div style={containerStyle}>
      <Player fluid={false} width="100%" height="100%" style={{background:'#ffffff'}} background={"#ffffff"}muted={true}>
        <source src={video} />
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh', // Adjust the height as needed for centering vertically.
};

export default VideoComponent;