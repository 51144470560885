import React, { useEffect, useState } from "react";
import { Select, Card, Popover } from "antd";
import * as d3 from "d3";

const MediaAttitudeRank = (props) => {
    const options = [
        {
            label: '近一周',
            value: '近一周'
        },
        {
            label: '近一月',
            value: '近一月'
        },
        {
            label: '近一年',
            value: '近一年'
        },
        {
            label: '2021-至今',
            value: '2021-至今'
        },
        {
            label: '2017-2021',
            value: '2017-2021'
        },
        {
            label: '2009-2017',
            value: '2009-2017'
        },
        {
            label: '1980-至今',
            value: '1980-至今'
        }]

    function readyShow(label) {
        var mode = 1
        var csv_name = { '近一周': 'data/data1/week11.3-11.9.csv', '近一月': 'data/data1/Oct_2023.csv', '近一年': 'data/data1/2023.csv', '2021-至今': 'data/data1/Biden_2021_present.csv', '2017-2021': 'data/data1/Trump_2017_2021.csv', '2009-2017': 'data/data1/Obama_2009_2017.csv', '1980-至今': 'data/data1/All.csv' }[label]
        Promise.all([
            // enter code to read files
            d3.csv(csv_name),
            label
        ]).then(
            function (data) {
                var mode = 1
                let data2023 = data[0];

                label = data[2]
                let all_data = { data: { data: data2023 }, label: label }
                //console.log(all_data)
                var text_op = []
                for (let i in all_data.data) {
                    text_op.push(i)

                    var label = all_data.label
                    var data = all_data.data.data
                    var le0num=0;var ge0num=0
                    for (let i in data) {
                        data[i].media = data[i].Publisher
                        data[i].url = `pic/media_logo/${data[i].media}.png`
                        data[i].influence = Math.floor(100*Number(data[i].Average_Sentiment))/100
                        if(data[i].influence>0){le0num+=1}else if(data[i].influence<0){ge0num+=1}
                    }
                    data = refineData(data)
                    processList(data)
                    //console.log(data,label)


                    const num_data = data.length

                    const numFontSize = 10;
                    const numFontFamily = "Source Han Sans";
                    const margin = { top: 0, right: 0, bottom: 0, left: 0 };


                    const offsetBetweenBarAndText = 2;
                    
                    
                    //getTextWidth(formatNumber(data[data.length - 1].influence), numFontSize, numFontFamily) + offsetBetweenBarAndText + ((mode == 1) ? 60 : 10)
                    
                    //getTextWidth(formatNumber(data[0].influence), numFontSize, numFontFamily) + ((mode == 1) ? 170 : 10)


                    const width = window.innerWidth * 0.18;
                    //const every_data_height = 60
                    const height = window.innerHeight * 0.6 - margin.top// ((mode == 1) ? 550 : 750) - margin.top;
                    //console.log(height)
                    //console.log('lege',le0num,ge0num)
                    
                    if(le0num==0 && width>=100){var max_left_label_width = 30
                        var max_right_lebel_width = width*0.5}
                    else if(ge0num==0 &&  width>=100){var max_left_label_width = width*0.5
                        var max_right_lebel_width = 30}
                    else{var max_left_label_width = width*0.3
                        var max_right_lebel_width = width*0.3}
                    

                    const every_data_height = height / num_data
                    const imageBarGap = 3
                    const imageLabelGap = 3
                    d3.select('svg').attr('height', height + margin.top + margin.bottom).attr('width', width + margin.left + margin.right)

                    // Create SVG container
                    d3.select('#media-attitude-rank-svg').remove()
                    const svg = d3.select("#media-attitude-rank-chart")
                        .append("svg").attr('class', 'svg-download').attr('id', 'media-attitude-rank-svg')
                        .attr("width", width + margin.left + margin.right)
                        .attr("height", height + margin.top + margin.bottom)
                        .append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


                    const xScale = d3.scaleLinear()
                        .domain([d3.min(data, d => d.influence), d3.max(data, d => d.influence)])
                        .range([max_left_label_width, width - max_right_lebel_width]);
                    //console.log('hengheng',width - max_right_lebel_width-max_left_label_width)
                    // Scale for Y-axis
                    const yScale = d3.scaleBand()
                        .domain(data.map(d => d.num))
                        .range([margin.top, height])

                    const yScaleBackground = d3.scaleBand()
                        .domain(data.map(d => d.num))
                        .range([0, height])

                    // Create bars
                    let svg_all = svg.selectAll(".bar").data(data)
                        .enter()


                    let all_svg = svg_all.append('svg')


                    all_svg.append('rect')
                        .attr("class", "bk_bar")
                        .attr("x", d => 0)
                        .attr("y", d => yScale(d.num))
                        .attr("width", d => width)
                        .attr("height", yScale.bandwidth())
                        .attr('num', d => d.num)
                        .style("fill", d => (d.num % 2 == 0) ? "#FAFAFA" : "white")


                    all_svg.append('rect')
                        .attr("class", "bar")
                        .attr("x", d => ((d.influence>=0)?xScale(0):xScale(d.influence)))
                        .attr("y", d => yScale(d.num) + yScale.bandwidth() * 0.2)
                        .attr("width", d => Math.abs(xScale(0) - xScale(Math.abs((d.influence)))))
                        .attr("height", yScale.bandwidth() * 0.6)
                        .attr('num', d => d.num)
                        .style("fill", d => d.influence > 0 ? "#FDB462" : "#A1A1A1")



                    all_svg.append('text')
                        .text(d => formatNumber(d.influence))
                        .attr('y', d => yScale(d.num) + yScale.bandwidth() / 2 + numFontSize / 2 - 2)
                        .style('font-size', numFontSize).style('font-family', numFontFamily)
                        .attr('x', d => {
                            const textWidth = getTextWidth(formatNumber(d.influence), numFontSize, numFontFamily);

                            // 调整文字和柱形图之间的间距

                            if (d.influence < 0) {
                                return xScale((d.influence)) - textWidth - offsetBetweenBarAndText;
                            } else {
                                return xScale((d.influence)) + offsetBetweenBarAndText;
                            }
                        });

                    let svg_image = all_svg.append('svg').attr('class', 'media-attitude-rank-svg-img');

                    let svg_img_img = svg_image.append('image');
                    svg_img_img.attr('href', d => d.url).attr('id', d => `media-attitude-rank-svg-img-${d.num}`).style('visibility','hidden')
                        .attr('height', yScale.bandwidth() * 0.5).each(function (d) {
                            this.onload = function () {
                                var node = d3.select(`#media-attitude-rank-svg-img-${d.num}`);
                                if(!node.node()){return null}
                                let s1 = xScale(0) - Number(node.node().getBoundingClientRect().width);
                                var s2 = Number(node.node().getBoundingClientRect().width)
                                var heightSize
                                if (d.influence>=0){
                                    if(s2>xScale(0)){
                                        heightSize= xScale(0)/s2
                                        node.attr('x', 0);
                                        node.style('height',yScale.bandwidth() * 0.5*heightSize)
                                    }
                                    else{
                                        heightSize=1
                                        node.attr('x', d.influence >= 0 ? s1*heightSize : node.attr('x'));
                                //node.style('height',)
                                    }

                                }
                                else{
                                    if(s2>width -xScale(0)){
                                        heightSize= (width -xScale(0))/s2 
                                        node.style('height',yScale.bandwidth() * 0.4*heightSize)
                                    }
                                    else{
                                        heightSize=1
                                    }
                                }
                                console.log('heightSize',heightSize,d.media)
                                //console.log(xScale(0), Number(node.node().getBoundingClientRect().width), s1);
                                node.style('visibility',null)
                                
                            }
                        })
                        .attr('y', d => yScale(d.num) + yScale.bandwidth() * 0.25)
                        .attr('x', d => (d.influence >= 0) ? 0 : imageBarGap + xScale(0))
                }
            })

        function refineData(data) {
            var newdata = []
            for (let i in data) {
                if (i != 'columns') { newdata.push(data[i]) }

            }
            data = newdata
            for (var i = 0; i < data.length; i++) {
                data[i].influence = (mode == 1) ? Number(data[i].influence) : 2 * (Number(data[i].influence) - 0.5);
            }
            data = data.sort(function (a, b) { return b.influence - a.influence; })
            for (var i = 0; i < data.length; i++) {
                data[i].num = i;
            }
            return data

        }

        function convertToBase64(imgUrl) {
            return new Promise(function (resolve, reject) {
                var image = new Image();
                image.src = imgUrl;
                image.crossOrigin = "Anonymous";
                image.onload = function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;

                    var context = canvas.getContext("2d");
                    context.fillStyle = "#ffffff"; // 设置为白色
                    context.fillRect(0, 0, canvas.width, canvas.height); // 填充整个 Canvas

                    context.drawImage(image, 0, 0);

                    var base64 = canvas.toDataURL("image/jpeg");
                    resolve(base64);
                };
                image.onerror = function () {
                    reject(new Error("Failed to load image"));
                };

            });
        }


        async function f(imgUrl) {
            var externalVariable;

            try {
                externalVariable = await convertToBase64(imgUrl);

                return externalVariable;
            } catch (error) {
                console.error(error);
            }
        }


        function getTextWidth(text, fontSize, fontFamily) {
            const container = document.createElement("span");
            container.style.fontSize = fontSize + "px";
            container.style.fontFamily = fontFamily;
            container.style.visibility = "hidden";
            container.innerText = text;
            document.body.appendChild(container);
            const width = container.offsetWidth;
            document.body.removeChild(container);
            return width;
        }
        function formatNumber(number) {
            let formattedNumber = '';
            if (number >= 0) {
                formattedNumber = '+' + number.toLocaleString();
            } else {
                formattedNumber = '-' + Math.abs(number).toLocaleString();
            }
            return formattedNumber;
        }

        async function processList(list) {
            try {
                for (let index in list) {
                    const base64 = await convertToBase64(list[index].url);
                    list[index].url = base64
                    //console.log(list[index])

                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        readyShow("近一周")
    }, [])

    return (
        <Card title={
            <Popover placement="bottom" content={props.description}>
                <div style={{ width: '100%', cursor: 'pointer' }}>
                    {props.title}
                </div>
            </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue="近一周"
                    onChange={readyShow}
                    options={options}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
        >
            <div style={{ paddingLeft: window.innerWidth * 0.01 }}>
                <div id="media-attitude-rank-chart" style={{ //overflow: "auto", 
                maxHeight: window.innerHeight * 0.7, }}></div>
            </div>
        </Card>
    );
}

export default MediaAttitudeRank