import React from 'react';
import "./style.css"
const linkPage = "https://www.midu.com/mxd-pro/spreadCharts"

function Case3() {
    const clickNewPage = () => {
        window.open(linkPage, '__blank');
    }

    return (
        <div className='case-page'>
            <a href={linkPage} target="_blank" rel="noopener noreferrer">
                <img src={"./case/11.jpg"} style={{ width: "100%" }} alt="Case3" />
            </a>
            <div className='link-box'>
                <div className='link-box-btn' onClick={clickNewPage}>
                    {linkPage}
                </div>
            </div>
        </div>
    );
}

export default Case3;
