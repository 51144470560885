import React from 'react';
import { Row } from 'antd';
import ChartCard from '../../component/ChartCart';
import TopicsOverview from '../../charts/TopicsOverview';
import Rankflow2Chart from '../../charts/Rankflow2';
import StackChart from '../../charts/StackChart';
import TopicsLineChart from '../../charts/TopicsLineChart';

function Topic() {
    return (
        <div id='topic-page'>
            <Row class='overview-row' style={{ height: '55vh', margin: '10px' }}>
                <ChartCard title='1. 议题库数据结构与量级概况'>
                    <TopicsOverview/>
                </ChartCard>
            </Row>
            <Row class='progress-row' style={{ height: '75vh', margin: '10px' }}>
                <ChartCard title='2. 议题社群“主流化”程度算法-右翼民粹'>
                    <TopicsLineChart/>
                </ChartCard>
            </Row>
            <Row class='change-row1' style={{ height: '90vh', margin: '10px' }}>
                <ChartCard title='3. 议题社群群内“激进化”程度算法-右翼民粹'>
                    <StackChart/>
                </ChartCard>
            </Row>
            <Row class='change-row2' style={{ height: '65vh', margin: '10px' }}>
                <ChartCard title='4. 美右翼民粹议题群内“激进化”演变趋势'>
                    <Rankflow2Chart></Rankflow2Chart>
                </ChartCard>
            </Row>
        </div>
    );
}

export default Topic;