import React, { useEffect, useState } from "react";
import { Select, Card, Popover } from "antd";
import * as d3 from "d3";
import "./style.css"

const MediaAuthor = (props) => {
    const items = [
        {
            "label": "1980-至今",
            "value": "All"
        },
        {
            "label": "2009-2017",
            "value": "2009-2017"
        },
        {
            "label": "2017-2021",
            "value": "2017-2021"
        },
        {
            "label": "2021-至今",
            "value": "2021"
        },
        {
            "label": "近一年",
            "value": "Year"
        },
        {
            "label": "近一月",
            "value": "Month"
        },
        {
            "label": "近一周",
            "value": "Week"
        },
    ]
    const dataItems = [
        {
            label: "纽约时报",
            value: "The New York Times"
        },
        {
            label: "华尔街日报",
            value: "The Wall Street Journal"
        },
        {
            label: "芝加哥论坛报",
            value: "Chicago Tribune"
        },
        {
            label: "洛杉矶时报",
            value: "Los Angeles Times"
        },
        {
            label: "华盛顿邮报",
            value: "The Washington Post"
        },
    ]

    const [data, setData] = useState('The New York Times');
    const [timeRange, setTimeRange] = useState('All');

    const margin = { top: 20, right: 20, bottom: 40, left: 10 };
    const width = window.innerWidth * 0.6 - margin.left - margin.right;
    const height = window.innerHeight * 0.6 - margin.top - margin.bottom;

    // Create a function to remove the previous scatter plot elements
    function removePreviousPlot() {
        d3.select("#media-author-chart-container").selectAll("*").remove();
    }

    function updateScatterPlot(selectedData, selectedTime) {
        removePreviousPlot();
        // Create an SVG element for the scatter plot.
        const svg = d3.select("#media-author-chart-container")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        // Create a tooltip container div
        const tooltip = d3.select("#media-author-tooltip")

        const dataPath = `./${selectedData}/${selectedTime}.csv`;

        d3.csv(dataPath).then(function (data) {
            // Parse attitude and significance as numbers.
            data.forEach(function (d) {
                d.attitude = +d.attitude;
                d.significance = +d.significance;
            });

            // Add background colors to the four quadrants.
            svg.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#FBF8EA");
            
            svg.append("rect")
                .attr("x", 0)
                .attr("y", height/2)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#EDF0FE");
            
            svg.append("rect")
                .attr("x", width/2)
                .attr("y", 0)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#FCEFEE");
            
            svg.append("rect")
                .attr("x", width/2)
                .attr("y", height/2)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#ECF7F1");
        
            svg.append("text")
                .attr("x", 5)
                .attr("y", height/2 - 10)
                .style("text-anchor", "start")
                .text("斗争域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            svg.append("text")
                .attr("x", width - margin.right + 15)
                .attr("y", 0.5*height - 10)
                .style("text-anchor", "end")
                .text("团结域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            svg.append("text")
                .attr("x", 5)
                .attr("y", height - 10)
                .style("text-anchor", "start")
                .text("风险域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            svg.append("text")
                .attr("x", width - margin.right + 15)
                .attr("y", height - 10)
                .style("text-anchor", "end")
                .text("支持域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);

            // Create scales for x and y axes.
            const yScale = d3.scaleLinear()
                .domain([Math.floor(d3.extent(data, d => d.significance)[0] / 10) * 10, Math.ceil(d3.extent(data, d => d.significance)[1] / 10) * 10])
                .range([height,0]);

            const xScale = d3.scaleLinear()
                .domain([-1, 1])
                .range([0,width]);

            // Create x and y axes.
            // const xAxis = d3.axisBottom(xScale).ticks(20);
            const xAxis = d3.axisTop(xScale).ticks(20);
            const yAxis = d3.axisLeft(yScale);

            // Append axes to the SVG.
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0, ${height / 2})`)
                .call(xAxis)

            // Add grid lines
            svg.selectAll(".x-axis")
                .append("g")
                .attr("class", "grid-lines")
                .selectAll("line")
                .data(xScale.ticks())
                .enter()
                .append("line")
                .attr("class", "grid-line")
                .attr("x1", d => xScale(d))
                .attr("x2", d => xScale(d))
                .attr("y1", -height / 2)
                .attr("y2", height / 2)
                .style("stroke", "#D3D6DF")
                .style("stroke-dasharray", "2,2");

            svg.append("text")
                .attr("class", "x-label")
                .attr("x", width - 20)
                .attr("y", height / 2 + 20)
                .style("text-anchor", "middle")
                .text("对华态度")
                .style("font-size", "12px");


            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${width / 2}, 0)`)
                .call(yAxis);

            svg.selectAll(".y-axis")
                .append("g")
                .attr("class", "grid-lines")
                .selectAll("line")
                .data(yScale.ticks())
                .enter()
                .append("line")
                .attr("class", "grid-line")
                .attr("x1", -width / 2)
                .attr("x2", width/2)
                .attr("y1", d => yScale(d))
                .attr("y2", d => yScale(d))
                .style("stroke", "#D3D6DF")
                .style("stroke-dasharray", "2,2");

            svg.append("text")
                .attr("class", "y-label")
                .attr("x", width / 2)
                .attr("y", -10)
                .style("text-anchor", "middle")
                .text("影响力")
                .style("font-size", "12px");


            // Create circles for the scatter plot.
            svg.selectAll("circle")
                .data(data)
                .enter()
                .append("circle")
                .attr("cx", d => xScale(d.attitude))
                .attr("cy", d => yScale(d.significance))
                .attr("r", 5)
                .style("stroke", "white")
                .style("stroke-width", 0.2)
                .style("fill", function (d) {
                    if (selectedData === 'The New York Times') return '#D41A24'
                    if (selectedData === 'Chicago Tribune') return '#60A2CD'
                    if (selectedData === 'The Wall Street Journal') return '#52B560'
                    if (selectedData === 'The Washington Post') return '#80C3B2'
                    if (selectedData === 'Los Angeles Times') return '#D9B52C'
                })
                .on("mouseover", function (event, d) {
                    // Show the tooltip when hovering over a circle
                    const [mouseX, mouseY] = d3.pointer(event);
                    // Position the tooltip next to the mouse pointer and near the data point
                    // this.getBoundingClientRect();
                    const tooltipWidth = parseFloat(tooltip.style("width"));
                    const tooltipHeight = parseFloat(tooltip.style("height"));
                    const myData = d3.select(this).data()[0]

                    // Set tooltip position initially at the mouse position.
                    let tooltipX = mouseX + 50; // Adjust the horizontal offset
                    let tooltipY = mouseY + 50; // Adjust the vertical offset

                    // Check if the tooltip is too close to the edge.
                    if (tooltipX + tooltipWidth > width) {
                        tooltipX = width - tooltipWidth;
                    }
                    if (tooltipX - tooltipWidth < 0) {
                        tooltipX = margin.left;
                    }
                    if (tooltipY + tooltipHeight > height) {
                        tooltipY = height - tooltipHeight;
                    }
                    if (tooltipY - tooltipHeight < 0) {
                        tooltipY = margin.top;
                    }

                    tooltip.transition()
                        .duration(200)
                        .style("opacity", 0.9);

                    tooltip.html(`作者名称: ${myData.name}<br>影响力: ${myData.significance}<br>对华态度: ${myData.attitude}`)
                        .style("left", tooltipX + "px")
                        .style("top", tooltipY + "px") // Adjust the top position
                        .style("font-size", "12px");

                    // Highlight the data point
                    d3.select(this)
                        .attr("r", 8) // Increase the radius
                })
                .on("mouseout", function (d) {
                    // Hide the tooltip when moving the mouse away from a circle
                    tooltip.transition()
                        .duration(500)
                        .style("opacity", 0);

                    // Restore the data point's appearance
                    d3.select(this)
                        .attr("r", 5)
                });
        });
    }

    const handleDataChange = (value) => {
        setData(value);
    }

    const handleTimeChange = (value) => {
        setTimeRange(value);
    }

    useEffect(() => {
        updateScatterPlot(data, timeRange)
    }, [data, timeRange])

    return (
        <Card title={
            <Popover placement="bottom" content={props.description}>
                <div style={{ width: '100%', cursor: 'pointer' }}>
                    {props.title}
                </div>
            </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue={'All'}
                    options={items}
                    onChange={handleTimeChange}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
        >
            <Select
                size='large'
                defaultValue={'The New York Times'}
                options={dataItems}
                onChange={handleDataChange}
                style={{marginBottom: "15px"}}
            >
            </Select>
            <div id="media-author-tooltip" style={{opacity: 0}}></div>
            <div id="media-author-chart-container"></div>
        </Card>
    );
}

export default MediaAuthor