import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./style.css"

const MediaOverview = () => {
    const containerRef = useRef();
    useEffect(() => {
        const mediaList = [
            {
                nameChinese: "纽约时报",
                nameEnglish: "New York Times",
                logo: "medialogo/NewYork_Times.png",
                timeRange: "1980-",
                updateRate: "Daily",
                chinaReports: "138,788",
                usElectionReports: "690,354",
                collectionRate: "110,000",
            },
            {
                nameChinese: "芝加哥论坛报",
                nameEnglish: "Chicago Tribune",
                logo: "medialogo/Chicago_Tribune.png",
                timeRange: "1985-",
                updateRate: "Daily",
                chinaReports: "67,843",
                usElectionReports: "414,142",
                collectionRate: "120,000",
            },
            {
                nameChinese: "洛杉矶时报",
                nameEnglish: "Los Angeles Times",
                logo: "medialogo/LosAngeles_Times.jpg",
                timeRange: "1985-",
                updateRate: "Daily",
                chinaReports: "85,019",
                usElectionReports: "502,314",
                collectionRate: "110,000",
            },
            {
                nameChinese: "华尔街日报",
                nameEnglish: "The Wall Street Journal",
                logo: "medialogo/The_Wall_Street_Journal.png",
                timeRange: "1984-",
                updateRate: "Daily",
                chinaReports: "246,105",
                usElectionReports: "501,584",
                collectionRate: "120,000",
            },
            {
                nameChinese: "华盛顿邮报",
                nameEnglish: "The Washington Post",
                logo: "medialogo/The_Washington_Post.jpg",
                timeRange: "1987-",
                updateRate: "Daily",
                chinaReports: "83,087",
                usElectionReports: "500,918",
                collectionRate: "120,000",
            },
            {
                nameChinese: "亚利桑那共和报",
                nameEnglish: "Arizona Republic",
                logo: "medialogo/Anzona_Republic.png",
                timeRange: "1999-",
                updateRate: "Daily",
                chinaReports: "15,536",
                usElectionReports: "143,438",
                collectionRate: "100,000",
            },
            {
                nameChinese: "明星论坛报",
                nameEnglish: "Star Tribune",
                logo: "medialogo/Star_Tribune.png",
                timeRange: "1986-",
                updateRate: "Daily",
                chinaReports: "27,560",
                usElectionReports: "198,222",
                collectionRate: "100,000",
            },
            {
                nameChinese: "匹兹堡邮报",
                nameEnglish: "Pittsburgh Post-Gazette",
                logo: "medialogo/Pittsburgh_Post-Gazette.png",
                timeRange: "1993-",
                updateRate: "Daily",
                chinaReports: "35,225",
                usElectionReports: "209,517",
                collectionRate: "100,000",
            },
            {
                nameChinese: "亚特兰大日报",
                nameEnglish: "The Atlanta Journal-Constitution",
                logo: "medialogo/The_Atlanta_Journal-Constitution.png",
                timeRange: "2001-",
                updateRate: "Daily",
                chinaReports: "12,541",
                usElectionReports: "102,531",
                collectionRate: "100,000",
            },
            {
                nameChinese: "今日美国",
                nameEnglish: "USA Today",
                logo: "medialogo/USA_Today.png",
                timeRange: "1997-",
                updateRate: "Daily",
                chinaReports: "18,314",
                usElectionReports: "102,481",
                collectionRate: "100,000",
            },
            {
                nameChinese: "坦帕湾时报",
                nameEnglish: "Tampa Bay Times",
                logo: "medialogo/Tampa_Bay_Times.png",
                timeRange: "1986-",
                updateRate: "Daily",
                chinaReports: "25,188",
                usElectionReports: "258,290",
                collectionRate: "100,000",
            },
        ];

        const container = d3.select("#media-overview-container");

        const mediaItems = container.selectAll(".media-item")
            .data(mediaList)
            .enter()
            .append("div")
            .attr("class", "media-item");

        const line_one = mediaItems.append("div")
            .style("display", "flex")
            .style("justify-content", "space-around")
            .style("height", "50%")
            .append("div")
            .attr("class", "line_one");
        const line_two = mediaItems.append("div")
            .attr("class", "line_two");
        const line_three = mediaItems.append("div")
            .attr("class", "line_three");

        line_one.append("div")
            .attr("class", "media-logo")
            .append("img")
            .attr("src", d => d.logo);

        const mediaName = line_one.append("div")
            .attr("class", "media-name");

        mediaName.append("p")
            .attr("class", "chinese")
            .text(d => d.nameChinese);

        mediaName.append("p")
            .attr("class", "english")
            .text(d => d.nameEnglish);

        line_one.append("div")
            .attr("class", "media-time")
            .style("width", (containerRef.current.offsetWidth * 0.3 - 205) * 0.45 + "px")
            .html(d => `<div class="media-time-up">${d.timeRange}</div><div class="media-time-low">时间范围</div>`);

        line_one.append("div")
            .attr("class", "media-rate")
            .style("width", (containerRef.current.offsetWidth * 0.3 - 205) * 0.45 + "px")
            .html(d => `<div class="media-time-up">${d.updateRate}</div><div class="media-time-low">更新频率</div>`);


        line_two.append("div")
            // .attr("class", "stat-item")
            .html(d => `<p class="value">${d.chinaReports}</p><p class="unit">涉华报道（篇）</p>`);

        line_two.append("div")
            // .attr("class", "stat-item")
            .html(d => `<p class="value">${d.usElectionReports}</p><p class="unit">美国大选议题（篇）</p>`);

        line_two.append("div")
            // .attr("class", "stat-item")
            .html(d => `<p class="value">${d.collectionRate}</p><p class="unit">采集频率（篇/天）</p>`);

        line_three.append("p")
            .attr("class", "number")
            .text((d, i) => `${(i + 1).toString().padStart(2, '0')}/${mediaList.length.toString().padStart(2, '0')}`);

    }, [])
    return (
        <div id="media-overview-container" ref={containerRef} style={{ overflow: "auto", maxHeight: window.innerHeight * 0.8 }}></div>
    );
}

export default MediaOverview;