import React, { useEffect } from "react";
import * as d3 from "d3";

function LineChart(data, {
    x = ([x]) => x, // given d in data, returns the (temporal) x-value
    y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
    z = () => 1, // given d in data, returns the (categorical) z-value
    title, // given d in data, returns the title text
    defined, // for gaps in data
    curve = d3.curveLinear, // method of interpolation between points
    marginTop = 20, // top margin, in pixels
    marginRight = 80, // right margin, in pixels
    marginBottom = 30, // bottom margin, in pixels
    marginLeft = 80, // left margin, in pixels
    width = 0,
    height = 0,
    // width = 640, // outer width, in pixels
    // height = 400, // outer height, in pixels
    xType = d3.scaleUtc, // type of x-scale
    xDomain, // [xmin, xmax]
    xRange = [marginLeft, width - marginRight], // [left, right]
    yType = d3.scaleLinear, // type of y-scale
    yDomain, // [ymin, ymax]
    yRange = [height - marginBottom, marginTop], // [bottom, top]
    yFormat, // a format specifier string for the y-axis
    yLabel, // a label for the y-axis
    xLabel,
    zDomain, // array of z-values
    color = "currentColor", // stroke color of line, as a constant or a function of *z*
    strokeLinecap, // stroke line cap of line
    strokeLinejoin, // stroke line join of line
    strokeWidth = 1.5, // stroke width of line
    strokeOpacity, // stroke opacity of line
    mixBlendMode = "multiply", // blend mode of lines
    voronoi,// show a Voronoi overlay? (for debugging)
} = {}) {

    // console.log("width ",marginBottom,width,height)
    // Compute values.
    const divisionColors = {
        "American Enterprise Institute": "#D9B52C",
        "Brookings Institution": "#60A2CD",
    };

    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const Z = d3.map(data, z);
    const O = d3.map(data, d => d);
    if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
    const D = d3.map(data, defined);

    // Compute default domains, and unique the z-domain.
    if (xDomain === undefined) xDomain = d3.extent(X);
    if (yDomain === undefined) yDomain = d3.extent(Y);;
    if (zDomain === undefined) zDomain = Z;
    zDomain = new d3.InternSet(zDomain);

    // Omit any data not present in the z-domain.
    const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

    const xScale = xType()
        .domain(xDomain)
        .range(xRange)
        .clamp(true);

    const yScale = d3.scaleLinear()
        .domain(yDomain)
        .range([height - marginBottom, marginTop])
        .nice();

    const timeFormat = d3.timeFormat('%Y-%m');
    const xAxis = d3.axisBottom(xScale)
        .ticks(width / 180)
        .tickFormat(timeFormat);
    const yAxis = d3.axisLeft(yScale).ticks(height / 60, yFormat);

    // Compute titles.
    const T = title === undefined ? Z : title === null ? null : d3.map(data, title);

    // Construct a line generator.
    const line = d3.line()
        .defined(i => D[i])
        .curve(curve)
        .x(i => xScale(X[i]))
        .y(i => yScale(Y[i]));

    const svg = d3.create("svg")
        .attr("width", width)
        .attr("height", height*1.1)
        .attr("viewBox", [0, -height*0.05, width, height*1.1])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        .style("-webkit-tap-highlight-color", "transparent")

    if (voronoi) svg.append("path")
        .attr("fill", "none")
        .attr("stroke", "#ccc")
        .attr("d", d3.Delaunay
            .from(I, i => xScale(X[i]), i => yScale(Y[i]))
            .voronoi([0, 0, width, height])
            .render());

    const path = svg.append("g")
        .attr("fill", "none")
        .attr("stroke", typeof color === "string" ? color : null)
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-opacity", strokeOpacity)
        .selectAll("path")
        .data(d3.group(I, i => Z[i]))
        .join("path")
        .style("mix-blend-mode", mixBlendMode)
        .attr("stroke", ([z]) => divisionColors[z])
        .attr("d", ([, I]) => line(I));
    const dotGroup = svg.append("g");

    const dot = dotGroup
        .selectAll("g")
        .data(I)
        .enter()
        .append("g")
        .attr("transform", i => `translate(${xScale(X[i])},${yScale(Y[i])})`);

    dot.append("circle")
        .attr("r", 4)
        .attr("fill", i => divisionColors[Z[i]])
        .attr("class", "data-point");

    dot.append("text")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("text-anchor", "middle")
        .attr("y", -8);
    const zoomGroup2 = svg.append("g")
        .attr("class", "zoom-group2");

    const zoomCircle2 = zoomGroup2.append("circle")
        .attr("r", 8)
        .style("fill", "#60A2CD");

    const auxiliaryScaleContainer = svg.append("g")
        .attr("class", "auxiliary-scale")
        .attr("stroke", "#ccc");

    const numberOfAuxiliaryTicks = 10;

    const auxiliaryTickValues = xScale.ticks(numberOfAuxiliaryTicks);

    auxiliaryScaleContainer.selectAll(".auxiliary-tick")
        .data(auxiliaryTickValues)
        .enter().append("line")
        .attr("class", "auxiliary-tick")
        .attr("x1", d => xScale(d)) 
        .attr("x2", d => xScale(d)) 
        .attr("y1", marginTop) // 刻度线的起始位置，根据需要调整
        .attr("y2", height - marginBottom); // 刻度线的结束位置，根据需要调整

    svg.append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height - 30})`)//迁移后可能还需要微调
        .call(xAxis)
        .call(g => g.selectAll(".tick text")
            .style("font-size", "16px"))
        .select(".domain")
        .style("stroke", "blue")
        ;

    svg.append("g")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis)
        .call(g => g.selectAll(".tick text")
            .style("font-size", "16px"))
        .select(".domain")
        .style("stroke", "blue")
        .call(g => g.select(".domain").remove())
        .call(voronoi ? () => { } : g => g.selectAll(".tick line").clone()
            .attr("x2", width - marginLeft - marginRight)
            .attr("stroke-opacity", 0.1))
        .call(g => g.append("text")
            .attr("x", marginLeft)
            .attr("y", 10)
            .attr("font-size", "16px")
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(yLabel));

    svg.append("text")
        .attr("x", marginLeft +20)
        .attr("y", 0)
        .attr("text-anchor", "end")
        .attr("font-size", "16px")
        .text(yLabel);

    //2022-03-01,Brookings Institution,7.911693051,
    zoomGroup2.attr("transform", `translate(${xScale(new Date("2022-03-01"))},${yScale(7.911693051)})`);

    let isInfoBoxVisible2 = false;

    const infoBox2 = svg.append("foreignObject")
        .attr("class", "info-box2")
        .attr("width", width*0.48)
        .attr("height", 300)
        .attr("transform", `translate(${xScale(new Date("2022-03-01"))+20},${yScale(7.911693051)-height/8})`)
        .style("font-size", "14px") // 设置字体大小
        .style("background-color", "rgba(255, 255, 255, 0.1)")
        .style("display", "none");

    zoomCircle2.on("mouseenter", () => {
        if (!isInfoBoxVisible2) {

        const infoText2 = `
          <div class="info-content">
            <strong>2022年3月18日</strong>
            <p>中美元首视频通话。两国元首就中美关系和乌克兰局势等共同关心的问题交换意见。布鲁金斯学会和企业研究所的影响力同时出现上扬的趋势。</p>
          </div>
        `;
            infoBox2.html(infoText2);
            d3.select(".info-content") 
                .style("background-color", "white") 
                .style("border-radius", "12px") // 添加圆角 
                .style("border", "2px solid #E0E0E0") 
                .style("padding", "20px") 
                .style("box-shadow", " 0px 0px 10px #E0E0E0"); // 添加阴影 
            infoBox2.style("display", "block"); 
            isInfoBoxVisible2 = true; 
        } 
    });

    zoomCircle2.on("mouseleave", () => {
        infoBox2.style("display", "none");
        isInfoBoxVisible2 = false;
    });


    const zoomGroup = svg.append("g")
        .attr("class", "zoom-group");


    const zoomCircle = zoomGroup.append("circle")
        .attr("r", 8)
        .style("fill", "#D9B52C");
// 2021-04-01,American Enterprise Institute,30.58
    zoomGroup.attr("transform", `translate(${xScale(new Date("2021-04-01"))},${yScale(30.58)})`);

    let isInfoBoxVisible = false;

    const infoBox = svg.append("foreignObject")
        .attr("class", "info-box")
        .attr("width", width*0.6)
        .attr("height", 340)
        .attr("transform", `translate(${xScale(new Date("2021-04-01"))},${yScale(30.58)+20})`)
        .style("font-size", "14px") // 设置字体大小
        .style("background-color", "rgba(255, 255, 255, 0.1)")
        .style("display", "none");

    zoomCircle.on("mouseenter", () => {
        if (!isInfoBoxVisible) {
            const infoText = `
      <div class="info-content2">
          <strong>2021年3月18日</strong>
          <p>中美高层战略对话在美举行，此次对话是拜登政府上台后的中美首次高层对话，同年4月美参院委员会通过《2021年战略竞争法案》。企业研究所举办和中国有关的研讨会，智库专家也撰文分析中美关系的新变化，影响力达到一个高点。</p>
        </div>
      `;
            infoBox.html(infoText);

            d3.select(".info-content2")
                .style("background-color", "white")
                .style("border-radius", "12px") // 添加圆角
                .style("border", "2px solid #E0E0E0")
                .style("padding", "20px")
                .style("box-shadow", " 0px 0px 10px #E0E0E0"); // 添加阴影

            infoBox.style("display", "block");

            isInfoBoxVisible = true;
        }
    });

    zoomCircle.on("mouseleave", () => {

        infoBox.style("display", "none");
        isInfoBoxVisible = false;
    });

    var legendData = [
        { color: "#D9B52C", label: "美国企业研究所" },
        { color: "#60A2CD", label: "布鲁金斯学会" },
    ];

    var legendGroup = svg.append("g")
        .attr("class", "legend")
        // .attr("transform", "translate(100, 485)");
        

    var legendItems = legendGroup.selectAll(".legend-item")
        .data(legendData)
        .enter().append("g")
        .attr("class", "legend-item")
        .attr("transform", function (d, i) {
            return "translate(" + (width*0.35+i * width*0.18)  + "," + height*1.02 + ")";
        });
        

    legendItems.append("circle")
        .attr("class", "legend-color")
        .attr("r", 8)
        .style("fill", function (d) {
            return d.color;
        });

    legendItems.append("text")
        .attr("class", "legend-label")
        .attr("x", 22)
        .attr("y", 6)
        .attr("font-size", "16px")
        .text(function (d) {
            return d.label;
        });

    return Object.assign(svg.node(), { value: null });
}

const ThinkInfluenceTrend = () => {
    useEffect(() => {
        d3.csv('think/linechart-data.csv').then(function (data) {
            // 处理数据...
            data.forEach(function (d) {
                d.date = new Date(d.date);
                d.emotion = +d.emotion;
            });

            const startDate = new Date('2021-01-01');
            const endDate = new Date('2023-07-01');

            const filteredData = data.filter(function (d) {
                return d.date >= startDate && d.date <= endDate;
            });

            // 调用 LineChart 函数
            let width=document.getElementById("think-influence-trend").offsetWidth;
            let height=document.getElementById("think-influence-trend").offsetHeight; 
            // console.log("width ",width,height)
            let chart = LineChart(filteredData, {
                x: d => d.date,
                y: d => d.emotion,
                z: d => d.division,
                xLabel: "时间",
                yLabel: "影响力",
                width: width,
                height: height*0.80,
                color: "steelblue",
                xDomain: [startDate, endDate]
            });

            // // 使用dx属性来微调文本位置
            // chart.svg.select('.y-axis-label')
            //      .attr('dx', '-20'); // 负值表示向左移动

            // 将图表添加到容器
            const container = document.getElementById("think-influence-trend");
            container.appendChild(chart);
        }).catch(function (error) {
            console.error('Error loading CSV:', error);
        });
    }, []);

    return (
        <div id="think-influence-trend" style={{width:"100%", height:"100%"}} ></div>
    );
}

export default ThinkInfluenceTrend;