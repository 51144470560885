import React, { useEffect, useState } from "react";
import { Carousel, Popover } from 'antd';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./style.css"

const CreamNetwork = () => {
    const images = [
        { url: 'cream/small.png', title: '（微观数据）全美舆论场中最核心的精英群体，显示了主导舆论场的关键精英。' },
        { url: 'cream/middle.png', title: '（中观数据）该模块使用了精英决策子群分析算法。该算法基于词向量距离计算精英言论在全美媒体报道中的接近程度，蓝色线条反映舆论场中不同精英群体之间的距离，该算法用以识别舆论场中“抱团发声”的精英群体。' },
        { url: 'cream/large.png', title: '（宏观数据）该模块使用了精英社会网络算法。该算法分析精英在全美媒体报道中的热度与共现频率，不同颜色节点代表不同类型的精英，节点越接近中心，反映所代表的精英在全美舆论场中影响力越大。' },
    ];
    const [visible, setVisible] = useState('none');
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const [content, setContent] = useState(null);

    const handleMouseMove = (e, index) => {
        const { clientX, clientY } = e;
        setPosition({ left: clientX, top: clientY });
        setVisible('block');
        setContent(images[index].title);
    };

    const handleMouseLeave = () => {
        setVisible('none');
    };

    return (
        <div>
            <Carousel arrows prevArrow={<img src="preArrow.png" />} nextArrow={<img src="nextArrow.png" />} style={{ marginTop: window.innerHeight * 0.05 }}>
                {
                    images.map((image, index) => (
                        <div onMouseMove={(e) => { handleMouseMove(e, index) }} onMouseLeave={handleMouseLeave}>
                            <img src={image.url} style={{ maxHeight: "100%", maxWidth: "100%", height: "auto" }}></img>
                        </div>
                    ))
                }
            </Carousel>
            <div className="network-card" style={{display: visible, left: position.left - 20 + "px", top: position.top - 80 + "px"}}>
                {content}
            </div>
        </div>
    );
}

export default CreamNetwork;