import React from 'react';
import { Row, Col } from 'antd';
import {DownOutlined} from '@ant-design/icons'
import ChartCard from '../../component/ChartCart';
import RankflowChart from '../../charts/Rankflow';
import CreamNetwork from '../../charts/CreamNetwork';
import EliteOverview from '../../charts/EliteOverview';
import RadarChartList from '../../charts/RadarChart';
import EliteScatterPlot from '../../charts/EliteScatterPlot';
import AttitudeRank from '../../charts/AttitudeRank';

function Cream() {
    return (
        <div>
            <Row gutter={10} style={{ height: '55vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='1. 美国精英人物库数据结构与量级概况'>
                    <EliteOverview></EliteOverview>
                </ChartCard>
            </Row>
            <Row gutter={10} style={{ height: '75vh', width: '80wh', margin: '10px' }}>
                <Col span={9} style={{ height: '75vh' }}>
                    <ChartCard title='2. 精英社会网络分析算法+精英决策子群分析算法：宏观-中观-微观社会网络'>
                        <CreamNetwork></CreamNetwork>
                    </ChartCard>
                </Col>
                <Col span={15} style={{ height: '75vh' }}>
                    <ChartCard title='3. 精英人物对华决策影响力算法' description='基于精英画像技术展示精英的个人特质、影响力构成与变化情况'>
                        <RadarChartList></RadarChartList>
                    </ChartCard>
                </Col>
            </Row>
            <Row gutter={10} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='4. 精英人物影响力演化算法' description="展示特定时间段影响力最高的前十名精英的影响力变化情况。该模块使用了精英影响力算法。该算法根据反映精英制度地位、关系网络和过程效果三个维度的8个指标分析而成。制度性指标测量了精英的部门影响力和职级，关系性指标测量了精英资历带来的影响资源，过程性指标测量了精英在媒体、智库、国会、白宫和政策领域五个过程中的影响效果。">
                    <RankflowChart></RankflowChart>
                </ChartCard>
            </Row>
            <Row gutter={10} style={{ height: '65vh', width: '80wh', margin: '10px' }}>
                <Col span={9} style={{ height: '65vh' }}>
                    <AttitudeRank title='5. 精英人物对华态度排名推荐算法' description="展示特定时间段对华态度最好和最美的十名精英的对华态度变化情况。该模块使用了精英对华态度算法，该体系电涉华主题领域（主权、安全、发展）和涉华态度（支持、友善、关切、遏制、敌视）两个层次构成，最终经层次分析法得权重整合为对华态度的合成指标。该算法首先定位和识别精英的代表性对华言论与政策主张，对相关文本对华态度进行测量后，最终统计分配给对应精英。"></AttitudeRank>
                </Col>
                <Col span={15} style={{ height: '65vh' }}>
                    <ChartCard title='6. 精英人物影响力-对华态度坐标系构建' description="根据精英影响力和对华态度划分成团结域、支持域、斗争域、风险域四个象限。分别对应可争取团结的对华态度和影响力均高的精英、值得支持帮助的对华态度好但影响力较低的精英、需要积极斗争的对华态度差但影响力高的精英以及需要警惕风险的对华态度差但当前影响力不高的精英。">
                        <EliteScatterPlot></EliteScatterPlot>
                    </ChartCard>
                </Col>
            </Row>
        </div>
    );
}

export default Cream;