import { Select, Space } from 'antd'
import { useState } from 'react';
import ChordRep from './ChordRep';
import ChordSen from './ChordSen';
import './styles.css'

function Chord() {
    const [library, setLibrary] = useState('1');
    const items = [
      {
        label: '众议院',
        value: '1',
      },
      {
        label: '参议院',
        value: '2',
      }
    ];
    const handlePageChange = (value) => {
      setLibrary(value)
    }
    return (
        <div>

        <div className="select-container" style={{width:"100%", height:"100%"}}>
        <Select
            size='large'
            defaultValue={'1'}
            options={items}
            onChange={handlePageChange}
        >
        </Select>
        </div>
        {library === '1' ? <ChordRep /> : null}
        {library === '2' ? <ChordSen /> : null}

        </div>
    );
  }
  
  export default Chord;