import React from 'react';
import video2 from "./02.m4v"
import video3 from "./03.m4v"
import { Player,BigPlayButton  } from 'video-react';
import 'video-react/dist/video-react.css';

function Metaphor() {
    return (
        <div>
            <img src='./metaphorPic/01.png' style={{width: "100%"}}></img>
            <img src='./metaphorPic/02.png' style={{width: "100%"}}></img>
            <Player width="100%" >
                <source src={video2} />
                <BigPlayButton position="center" />
            </Player>
            <img src='./metaphorPic/04.png' style={{width: "100%"}}></img>
            <img src='./metaphorPic/05.png' style={{width: "100%"}}></img>
            <Player width="100%" >
                <source src={video3} />
                <BigPlayButton position="center" />
            </Player>
        </div>
    );
}

export default Metaphor;