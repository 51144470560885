import React, { useEffect, useState } from "react";
import { Select, Card, Popover } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import * as d3 from "d3";
import "./style.css"

const MediaSingleArticle = (props) => {
    const options = [
        {
            label: '近一周',
            value: '近一周'
        },
        {
            label: '近一月',
            value: '近一月'
        },
        {
            label: '近一年',
            value: '近一年'
        },
        {
            label: '2021-至今',
            value: '2021-至今'
        },
        {
            label: '2017-2021',
            value: '2017-2021'
        },
        {
            label: '2009-2017',
            value: '2009-2017'
        },
        {
            label: '1980-至今',
            value: '1980-至今'
        }]
    const options1 = ['近一周', '近一月', '近一年', '2021-至今', '2017-2021', '2009-2017', '1980-至今']
    const options2 = ["Chicago Tribune", "Los Angeles Times"
        , "New York Times", "The Washingtto Post", "The Wall Street Journal Online"
        , "The Wall Street Journal"]

    const [selectedOption, setSelectedOption] = useState('1980-至今')
    const [selectedOption2, setSelectedOption2] = useState('The Wall Street Journal')

    useEffect(() => {
        readyShow(selectedOption, selectedOption2)
    }, [selectedOption, selectedOption2])

    function handleChangeNext() {
        let index = options2.findIndex(option => option === selectedOption2);
        index = (index === options2.length - 1) ? 0 : (index + 1);
        setSelectedOption2(options2[index])
        setSelectedOption(selectedOption)
    }

    function handleChangeBefore() {
        
        let index = options2.findIndex(option => option === selectedOption2);
        index = (index === 0) ? (options2.length - 1) :( index - 1);
        
        setSelectedOption2(options2[index])
        console.log(index,options2.length,options2[index])
        setSelectedOption(selectedOption)
    }

    function handleChange(value) {
        setSelectedOption(value)
    }

    function readyShow(selectedOption1, selectedOption2) {
        var mode = 1
        Promise.all([
            // enter code to read files
            d3.csv('./media-single-article-all2.csv'),
            selectedOption1,
            selectedOption2
        ]).then(
            function (data) {
                var mode = 1

                let data3 = data[0]

                let all_data2 = { data: {}, label: selectedOption1, media: selectedOption2 }
                for (let j in data3) {
                    let dataline = data3[j]
                    //console.log(dataline.media,dataline)
                    if (dataline.media in all_data2.data) {
                        if (dataline.time in all_data2.data[dataline.media]) {
                            all_data2.data[dataline.media][dataline.time].push(dataline)
                        }
                        else {
                            all_data2.data[dataline.media][dataline.time] = [dataline]
                        }
                    }
                    else {
                        //console.log(1)
                        all_data2.data[`${dataline.media}`] = {}
                        //console.log(dataline.media,all_data2)
                        all_data2.data[dataline.media][dataline['time']] = [dataline]
                    }
                }


                let all_data = { data: {}, label:data[1], media:data[2] }
                for (let i in all_data2.data) {
                    if (i == 'undefined') {
                    }

                    else { all_data.data[i] = all_data2.data[i] }
                }

                var label = all_data.label
                var media = all_data.media
                
                var data
                console.log(all_data)
                if(all_data.data[media][label]){
                    data=all_data.data[media][label]
                }else{
                    d3.select('#media-single-article-svg').remove()
                    return null}
                data=data.filter((dataline)=>(Number(dataline['sentiment'])!=0))
                data=data.sort((a,b)=>(Number(a['影响力'])-Number(b['影响力'])))
                data=data.slice(0,5)
                console.log(data)
                console.log('data',all_data.data[media])
                //data = getRandomArrayElements(data, 10)
                /*
                data=data.sort(function(a,b){
                    if(Number(a['影响力']))>
                    return })
                */
                for (let i in data) {

                    data[i].url = `./pic/media_logo/${data[i].media}.png`
                    data[i].title = data[i]['标题']
                    data[i].author = data[i]['作者']
                    data[i].href = data[i]['文档 URL']
                    data[i].influence = Number(data[i].sentiment)

                }
                data = refineData(data)
                console.log(data)
                const num_data = data.length

                const numFontSize = 14;
                const numFontFamily = "Source Han Sans";
                const margin = { top: 0, right: 0, bottom: 0, left: 0 };


                const offsetBetweenBarAndText = 5;
                const max_left_label_width = d3.max(data, d => getTextWidth(formatNumber(d.influence), numFontSize, numFontFamily)) + offsetBetweenBarAndText + 60
                const max_right_lebel_width = d3.max(data, d => getTextWidth(formatNumber(d.influence), numFontSize, numFontFamily)) + 60


                const width = window.innerWidth * 0.31;
                //const every_data_height = 60
                const height = window.innerHeight * 0.55*data.length/5;

                const every_data_height = height / num_data
                const imageBarGap = 3
                const imageLabelGap = 3
                const xScale = d3.scaleLinear()
                    .domain([-d3.max(data, d => (d.influence > 0) ? d.influence : -d.influence), d3.max(data, d => (d.influence > 0) ? d.influence : -d.influence)])
                    .range([max_left_label_width, width - max_right_lebel_width]);

                // d3.select('svg').attr('height', height + margin.top + margin.bottom).attr('width', width + margin.left + margin.right)

                // Create SVG container
                d3.select('#media-single-article-svg').remove()
                const svg = d3.select("#media-single-article-chart")
                    .append("svg").attr('class', 'svg-download').attr('id', 'media-single-article-svg')
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                // Scale for Y-axis
                const yScale = d3.scaleBand()
                    .domain(data.map(d => d.num))
                    .range([margin.top, height])

                const yScaleBackground = d3.scaleBand()
                    .domain(data.map(d => d.num))
                    .range([0, height])
                for (let i in data) {
                    data[i].refined_title = (data[i].influence >= 0) ? (
                        (getTextWidth(data[i].title, numFontSize, numFontFamily) > 0.9 * (width - xScale(0))) ?
                            cutTextWidth(data[i].title, numFontSize, numFontFamily, 0.9 * (width - xScale(0))) + '...' : data[i].title)
                        : ((getTextWidth(data[i].title, numFontSize, numFontFamily) > 0.9 * (xScale(0))) ?
                            (cutTextWidth(data[i].title, numFontSize, numFontFamily, 0.9 * (xScale(0)))) + '...' : data[i].title)

                    data[i].refined_author = (data[i].influence >= 0) ? (
                        (getTextWidth(data[i].author, numFontSize, numFontFamily) > 0.8 * (width - xScale(0))) ?
                            cutTextWidth(data[i].author, numFontSize, numFontFamily, 0.8 * (width - xScale(0))) + '...' : data[i].author)
                        : ((getTextWidth(data[i].author, numFontSize, numFontFamily) > 0.8 * (xScale(0))) ?
                            (cutTextWidth(data[i].author, numFontSize, numFontFamily, 0.8 * (xScale(0)))) + '...' : data[i].author)
                }
                //console.log(data)
                // Create bars
                let svg_all = svg.selectAll(".bar").data(data)
                    .enter()


                let all_svg = svg_all.append('svg')


                all_svg.append('rect')
                    .attr("class", "bk_bar")
                    .attr("x", d => 0)
                    .attr("y", d => yScale(d.num))
                    .attr("width", d => width)
                    .attr("height", yScale.bandwidth())
                    .attr('num', d => d.num)
                    .style("fill", d => (d.num % 2 == 0) ? "#FAFAFA" : "white")


                all_svg.append('rect')
                    .attr("class", "bar")
                    .attr("x", d => xScale(Math.min(0, (d.influence))))
                    .attr("y", d => yScale(d.num) + yScale.bandwidth() * 0.7)
                    .attr("width", d => Math.abs(xScale(0) - xScale(Math.abs((d.influence)))))
                    .attr("height", yScale.bandwidth() * 0.3)
                    .attr('num', d => d.num)
                    .style("fill", d => d.influence > 0 ? "#6688BB" : "#A1A1A1")



                all_svg.append('text')
                    .text(d => formatNumber(d.influence)).style("fill", d => d.influence >= 0 ? "#6688BB" : "#A1A1A1")
                    .attr('y', d => yScale(d.num) + yScale.bandwidth() / 2 + numFontSize / 2 - 2 + 0.35 * yScale.bandwidth())
                    .style('font-size', numFontSize).style('font-family', numFontFamily)
                    .attr('x', d => {
                        const textWidth = getTextWidth(formatNumber(d.influence), numFontSize, numFontFamily);

                        // 调整文字和柱形图之间的间距

                        if (d.influence < 0) {
                            return xScale((d.influence)) - textWidth - offsetBetweenBarAndText;
                        } else {
                            return xScale((d.influence)) + offsetBetweenBarAndText;
                        }
                    });

                all_svg.append('text')
                    .text(d => { return d.refined_title })
                    .attr('y', d => yScale(d.num) + yScale.bandwidth() / 2 + numFontSize / 2 - 2 - 0.3 * yScale.bandwidth())
                    .style('font-size', numFontSize).style('font-family', numFontFamily).style('text-decoration', d => (d.href) ? 'underline' : 'undefined')
                    .attr('x', d => {
                        var textWidth = getTextWidth(d.refined_title, numFontSize, numFontFamily);
                        if (d.influence < 0) {
                            return xScale(0) - textWidth;
                        } else {
                            return xScale(0);
                        }

                    })
                    .on('click', (d, i) => {
                        //console.log(d,i)
                        //console.log(1,i.href)
                        if (i.href) {
                            //console.log(i.href)
                            window.open(i.href, '_blank');
                        }
                    })

                all_svg.append('text')
                    .text(d => d.refined_author)
                    .attr('y', d => yScale(d.num) + yScale.bandwidth() / 2 + numFontSize / 2 - 2 + 0 * 0.3 * yScale.bandwidth())
                    .style('font-size', numFontSize).style('font-family', numFontFamily).style('fill', '#777777')
                    .attr('x', d => {
                        const textWidth = getTextWidth(d.refined_author, numFontSize, numFontFamily)
                        //console.log(textWidth)
                        // 调整文字和柱形图之间的间距

                        if (d.influence < 0) {
                            return xScale(0) - textWidth;
                        } else {
                            return xScale(0);
                        }
                    });

                let svg_image = all_svg.append('svg').attr('class', 'svg-img');

                //svg.append('rect').attr('width',1).attr('x',xScale(0)-0.5).attr('y',yScale(0)+yScale.bandwidth()*0.2).attr('height',height-yScale.bandwidth()*0.4-yScale(0)).style('fill','black')
            })

        function refineData(data) {
            var newdata = []
            for (let i in data) {
                if (i != 'columns') { newdata.push(data[i]) }

            }
            data = newdata
            for (var i = 0; i < data.length; i++) {
                data[i].influence = (mode == 1) ? Number(data[i].influence) : 2 * (Number(data[i].influence) - 0.5);
            }
            data = data.sort(function (a, b) { return b.influence - a.influence; })
            for (var i = 0; i < data.length; i++) {
                data[i].num = i;
            }
            return data

        }

        function convertToBase64(imgUrl) {
            return new Promise(function (resolve, reject) {
                var image = new Image();
                image.src = imgUrl;
                image.crossOrigin = "Anonymous";
                image.onload = function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;

                    var context = canvas.getContext("2d");
                    context.fillStyle = "#ffffff"; // 设置为白色
                    context.fillRect(0, 0, canvas.width, canvas.height); // 填充整个 Canvas

                    context.drawImage(image, 0, 0);

                    var base64 = canvas.toDataURL("image/jpeg");
                    resolve(base64);
                };
                image.onerror = function () {
                    reject(new Error("Failed to load image"));
                };

            });
        }


        async function f(imgUrl) {
            var externalVariable; // 外部变量

            try {
                externalVariable = await convertToBase64(imgUrl);
                //console.log(externalVariable);
                return externalVariable;
            } catch (error) {
                console.error(error);
            }
        }

        function getRandomArrayElements(arr, count) {
            var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;  //只是声明变量的方式, 也可以分开写
            while (i-- > min) {
                //console.log(i);
                index = Math.floor((i + 1) * Math.random()); //这里的+1 是因为上面i--的操作  所以要加回来
                temp = shuffled[index];  //即值交换
                shuffled[index] = shuffled[i];
                shuffled[i] = temp;
                //console.log(shuffled);
            }
            return shuffled.slice(min);
        }
        function getTextWidth(text, fontSize, fontFamily) {
            const container = document.createElement("span");
            container.style.fontSize = fontSize + "px";
            container.style.fontFamily = fontFamily;
            container.style.visibility = "hidden";
            container.innerText = text;
            document.body.appendChild(container);
            const width = container.offsetWidth;
            document.body.removeChild(container);
            return width;
        }
        function cutTextWidth(text, fontSize, fontFamily, expectedWidth) {
            for (let i in text) {
                var temp_text = text.substring(0, text.length - i)
                if (getTextWidth(temp_text, fontSize, fontFamily) < expectedWidth) { return temp_text }
            }
        }
        function formatNumber(number) {
            let formattedNumber = '';
            if (number >= 0) {
                formattedNumber = '+' + number.toLocaleString();
            } else {
                formattedNumber = '-' + Math.abs(number).toLocaleString();
            }
            return formattedNumber;
        }


        async function processList(list) {
            try {
                for (let index in list) {
                    const base64 = await convertToBase64(list[index].url); // 获取图片的Base64编码
                    list[index].url = base64
                    //console.log(list[index])
                    // 在这里可以对每个元素的Base64编码进行操作
                }
            } catch (error) {
                console.error(error);
                // 处理错误情况
            }
        }

    }
    return (
        <Card title={
            <Popover placement="bottom" content={props.description}>
                <div style={{ width: '100%', cursor: 'pointer' }}>
                    {props.title}
                </div>
            </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue='1980-至今'
                    onChange={handleChange}
                    options={options}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
        >
            <div id="media-single-article-chart"></div>
            <div className="app2-container" style={{height:'10%'}}>
                
                <LeftOutlined onClick={handleChangeBefore} 
                style={{left:'23%',position:'absolute'}}
                />
                <img className="app2-image" src={`pic/media_logo/${selectedOption2}.png `} style={{margin:0,padding:0,height:'60%'}}/>
                <RightOutlined onClick={handleChangeNext} 
                style={{left:'75%',position:'absolute'}}
                />
            </div>
        </Card>
    )
}

export default MediaSingleArticle