import React, { useEffect} from 'react';
import * as d3 from 'd3';

const ThinkScatterplot = () => {
    // Create a ref to attach the SVG element to.
  
    useEffect(() => {
        // Define the dimensions and margins for the scatter plot.
        const margin = { top: 30, bottom: 170, left: 80, right: 80 };
        const width = window.innerWidth - margin.left - margin.right;
        const height = window.innerHeight*0.95 - margin.top - margin.bottom;

        // Create an SVG element for the scatter plot.
        const svg = d3.select("#think-scatter-chart-container")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const tooltip = d3.select("#think-scatter-chart-container")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        // Load data from data.csv and create the scatter plot.
        d3.csv("ScatterData.csv").then(function(data) {
            data.forEach(function(d) {
                d.attitude = +d.attitude;
                d.significance = +d.significance;
            });


            // Add background colors to the four quadrants.
            svg.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", 0.5*width)
                .attr("height", 0.5*height)
                .style("fill", "#FBF8EA");
            
            svg.append("rect")
                .attr("x", 0)
                .attr("y", 0.5*height)
                .attr("width", 0.5*width)
                .attr("height", 0.5*height)
                .style("fill", "#EDF0FE");
            
            svg.append("rect")
                .attr("x", 0.5*width)
                .attr("y", 0)
                .attr("width", 0.5*width)
                .attr("height", 0.5*height)
                .style("fill", "#FCEFEE");
            
            svg.append("rect")
                .attr("x", 0.5*width)
                .attr("y", 0.5*height)
                .attr("width", 0.5*width)
                .attr("height", 0.5*height)
                .style("fill", "#ECF7F1");

            
            svg.append("text")
                .attr("x", 0)
                .attr("y", 0.5*height - 10)
                .style("text-anchor", "start")
                .text("斗争域")
                .style("fill", "#B4B4B4")
                .style("font-size", "110px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);

            svg.append("text")
                .attr("x", width)
                .attr("y", 0.5*height - 10)
                .style("text-anchor", "end")
                .text("团结域")
                .style("fill", "#B4B4B4")
                .style("font-size", "110px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);

            svg.append("text")
                .attr("x", 0)
                .attr("y", height - 10)
                .style("text-anchor", "start")
                .text("风险域")
                .style("fill", "#B4B4B4")
                .style("font-size", "110px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);

            svg.append("text")
                .attr("x", width)
                .attr("y", height - 10)
                .style("text-anchor", "end")
                .text("支持域")
                .style("fill", "#B4B4B4")
                .style("font-size", "110px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);

            // Create scales for x and y axes.
            const xScale = d3.scaleLinear()
                .domain([-0.8, 1])
                .range([0, width]);

            const yScale = d3.scaleLinear()
                .domain([-20, 80])
                .range([height, 0]);

            // Create x and y axes.
            const xAxis = d3.axisBottom(xScale).ticks(20);
            const yAxis = d3.axisRight(yScale);

            // Append axes to the SVG.
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0, ${height})`)
                .call(xAxis)
                .style("font-size", "13px");
                
            // Add grid lines
            svg.selectAll(".x-axis")
                .append("g")
                .attr("class", "grid-lines")
                .selectAll("line")
                .data(xScale.ticks(20))
                .enter()
                .append("line")
                .attr("class", "grid-line")
                .attr("x1", d => xScale(d))
                .attr("x2", d => xScale(d))
                .attr("y1", -height)
                .attr("y2", 0) 
                .style("stroke", "#D3D6DF")
                .style("stroke-dasharray", "2,2");

            svg.append("text")
                .attr("class", "x-label")
                .attr("x", width-35)
                .attr("y", height-15)
                .style("text-anchor", "middle")
                .text("对华态度")
                .style("font-size", "14px");


            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${width/2}, 0)`)
                .call(yAxis)
                .style("font-size", "13px");
                
            svg.selectAll(".y-axis")
                .append("g")
                .attr("class", "grid-lines")
                .selectAll("line")
                .data(yScale.ticks())
                .enter()
                .append("line")
                .attr("class", "grid-line")
                .attr("x1", -width/2)
                .attr("x2", width/2)
                .attr("y1", d => yScale(d)) 
                .attr("y2", d => yScale(d)) 
                .style("stroke", "#D3D6DF")
                .style("stroke-dasharray", "2,2");

            svg.append("text")
                .attr("class", "y-label")
                .attr("x", width/2)
                .attr("y", -15)
                .style("text-anchor", "middle")
                .text("影响力")
                .style("font-size", "14px");

            // Create circles for the scatter plot.
            const circles = svg.selectAll(".circle")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "circle");

            // Define a clipping path as a circle for each data point.
            circles.append("clipPath")
            .attr("id", (d, i) => "clip" + i) // Create a unique ID for each clipping path
            .append("circle")
            .attr("cx", d => xScale(d.attitude))
            .attr("cy", d => yScale(d.significance))
            .attr("r", 25); // Set the circle radius

            // Create a circle with the clipped image inside.
            circles.append("circle")
            .attr("cx", d => xScale(d.attitude))
            .attr("cy", d => yScale(d.significance))
            .attr("r", 25) // Adjust the circle radius as needed
            .style("stroke", "#E5E5EA")
            .style("stroke-width", 0.5)
            .style("fill", "white");

            circles.append("image")
            .attr("xlink:href", d => d.profile_picture) // Set the image URL
            .attr("x", d => xScale(d.attitude) - 25)
            .attr("y", d => yScale(d.significance) - 25)
            .attr("width", 50)
            .attr("height", 50)
            .attr("clip-path", (d, i) => `url(#clip${i})`); // Apply the clipping path

            // Add event listeners for interactivity.
            circles
                .on("mouseover", function (event, d) {
                    // Show the tooltip when hovering over a circle
                    const [mouseX, mouseY] = d3.pointer(event);
                    // Position the tooltip next to the mouse pointer and near the data point
                    this.getBoundingClientRect();
                    const tooltipWidth = parseFloat(tooltip.style("width"));
                    const tooltipHeight = parseFloat(tooltip.style("height"));
                    const myData =  d3.select(this).data()[0]

                    // Set tooltip position initially at the mouse position.
                    let tooltipX = mouseX + 70; // Adjust the horizontal offset
                    let tooltipY = mouseY + 28; // Adjust the vertical offset

                    // Check if the tooltip is too close to the right edge.
                    if (tooltipX + tooltipWidth > width) {
                        tooltipX = width - tooltipWidth;
                    }

                    // Check if the tooltip is too close to the bottom edge.
                    if (tooltipY + tooltipHeight > height) {
                        tooltipY = height - tooltipHeight;
                    }
                    if (tooltipY - tooltipHeight < 0) {
                        tooltipY = margin.top;
                    }

                    tooltip.transition()
                        .duration(200)
                        .style("opacity", 0.9);

                    tooltip.html(`智库名称: ${myData.name}<br>对华态度: ${myData.attitude}<br>影响力: ${myData.significance}`)
                        .style("left", tooltipX + "px") 
                        .style("top", tooltipY + "px") 
                        .style("font-size", "12px"); 
                }) 
                .on("mouseout", function(event, d) { 
                        // Hide the tooltip when moving the mouse away from a circle 
                        tooltip.transition() 
                            .duration(500) 
                            .style("opacity", 0); 
                });
        });

}, []); // Empty dependency array to run this effect only once

return (
    <div id="think-scatter-chart-container" style={containerStyle }>
    </div>
);
};

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width:"100%",
    height:"100%"
  };

export default ThinkScatterplot;