import React, { useEffect, useRef } from 'react';
// import ReactECharts from 'echarts-for-react';
import { init } from 'echarts';

const ThemeRiver = () => {

  const chartRef = useRef(null);

  useEffect(() => {
    const chart_3bcb4e1d4796443c8842abe17472ba57 = init(document.getElementById('3bcb4e1d4796443c8842abe17472ba57'), 'white', { renderer: 'canvas' }, {responsive: true});

    // 图例自适应V1 --初始化时计算
    // 获取容器的宽度
    // const containerWidth = document.getElementById('3bcb4e1d4796443c8842abe17472ba57').offsetWidth;

    // 计算 legend 的宽度
    // const legendWidth = containerWidth * 1.00 - (25 * 2 + 7 + 10);

    // 图例自适应V2 -- 每次页面resize时计算
    const resizeHandler = () => {
        // 获取容器的宽度
        const containerWidth = chartRef.current.offsetWidth;
        // 计算 legend 的宽度(图表宽度的100% - selector（全选和反选）宽度)
        const legendWidth = containerWidth * 1 - (25 * 2 + 7 + 10);
        // 更新图表配置
        chart_3bcb4e1d4796443c8842abe17472ba57.setOption({
          legend: {
            width: legendWidth,
          },
        });
        // 更新图表大小
        chart_3bcb4e1d4796443c8842abe17472ba57.resize();
      };
  
      // 监听窗口的 resize 事件
      window.addEventListener('resize', resizeHandler);


    const option_3bcb4e1d4796443c8842abe17472ba57 = {
        "animation": true,
        "animationThreshold": 2000,
        "animationDuration": 1000,
        "animationEasing": "cubicOut",
        "animationDelay": 0,
        "animationDurationUpdate": 300,
        "animationEasingUpdate": "cubicOut",
        "animationDelayUpdate": 0,
        "aria": {
            "enabled": false
        },
        "color": [
            "#40916c",
            "#52b788",
            "#74c69d",
            "#95d5b2",
            "#b7e4c7",
            "#d8f3dc",
            "#c9184a",
            "#ff4d6d",
            "#ff758f",
            "#ff8fa3",
            "#ffb3c1",
            "#ffccd5",
            "#023e8a",
            "#0077b6",
            "#0096c7",
            "#00b4d8",
            "#48cae4",
            "#90e0ef",
            "#ade8f4",
            "#caf0f8"
        ],
        "series": [
            {
                "type": "themeRiver",
                "name": [
                    "\u9999\u6e2f",
                    "\u53f0\u6e7e",
                    "\u6fb3\u95e8",
                    "\u65b0\u7586",
                    "\u897f\u85cf",
                    "\u5185\u6d77\u95ee\u9898",
                    "\u5bf9\u534e\u6218\u7565",
                    "\u610f\u8bc6\u5f62\u6001",
                    "\u653f\u6743\u5236\u5ea6",
                    "\u519b\u4e8b\u95ee\u9898",
                    "\u4eba\u6743\u95ee\u9898",
                    "\u516c\u5171\u536b\u751f",
                    "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe",
                    "\u7ecf\u6d4e\u8d38\u6613",
                    "\u79d1\u5b66\u6280\u672f",
                    "\u80fd\u6e90\u73af\u5883",
                    "\u6c14\u5019\u73af\u5883",
                    "\u793e\u4f1a\u53d1\u5c55",
                    "\u77e5\u8bc6\u4ea7\u6743"
                ],
                "data": [
                    [
                        "2023",
                        34.18048780487805,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2022",
                        60.57551020408163,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2020",
                        42.14028776978417,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2018",
                        6.494845360824742,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2016",
                        4.389830508474576,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2014",
                        4.629032258064516,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2012",
                        3.6885245901639343,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2010",
                        12.041666666666666,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2008",
                        8.13953488372093,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2006",
                        12.428571428571427,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2004",
                        11.243243243243244,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2002",
                        7.4576271186440675,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2000",
                        7.638888888888888,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1998",
                        20.816326530612244,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1996",
                        11.76923076923077,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1994",
                        10.4,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1992",
                        14.571428571428571,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1990",
                        14.324324324324325,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1988",
                        2.3823529411764706,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1986",
                        0.0,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u9999\u6e2f"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u9999\u6e2f"
                    ],
                    [
                        "2023",
                        57.2,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2022",
                        99.77142857142857,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2020",
                        46.888489208633096,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2018",
                        20.783505154639176,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2016",
                        13.169491525423728,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2014",
                        17.85483870967742,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2012",
                        25.81967213114754,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2010",
                        24.083333333333332,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2008",
                        13.023255813953488,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2006",
                        33.971428571428575,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2004",
                        26.7027027027027,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2002",
                        21.6271186440678,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2000",
                        36.80555555555556,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1998",
                        33.673469387755105,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1996",
                        22.884615384615387,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1994",
                        28.799999999999997,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1992",
                        13.357142857142858,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1990",
                        12.891891891891893,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1988",
                        13.5,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1986",
                        9.0,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1984",
                        7.0,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1982",
                        0.75,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "1980",
                        28.0,
                        "\u53f0\u6e7e"
                    ],
                    [
                        "2023",
                        10.463414634146341,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2022",
                        7.126530612244897,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2020",
                        10.089928057553957,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2018",
                        2.597938144329897,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2016",
                        0.6271186440677966,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2014",
                        0.6612903225806451,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2012",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2010",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2008",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2006",
                        0.8285714285714285,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2004",
                        0.7027027027027027,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2002",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2000",
                        5.555555555555555,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1998",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1996",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1994",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1992",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1990",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1988",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1986",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u6fb3\u95e8"
                    ],
                    [
                        "2023",
                        20.926829268292682,
                        "\u65b0\u7586"
                    ],
                    [
                        "2022",
                        57.60612244897959,
                        "\u65b0\u7586"
                    ],
                    [
                        "2020",
                        24.928057553956837,
                        "\u65b0\u7586"
                    ],
                    [
                        "2018",
                        2.597938144329897,
                        "\u65b0\u7586"
                    ],
                    [
                        "2016",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "2014",
                        1.3225806451612903,
                        "\u65b0\u7586"
                    ],
                    [
                        "2012",
                        1.4754098360655739,
                        "\u65b0\u7586"
                    ],
                    [
                        "2010",
                        4.958333333333333,
                        "\u65b0\u7586"
                    ],
                    [
                        "2008",
                        4.069767441860465,
                        "\u65b0\u7586"
                    ],
                    [
                        "2006",
                        3.314285714285714,
                        "\u65b0\u7586"
                    ],
                    [
                        "2004",
                        1.4054054054054055,
                        "\u65b0\u7586"
                    ],
                    [
                        "2002",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "2000",
                        6.25,
                        "\u65b0\u7586"
                    ],
                    [
                        "1998",
                        5.5102040816326525,
                        "\u65b0\u7586"
                    ],
                    [
                        "1996",
                        0.6538461538461539,
                        "\u65b0\u7586"
                    ],
                    [
                        "1994",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "1992",
                        1.2142857142857142,
                        "\u65b0\u7586"
                    ],
                    [
                        "1990",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "1988",
                        0.7941176470588235,
                        "\u65b0\u7586"
                    ],
                    [
                        "1986",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u65b0\u7586"
                    ],
                    [
                        "2023",
                        10.463414634146341,
                        "\u897f\u85cf"
                    ],
                    [
                        "2022",
                        30.881632653061224,
                        "\u897f\u85cf"
                    ],
                    [
                        "2020",
                        19.586330935251798,
                        "\u897f\u85cf"
                    ],
                    [
                        "2018",
                        14.938144329896907,
                        "\u897f\u85cf"
                    ],
                    [
                        "2016",
                        6.898305084745763,
                        "\u897f\u85cf"
                    ],
                    [
                        "2014",
                        9.919354838709678,
                        "\u897f\u85cf"
                    ],
                    [
                        "2012",
                        8.114754098360656,
                        "\u897f\u85cf"
                    ],
                    [
                        "2010",
                        9.208333333333334,
                        "\u897f\u85cf"
                    ],
                    [
                        "2008",
                        9.767441860465116,
                        "\u897f\u85cf"
                    ],
                    [
                        "2006",
                        5.800000000000001,
                        "\u897f\u85cf"
                    ],
                    [
                        "2004",
                        11.945945945945947,
                        "\u897f\u85cf"
                    ],
                    [
                        "2002",
                        12.677966101694915,
                        "\u897f\u85cf"
                    ],
                    [
                        "2000",
                        17.36111111111111,
                        "\u897f\u85cf"
                    ],
                    [
                        "1998",
                        25.10204081632653,
                        "\u897f\u85cf"
                    ],
                    [
                        "1996",
                        10.461538461538462,
                        "\u897f\u85cf"
                    ],
                    [
                        "1994",
                        16.8,
                        "\u897f\u85cf"
                    ],
                    [
                        "1992",
                        21.857142857142858,
                        "\u897f\u85cf"
                    ],
                    [
                        "1990",
                        25.783783783783786,
                        "\u897f\u85cf"
                    ],
                    [
                        "1988",
                        9.529411764705882,
                        "\u897f\u85cf"
                    ],
                    [
                        "1986",
                        1.0,
                        "\u897f\u85cf"
                    ],
                    [
                        "1984",
                        6.0,
                        "\u897f\u85cf"
                    ],
                    [
                        "1982",
                        2.25,
                        "\u897f\u85cf"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u897f\u85cf"
                    ],
                    [
                        "2023",
                        9.765853658536587,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2022",
                        35.03877551020408,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2020",
                        21.366906474820144,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2018",
                        15.587628865979383,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2016",
                        11.915254237288135,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2014",
                        6.612903225806451,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2012",
                        5.901639344262295,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2010",
                        0.7083333333333333,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2008",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2006",
                        1.657142857142857,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2004",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2002",
                        2.2372881355932206,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2000",
                        1.3888888888888888,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1998",
                        4.8979591836734695,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1996",
                        5.230769230769231,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1994",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1992",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1990",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1988",
                        0.7941176470588235,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1986",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1984",
                        2.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u5185\u6d77\u95ee\u9898"
                    ],
                    [
                        "2023",
                        19.25287356321839,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2022",
                        48.54072790294627,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2020",
                        33.575815738963534,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2018",
                        11.864583333333332,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2016",
                        2.1139896373056994,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2014",
                        7.005649717514125,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2012",
                        8.799999999999999,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2010",
                        5.280701754385965,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2008",
                        4.384615384615384,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2006",
                        5.684210526315789,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2004",
                        1.5789473684210527,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2002",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2000",
                        3.1337579617834397,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1998",
                        2.280701754385965,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1996",
                        3.2688172043010755,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1994",
                        1.6304347826086956,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1992",
                        0.7777777777777778,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1990",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1988",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1986",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u5bf9\u534e\u6218\u7565"
                    ],
                    [
                        "2023",
                        45.4367816091954,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2022",
                        117.98093587521663,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2020",
                        44.53934740882918,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2018",
                        16.052083333333332,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2016",
                        8.455958549222798,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2014",
                        8.406779661016948,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2012",
                        4.3999999999999995,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2010",
                        7.543859649122807,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2008",
                        5.846153846153846,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2006",
                        1.6240601503759398,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2004",
                        4.7368421052631575,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2002",
                        1.6346153846153846,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2000",
                        3.1337579617834397,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1998",
                        10.643274853801168,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1996",
                        1.6344086021505377,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1994",
                        0.0,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1992",
                        1.5555555555555556,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1990",
                        5.444444444444445,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1988",
                        0.8205128205128205,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1986",
                        0.7941176470588235,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u610f\u8bc6\u5f62\u6001"
                    ],
                    [
                        "2023",
                        1.5402298850574712,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2022",
                        17.528596187175044,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2020",
                        6.852207293666027,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2018",
                        2.7916666666666665,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2016",
                        1.4093264248704662,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2014",
                        1.4011299435028248,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2012",
                        2.933333333333333,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2010",
                        0.7543859649122807,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2008",
                        0.7307692307692307,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2006",
                        4.060150375939849,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2004",
                        3.1578947368421053,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2002",
                        1.6346153846153846,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2000",
                        7.050955414012739,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1998",
                        6.842105263157895,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1996",
                        0.0,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1994",
                        3.260869565217391,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1992",
                        5.444444444444445,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1990",
                        8.555555555555555,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1988",
                        0.8205128205128205,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1986",
                        1.588235294117647,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1982",
                        0.7777777777777778,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u653f\u6743\u5236\u5ea6"
                    ],
                    [
                        "2023",
                        274.1609195402299,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2022",
                        468.5528596187175,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2020",
                        204.1957773512476,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2018",
                        113.0625,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2016",
                        78.92227979274611,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2014",
                        69.35593220338983,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2012",
                        74.06666666666666,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2010",
                        76.94736842105263,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2008",
                        70.15384615384615,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2006",
                        67.3984962406015,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2004",
                        58.421052631578945,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2002",
                        53.125,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2000",
                        77.56050955414013,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1998",
                        82.10526315789473,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1996",
                        53.935483870967744,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1994",
                        49.72826086956521,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1992",
                        64.55555555555556,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1990",
                        68.44444444444444,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1988",
                        22.153846153846153,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1986",
                        16.676470588235293,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1984",
                        17.333333333333332,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1982",
                        5.444444444444445,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "1980",
                        8.1,
                        "\u519b\u4e8b\u95ee\u9898"
                    ],
                    [
                        "2023",
                        61.609195402298845,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2022",
                        125.39688041594454,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2020",
                        67.83685220729367,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2018",
                        57.229166666666664,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2016",
                        45.09844559585492,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2014",
                        37.83050847457627,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2012",
                        30.799999999999997,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2010",
                        38.473684210526315,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2008",
                        51.88461538461538,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2006",
                        29.233082706766915,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2004",
                        22.105263157894736,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2002",
                        28.60576923076923,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2000",
                        32.12101910828026,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1998",
                        28.128654970760234,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1996",
                        17.161290322580648,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1994",
                        20.380434782608695,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1992",
                        25.666666666666668,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1990",
                        29.555555555555557,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1988",
                        8.205128205128204,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1986",
                        7.941176470588235,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1984",
                        8.666666666666666,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1982",
                        0.7777777777777778,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "1980",
                        0.9,
                        "\u4eba\u6743\u95ee\u9898"
                    ],
                    [
                        "2023",
                        23.141361256544503,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2022",
                        71.87645687645688,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2020",
                        53.42521166509878,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2018",
                        15.5906432748538,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2016",
                        11.109826589595375,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2014",
                        10.47715736040609,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2012",
                        9.486646884272997,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2010",
                        13.516556291390728,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2008",
                        15.934826883910386,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2006",
                        13.656387665198238,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2004",
                        11.314285714285715,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2002",
                        9.911262798634812,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2000",
                        10.23846153846154,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1998",
                        8.027522935779817,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1996",
                        6.591836734693877,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1994",
                        8.820512820512821,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1992",
                        8.646616541353383,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1990",
                        7.208955223880597,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1988",
                        3.09375,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1986",
                        2.698795180722892,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1984",
                        0.8387096774193549,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1982",
                        1.8,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "1980",
                        1.4482758620689655,
                        "\u516c\u5171\u536b\u751f"
                    ],
                    [
                        "2023",
                        80.10471204188482,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2022",
                        137.18135198135198,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2020",
                        61.459078080903105,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2018",
                        25.742690058479532,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2016",
                        17.91907514450867,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2014",
                        17.0253807106599,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2012",
                        20.2106824925816,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2010",
                        22.874172185430464,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2008",
                        24.234215885947048,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2006",
                        21.508810572687224,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2004",
                        14.4,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2002",
                        13.815699658703073,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2000",
                        17.684615384615384,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1998",
                        13.761467889908257,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1996",
                        12.489795918367347,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1994",
                        11.393162393162394,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1992",
                        8.300751879699249,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1990",
                        9.955223880597014,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1988",
                        4.125,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1986",
                        3.0843373493975905,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1984",
                        2.5161290322580645,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1982",
                        1.35,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "1980",
                        1.9310344827586208,
                        "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe"
                    ],
                    [
                        "2023",
                        81.8848167539267,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2022",
                        151.96736596736596,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2020",
                        76.72342427093132,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2018",
                        42.05847953216374,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2016",
                        26.161849710982658,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2014",
                        27.1751269035533,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2012",
                        29.28486646884273,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2010",
                        30.1523178807947,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2008",
                        34.85743380855397,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2006",
                        34.48237885462555,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2004",
                        23.314285714285713,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2002",
                        20.72354948805461,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2000",
                        28.853846153846156,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1998",
                        37.079510703363916,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1996",
                        22.551020408163264,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1994",
                        23.153846153846157,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1992",
                        20.751879699248118,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1990",
                        23.0,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1988",
                        11.34375,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1986",
                        7.710843373493976,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1984",
                        7.967741935483871,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1982",
                        3.15,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "1980",
                        4.344827586206897,
                        "\u7ecf\u6d4e\u8d38\u6613"
                    ],
                    [
                        "2023",
                        126.38743455497382,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2022",
                        271.4876456876457,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2020",
                        125.3283160865475,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2018",
                        46.409356725146196,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2016",
                        29.745664739884393,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2014",
                        32.41370558375635,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2012",
                        37.946587537091986,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2010",
                        37.77704194260485,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2008",
                        40.83299389002037,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2006",
                        42.33480176211454,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2004",
                        27.771428571428572,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2002",
                        20.72354948805461,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2000",
                        29.784615384615385,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1998",
                        34.78593272171254,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1996",
                        20.46938775510204,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1994",
                        20.213675213675216,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1992",
                        25.593984962406015,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1990",
                        25.059701492537314,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1988",
                        12.71875,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1986",
                        10.024096385542169,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1984",
                        6.709677419354839,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1982",
                        1.35,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "1980",
                        3.3793103448275863,
                        "\u79d1\u5b66\u6280\u672f"
                    ],
                    [
                        "2023",
                        60.07853403141362,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2022",
                        113.77016317016317,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2020",
                        43.78457196613358,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2018",
                        24.654970760233915,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2016",
                        18.277456647398843,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2014",
                        20.299492385786802,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2012",
                        22.685459940652816,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2010",
                        24.26048565121413,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2008",
                        25.894093686354378,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2006",
                        24.922907488986784,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2004",
                        13.714285714285715,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2002",
                        15.617747440273039,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2000",
                        18.305128205128206,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1998",
                        15.672782874617738,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1996",
                        11.795918367346939,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1994",
                        13.965811965811966,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1992",
                        11.759398496240602,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1990",
                        13.731343283582088,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1988",
                        8.25,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1986",
                        5.0120481927710845,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1984",
                        7.129032258064516,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1982",
                        0.9,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "1980",
                        2.896551724137931,
                        "\u80fd\u6e90\u73af\u5883"
                    ],
                    [
                        "2023",
                        21.80628272251309,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2022",
                        62.42983682983683,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2020",
                        24.10159924741298,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2018",
                        14.865497076023392,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2016",
                        10.034682080924854,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2014",
                        11.459390862944161,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2012",
                        9.486646884272997,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2010",
                        14.20971302428256,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2008",
                        9.959266802443992,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2006",
                        3.755506607929515,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2004",
                        4.8,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2002",
                        3.604095563139932,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2000",
                        8.066666666666666,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1998",
                        6.4984709480122325,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1996",
                        3.122448979591837,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1994",
                        3.307692307692308,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1992",
                        6.571428571428571,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1990",
                        6.17910447761194,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1988",
                        0.6875,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1986",
                        0.7710843373493976,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1984",
                        0.8387096774193549,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1982",
                        0.45,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u6c14\u5019\u73af\u5883"
                    ],
                    [
                        "2023",
                        0.0,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2022",
                        4.107226107226108,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2020",
                        4.016933207902164,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2018",
                        2.1754385964912277,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2016",
                        0.7167630057803468,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2014",
                        1.3096446700507614,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2012",
                        0.8249258160237388,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2010",
                        2.0794701986754967,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2008",
                        1.3279022403258656,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2006",
                        1.7070484581497798,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2004",
                        1.7142857142857144,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2002",
                        0.3003412969283277,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2000",
                        0.0,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1998",
                        0.382262996941896,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1996",
                        0.0,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1994",
                        0.7350427350427351,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1992",
                        1.7293233082706765,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1990",
                        3.08955223880597,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1988",
                        0.34375,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1986",
                        1.1566265060240966,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u793e\u4f1a\u53d1\u5c55"
                    ],
                    [
                        "2023",
                        31.596858638743456,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2022",
                        68.17995337995339,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2020",
                        38.16086547507055,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2018",
                        14.502923976608187,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2016",
                        10.034682080924854,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2014",
                        8.84010152284264,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2012",
                        9.074183976261127,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2010",
                        12.130242825607064,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2008",
                        9.959266802443992,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2006",
                        12.63215859030837,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2004",
                        10.971428571428572,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2002",
                        3.3037542662116044,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "2000",
                        8.066666666666666,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1998",
                        8.792048929663608,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1996",
                        7.979591836734694,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1994",
                        4.410256410256411,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1992",
                        8.646616541353383,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1990",
                        3.7761194029850746,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1988",
                        3.4375,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1986",
                        1.5421686746987953,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1984",
                        0.0,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1982",
                        0.0,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ],
                    [
                        "1980",
                        0.0,
                        "\u77e5\u8bc6\u4ea7\u6743"
                    ]
                ],
                "label": {
                    "show": false,
                    "position": [
                        "10%",
                        "50%"
                    ],
                    "margin": 8,
                    "align": "left"
                },
                "rippleEffect": {
                    "show": true,
                    "brushType": "stroke",
                    "scale": 2.5,
                    "period": 4
                }
            }
        ],
        "legend": [
            {
                "data": [
                    "\u9999\u6e2f",
                    "\u53f0\u6e7e",
                    "\u6fb3\u95e8",
                    "\u65b0\u7586",
                    "\u897f\u85cf",
                    "\u5185\u6d77\u95ee\u9898",
                    "\u5bf9\u534e\u6218\u7565",
                    "\u610f\u8bc6\u5f62\u6001",
                    "\u653f\u6743\u5236\u5ea6",
                    "\u519b\u4e8b\u95ee\u9898",
                    "\u4eba\u6743\u95ee\u9898",
                    "\u516c\u5171\u536b\u751f",
                    "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe",
                    "\u7ecf\u6d4e\u8d38\u6613",
                    "\u79d1\u5b66\u6280\u672f",
                    "\u80fd\u6e90\u73af\u5883",
                    "\u6c14\u5019\u73af\u5883",
                    "\u793e\u4f1a\u53d1\u5c55",
                    "\u77e5\u8bc6\u4ea7\u6743"
                ],
                "selected": {
                    "\u9999\u6e2f": false,
                    "\u53f0\u6e7e": false,
                    "\u6fb3\u95e8": false,
                    "\u65b0\u7586": false,
                    "\u897f\u85cf": false,
                    "\u5185\u6d77\u95ee\u9898": false,
                    "\u5bf9\u534e\u6218\u7565": false,
                    "\u610f\u8bc6\u5f62\u6001": false,
                    "\u653f\u6743\u5236\u5ea6": false,
                    "\u519b\u4e8b\u95ee\u9898": false,
                    "\u4eba\u6743\u95ee\u9898": false,
                    "\u516c\u5171\u536b\u751f": false,
                    "\u57fa\u7840\u8bbe\u65bd\u5efa\u8bbe": false,
                    "\u7ecf\u6d4e\u8d38\u6613": false,
                    "\u79d1\u5b66\u6280\u672f": false,
                    "\u80fd\u6e90\u73af\u5883": false,
                    "\u6c14\u5019\u73af\u5883": false,
                    "\u793e\u4f1a\u53d1\u5c55": false,
                    "\u77e5\u8bc6\u4ea7\u6743": false
                },
                "selector": [
                    {
                        // 全选
                        "type": 'all',
                        "title": '全选',
                    },
                    {
                        // 反选
                        "type": 'inverse',
                        "title": '反选'
                    }
                ],
                "selectorLabel": {
                    "borderRadius":4
                },
                // "width": "95%",
                // "width": legendWidth,
                "show": true,
                "padding": 5,
                "itemGap": 10,
                "itemWidth": 25,
                "itemHeight": 14,
                "backgroundColor": "transparent",
                "borderColor": "#ccc",
                "borderWidth": 1,
                "borderRadius": 0,
                "pageButtonItemGap": 5,
                "pageButtonPosition": "end",
                "pageFormatter": "{current}/{total}",
                "pageIconColor": "#2f4554",
                "pageIconInactiveColor": "#aaa",
                "pageIconSize": 15,
                "animationDurationUpdate": 800,
                // "selector": false,
                // "selectorPosition": "auto",
                "selectorItemGap": 7,
                "selectorButtonGap": 10,
                "textStyle": {
                    "fontSize": 16
                }
            }
        ],
        "tooltip": {
            "show": true,
            "trigger": "axis",
            "triggerOn": "mousemove|click",
            "axisPointer": {
                "type": "line"
            },
            "showContent": true,
            "alwaysShowContent": false,
            "showDelay": 0,
            "hideDelay": 100,
            "enterable": false,
            "confine": false,
            "appendToBody": false,
            "transitionDuration": 0.4,
            "textStyle": {
                "fontSize": 14
            },
            "borderWidth": 0,
            "padding": 5,
            "order": "seriesAsc"
        },
        "singleAxis": {
            "top": "50",
            "bottom": "50",
            "type": "time"
        },
        "title": [
            {
                "show": true,
                "target": "blank",
                "subtarget": "blank",
                "padding": 5,
                "itemGap": 10,
                "textAlign": "auto",
                "textVerticalAlign": "auto",
                "triggerEvent": false
            }
        ],
        "toolbox": {
            "show": false,
            "orient": "horizontal",
            "itemSize": 15,
            "itemGap": 10,
            "left": "80%",
            "feature": {
                "saveAsImage": {
                    "type": "png",
                    "backgroundColor": "auto",
                    "connectedBackgroundColor": "#fff",
                    "show": true,
                    "title": "\u4fdd\u5b58\u4e3a\u56fe\u7247",
                    "pixelRatio": 1
                },
                "restore": {
                    "show": true,
                    "title": "\u8fd8\u539f"
                },
                "dataView": {
                    "show": true,
                    "title": "\u6570\u636e\u89c6\u56fe",
                    "readOnly": false,
                    "lang": [
                        "\u6570\u636e\u89c6\u56fe",
                        "\u5173\u95ed",
                        "\u5237\u65b0"
                    ],
                    "backgroundColor": "#fff",
                    "textareaColor": "#fff",
                    "textareaBorderColor": "#333",
                    "textColor": "#000",
                    "buttonColor": "#c23531",
                    "buttonTextColor": "#fff"
                },
                "dataZoom": {
                    "show": true,
                    "title": {
                        "zoom": "\u533a\u57df\u7f29\u653e",
                        "back": "\u533a\u57df\u7f29\u653e\u8fd8\u539f"
                    },
                    "icon": {},
                    "filterMode": "filter"
                },
                "magicType": {
                    "show": true,
                    "type": [
                        "line",
                        "bar",
                        "stack",
                        "tiled"
                    ],
                    "title": {
                        "line": "\u5207\u6362\u4e3a\u6298\u7ebf\u56fe",
                        "bar": "\u5207\u6362\u4e3a\u67f1\u72b6\u56fe",
                        "stack": "\u5207\u6362\u4e3a\u5806\u53e0",
                        "tiled": "\u5207\u6362\u4e3a\u5e73\u94fa"
                    },
                    "icon": {}
                }
            }
        }
    };


    chart_3bcb4e1d4796443c8842abe17472ba57.setOption(option_3bcb4e1d4796443c8842abe17472ba57);

    resizeHandler();

    // Cleanup when the component unmounts
    return () => {
      chart_3bcb4e1d4796443c8842abe17472ba57.dispose();
      // 清除 resize 事件监听器
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className="river-container" style={chartContainerStyle}>
      <div id="3bcb4e1d4796443c8842abe17472ba57" ref={chartRef} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};


const chartContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width:'98%',
    height:'98%'
  };

export default ThemeRiver;
