import React, { useEffect } from "react";
import * as d3 from "d3";
import "./style.css"

const ThinkOverview = () => {
    const mediaList = [
        {
            "nameChinese": "布鲁金斯学会",
            "nameEnglish": "Brookings Institution",
            "logo": "think/布鲁金斯学会.png",
            "timeRange": "2008-",
            "chinaReports": 3886
        },
        {
            "nameChinese": "彼得森国际经济研究所",
            "nameEnglish": "Peterson Institute for International Economics（PIIE）",
            "logo": "think/彼得森国际经济研究所.png",
            "timeRange": "1989-",
            "chinaReports": 1476
        },
        {
            "nameChinese": "战略与国际问题研究中心",
            "nameEnglish": "Center for Strategic and International Studies（CSIS）",
            "logo": "think/战略与国际问题研究中心.png",
            "timeRange": "2013-",
            "chinaReports": 1948
        },
        {
            "nameChinese": "卡内基国际和平基金会",
            "nameEnglish": "Carnegie Endowment for International Peace",
            "logo": "think/卡内基国际和平基金会.png",
            "timeRange": "2003-",
            "chinaReports": 8132
        },
        {
            "nameChinese": "美国传统基金会",
            "nameEnglish": "Heritage Foundation",
            "logo": "think/美国传统基金会.png",
            "timeRange": "2004-",
            "chinaReports": 766
        },
        {
            "nameChinese": "大西洋理事会",
            "nameEnglish": "Atlantic Council",
            "logo": "think/大西洋理事会.png",
            "timeRange": "2020-",
            "chinaReports": 1984
        },
        {
            "nameChinese": "伍德罗·威尔逊国际学者中心",
            "nameEnglish": "Wilson Center, FKA Woodrow Wilson International Center for Scholars（WWICS）",
            "logo": "think/伍德罗·威尔逊国际学者中心.png",
            "timeRange": "1993-",
            "chinaReports": 2127
        },
        {
            "nameChinese": "兰德公司",
            "nameEnglish": "RAND Corporation",
            "logo": "think/兰德公司.png",
            "timeRange": "1989-",
            "chinaReports": 2686
        },
        {
            "nameChinese": "史汀生研究中心",
            "nameEnglish": "Stimson Center",
            "logo": "think/史汀生研究中心.png",
            "timeRange": "1986-",
            "chinaReports": 2543
        },
        {
            "nameChinese": "美国外交关系协会",
            "nameEnglish": "Council on Foreign Relations （CFR）",
            "logo": "think/美国外交关系协会.png",
            "timeRange": "2018-",
            "chinaReports": 2435
        },
        {
            "nameChinese": "贝尔弗科学与国际事务中心",
            "nameEnglish": "Belfer Center for Science and International Affairs",
            "logo": "think/贝尔弗科学与国际事务中心.png",
            "timeRange": "1969-",
            "chinaReports": 9670
        },
        {
            "nameChinese": "卡托研究所",
            "nameEnglish": "Cato Institute",
            "logo": "think/卡托研究所.png",
            "timeRange": "1945-",
            "chinaReports": 1663
        },
        {
            "nameChinese": "哈德逊研究所",
            "nameEnglish": "Hudson Institute,",
            "logo": "think/哈德逊研究所.png",
            "timeRange": "1962-",
            "chinaReports": 1205
        },
        {
            "nameChinese": "新美国安全中心",
            "nameEnglish": "Center for a New American Security（CNAS）",
            "logo": "think/新美国安全中心.png",
            "timeRange": "1999-",
            "chinaReports": 1318
        },
        {
            "nameChinese": "美国企业公共政策研究所",
            "nameEnglish": "American Enterprise Institute for Public Policy Research（AEI）",
            "logo": "think/美国企业公共政策研究所.png",
            "timeRange": "1958-",
            "chinaReports": 1592
        },
        {
            "nameChinese": "德国马歇尔基金会",
            "nameEnglish": "German Marshall Fund of the United States（GMF）",
            "logo": "think/德国马歇尔基金会.png",
            "timeRange": "2018-",
            "chinaReports": 141
        },
        {
            "nameChinese": "胡佛研究所",
            "nameEnglish": "Hoover Institution",
            "logo": "think/胡佛研究所.png",
            "timeRange": "1989-",
            "chinaReports": 4945
        },
        {
            "nameChinese": "亚洲协会政策研究所",
            "nameEnglish": "Asia Society Policy Institute（ASPI）",
            "logo": "think/亚洲协会政策研究所.png",
            "timeRange": "2018-",
            "chinaReports": 9240
        },
        {
            "nameChinese": "外交政策研究所",
            "nameEnglish": "Foreign Policy Research Institute（FPRI）",
            "logo": "think/外交政策研究所.png",
            "timeRange": "1993-",
            "chinaReports": 2048
        },
        {
            "nameChinese": "信息技术与创新基金会 ",
            "nameEnglish": "Information Technology and Innovation Foundation（ITIF）",
            "logo": "think/信息技术与创新基金会.png",
            "timeRange": "1999-",
            "chinaReports": 1814
        }
    ]
    useEffect(() => {
        const container = d3.select("#think-overview");

        const mediaItems = container.selectAll(".think-item")
            .data(mediaList)
            .enter()
            .append("div")
            .attr("class", "think-item");

        const line_one = mediaItems.append("div")
            .attr("class", "line_one");
        const line_two = mediaItems.append("div")
            .attr("class", "line_two");


        line_one.append("div")
            .attr("class", "media-logo")
            .append("img")
            .attr("class", "think-overview-img")
            .attr("src", d => d.logo);

        const mediaName = line_one.append("div")
            .attr("class", "media-name");

        mediaName.append("p")
            .attr("class", "chinese")
            .text(d => d.nameChinese);

        mediaName.append("p")
            .attr("class", "english")
            .text(d => d.nameEnglish);

        line_two.append("div")
            .attr("class", "media-time")
            .html(d => `<div class="media-time-up">${d.timeRange}</div><div class="media-time-low">时间范围</div>`);

        line_two.append("div")
            .attr("class", "media-rate")
            .html(d => `<div class="media-time-up">${d.chinaReports}</div><div class="media-time-low">涉华报告</div>`);

    }, []);

    return (
        <div id="think-overview" style={{overflow: "auto", maxHeight: "95%" }}></div>
    );
}

export default ThinkOverview;