import React from 'react';
import pdfView from "./20231026-production-shanghai.pdf"

function Case2() {
    return (
        <div className='case-page' style={{height:"calc(100vh - 64px)", overflowY:'hidden'}}>
            <iframe
                title="PDF Viewer"
                src={pdfView}
                width="100%"
                height="100%"
            />
        </div>
    );
}

export default Case2;