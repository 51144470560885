import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import { Card, Select, Popover } from "antd"

function getRandomArrayElements(arr, count) {
    var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;  //只是声明变量的方式, 也可以分开写
    while (i-- > min) {
        //console.log(i);
        index = Math.floor((i + 1) * Math.random()); //这里的+1 是因为上面i--的操作  所以要加回来
        temp = shuffled[index];  //即值交换
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
        //console.log(shuffled);
    }
    return shuffled.slice(min);
}

function getTextWidth(text, fontSize, fontFamily) {
    const container = document.createElement("span");
    container.style.fontSize = fontSize + "px";
    container.style.fontFamily = fontFamily;
    container.style.visibility = "hidden";
    container.innerText = text;
    document.body.appendChild(container);
    const width = container.offsetWidth;
    document.body.removeChild(container);
    return width;
}

function formatNumber(number) {
    let formattedNumber = '';
    if (number >= 0) {
        formattedNumber = '+' + number.toLocaleString();
    } else {
        formattedNumber = '-' + Math.abs(number).toLocaleString();
    }
    return formattedNumber;
}

function convertToBase64(imgUrl) {
    return new Promise(function (resolve, reject) {
        var image = new Image();
        image.src = imgUrl;
        image.crossOrigin = "Anonymous";
        image.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;

            var context = canvas.getContext("2d");
            context.fillStyle = "#ffffff"; // 设置为白色
            context.fillRect(0, 0, canvas.width, canvas.height); // 填充整个 Canvas

            context.drawImage(image, 0, 0);

            var base64 = canvas.toDataURL("image/jpeg");
            resolve(base64);
        };
        image.onerror = function () {
            reject(new Error("Failed to load image"));
        };

    });
}

const AttitudeRank = (props) => {
    const options = [
        /*{
            label: '2022-12-25',
            value: '2022-12-25'
        },
        {
            label: '2023-01-01',
            value: '2023-01-01'
        },
        {
            label: '2023-01-07',
            value: '2023-01-07'
        },
        {
            label: '2023-01-14',
            value: '2023-01-14'
        },
        {
            label: '2023-01-21',
            value: '2023-01-21'
        },
        {
            label: '2023-01-28',
            value: '2023-01-28'
        },
        {
            label: '2023-02-06',
            value: '2023-02-06'
        },
        {
            label: '2023-02-13',
            value: '2023-02-13'
        },
        {
            label: '2023-02-20',
            value: '2023-02-20'
        },
        {
            label: '2023-02-27',
            value: '2023-02-27'
        },
        {
            label: '2023-03-06',
            value: '2023-03-06'
        }*/
        {
            label: '2023年',
            value: '2023年平均'
        }
    ]
    const [data, setData] = useState(null);
    const [selectedOption, setSelectedOption] = useState('2023年平均');

    const handleChange = (value) => {
        setSelectedOption(value);
    };
    const mode = 2

    function readyShow(label) {
        if (data == null) {
            return
        }
        let data_ = data;
        var data1 = undefined
        var data_trans = undefined
        data2 = data_;
        var all_data = {}
        all_data.data = {}
        for (let i in data2) {
            let dataline = data2[i]
            if (dataline.time in all_data.data) { all_data.data[dataline.time].push(dataline) }
            else { all_data.data[dataline.time] = [dataline] }
        }

        var data2 = all_data.data[label]

        var datale0 = []
        var datage0 = []
        for (let v in data2) {
            if (Number(data2[v].influence) > 0) {
                datale0.push(data2[v])
            }
            else if(Number(data2[v].influence) <0) (
                datage0.push(data2[v])
            )
        }

        //datale0 = getRandomArrayElements(datale0, 10)
        //datage0 = getRandomArrayElements(datage0, 15)
        data_ = datage0.concat(datale0)
        data_trans = function (d) {
            return d
        }
        console.log(data_)
        for (var i = 0; i < data_.length; i++) {
            //data_[i].influence = (mode == 1) ? Number(data_[i].influence) : 2 * (Number(data_[i].influence) - 0.5);
            //if(data_)
            data_[i].influence = Number(data_[i].influence) ;
        }
        
        data_ = data_.sort(function (a, b) {
            return b.influence - a.influence;
        });;
        for (var i = 0; i < data_.length; i++) {
            data_[i].num = i;
        }
        //processList(data_)
        //console.log('data',data)
        /*
        
        if(mode==2){
            data = data.slice(0,6).concat(data.slice(data.length-5,data.length))
            console.log(data)
        }*/
        const num_data = data_.length
        const numFontSize = 12;
        const numFontFamily = "Source Han Sans";
        const margin = { top: 3, right: 0, bottom: 0, left: 0 };
        const offsetBetweenBarAndText = 5;
        const max_left_label_width = getTextWidth(formatNumber(data_[data_.length - 1].influence), numFontSize, numFontFamily) + offsetBetweenBarAndText + ((mode == 1) ? 6 : 10)
        const max_right_lebel_width = getTextWidth(formatNumber(data_[0].influence), numFontSize, numFontFamily) + ((mode == 1) ? 170 : 10)
        const width = 425;
        //const every_data_height = 60
        const height = ((mode == 1) ? 650 : 1050) - margin.top;
        //console.log(height)
        const every_data_height = height / num_data
        const imageBarGap = 3
        const imageLabelGap = 3
        d3.select('svg').attr('height', height + margin.top + margin.bottom).attr('width', width + margin.left + margin.right)

        // Create SVG container
        d3.select('#attitude-rank-svg').remove()
        const svg = d3.select("#attitude-rank-chart")
            .append("svg").attr('class', 'svg-download').attr('id', 'attitude-rank-svg')
            .attr("width", "100%")
            .attr("height", height + margin.top + margin.bottom)
            .attr("style", "max-width: 100%; height: auto")
            .attr("viewBox", [0, 0, 425, 1050])
            .append("g")
        // Scale for X-axis
        const xScale = d3.scaleLinear()
            .domain([d3.min(data_, d => d.influence), d3.max(data_, d => d.influence)])
            .range([max_left_label_width, width - max_right_lebel_width]);
        // Scale for Y-axis
        const yScale = d3.scaleBand()
            .domain(data_.map(d => d.num))
            .range([margin.top, height])
        const yScaleBackground = d3.scaleBand()
            .domain(data_.map(d => d.num))
            .range([0, height])
        // Create bars
        let svg_all = svg.selectAll(".bar").data(data_)
            .enter()
        let all_svg = svg_all.append('svg')
        all_svg.append('rect')
            .attr("class", "bk_bar")
            .attr("x", d => 0)
            .attr("y", d => yScale(d.num))
            .attr("width", d => width)
            .attr("height", yScale.bandwidth())
            .attr('num', d => d.num)
            .style("fill", d => (d.num % 2 == 0) ? "#FAFAFA" : "white")
        all_svg.append('rect')
            .attr("class", "bar")
            .attr("x", d => xScale(Math.min(0, data_trans(d.influence))))
            .attr("y", d => yScale(d.num) + yScale.bandwidth() * 0.2)
            .attr("width", d => Math.abs(xScale(0) - xScale(Math.abs(data_trans(d.influence)))))
            .attr("height", yScale.bandwidth() * 0.6)
            .attr('num', d => d.num)
            .style("fill", d => d.influence > 0 ? "#FDB462" : "#DCDCDC")
        all_svg.append('text')
            .text(d => formatNumber(d.influence))
            .attr('y', d => yScale(d.num) + yScale.bandwidth() / 2 + numFontSize / 2 - 2)
            .style('font-size', numFontSize).style('font-family', numFontFamily)
            .attr('x', d => {
                const textWidth = getTextWidth(formatNumber(d.influence), numFontSize, numFontFamily);
                //console.log(textWidth)
                if (d.influence < 0) {
                    return xScale(data_trans(d.influence)) - textWidth - offsetBetweenBarAndText;
                } else {
                    return xScale(data_trans(d.influence)) + offsetBetweenBarAndText;
                }
            });
        let svg_image = all_svg.append('svg').attr('class', 'attitude-rank-svg-img')
        svg_image.append('text').text(d => d.media).attr('id', d => `attitude-rank-svg-img-text-${d.num}`)
            .attr('y', d => yScale(d.num) + yScale.bandwidth() / 2 + numFontSize / 2 - 2)
            .attr('x', d => (d.influence >= 0) ? xScale(0) - getTextWidth(d.media, numFontSize, numFontFamily) - imageLabelGap : imageBarGap + xScale(0)).style('font-family', numFontFamily).style('font-size', numFontSize)
        let svg_img_img = svg_image.append('image')
        svg_img_img.attr('href', d => d.url).attr('id', d => `attitude-rank-svg-img-${d.num}`).style('visibility','hidden')
            .attr('height', yScale.bandwidth() * 0.5).each(function (d) {
                this.onerror = function () {
                    // Handle error here
                    console.log('Image loading failed: ' + d.url);
                }
                this.onload = function () {
                    var node = d3.select(`#attitude-rank-svg-img-${d.num}`);
                    if (!node.node()) { return null }
                    let s1 = xScale(0) - Number(node.node().getBoundingClientRect().width);
                    node.attr('x', d.influence >= 0 ? -imageBarGap + s1 : node.attr('x')).style('visibility', null);
                    let text_node = d3.select(`#attitude-rank-svg-img-text-${d.num}`)
                    text_node.attr('x', d.influence > 0 ? -imageBarGap + s1 - getTextWidth(d.media, numFontSize, numFontFamily) - imageLabelGap : parseFloat(node.attr('x')) + parseFloat(node.style("width")) + imageLabelGap)
                }
            }).attr('y', d => yScale(d.num) + yScale.bandwidth() * 0.25)
            .attr('x', d => {
                const textWidth = getTextWidth(formatNumber(d.influence), numFontSize, numFontFamily);
                return (d.influence >= 0) ? 0 : imageBarGap + xScale(0)
            })
            .on('mouseover', (d, i) => {
                var max_text_width = d3.max([
                    `    精英中文名称：` + i.chinese,
                    `    精英英文名称：` + i.media,
                    `            类型：` + i.type,
                    `            职务：` + i.service,
                    `            党派：` + i.party,
                   // `        影响力：` + i.importance
                ], d => {console.log(getTextWidth(d, 12, numFontFamily),d)
                    return getTextWidth(d, 12, numFontFamily)
                })
                console.log('mtw',max_text_width)
                let element = d3.select(`#attitude-rank-chart`);
                let rect = element.node().getBoundingClientRect();
                console.log(d.pageX, d.pageY, rect.left, rect.top, window.event.x, window.event.y)
                var card = d3.select('#attitude-rank-chart').append('div')
                card.attr('class', 'card-lala').style('box-shadow', '#DEDEDE 0px 0px 10px')
                card.style('position', 'absolute').style('opacity', 1)
                card.style('left', (window.event.x - rect.left -80) + 'px')
                card.style('top', (window.event.y - rect.top + 70) + 'px')
                card.style('background-color', 'white')
                card.style('width', (1.3 * max_text_width +75)+'px').style('border-radius', '5px').style('border-style', 'solid').style(
                    'border-width', '1px').style('border-color', '#DEDEDE')
                card.style('line-height', 0.6)//.style('padding','5px')
                //card.style('height', '130px')
                card.style('font-family', numFontFamily)
                card.style('padding', '3px 30px');
                card.append('p').text('精英中文名称：' + i.chinese).style('font-size', 12 + 'px')
                card.append('p').text('精英英文名称：' + i.media).style('font-size', 12 + 'px')
                card.append('p').html(`&emsp;&emsp;&emsp;&emsp;类型：` + i.type).style('font-size', 12 + 'px')
                card.append('p').html(`&emsp;&emsp;&emsp;&emsp;职务：` + i.service).style('font-size', 12 + 'px')
                card.append('p').html(`&emsp;&emsp;&emsp;&emsp;党派：` + i.party).style('font-size', 12 + 'px')
                //card.append('p').html(`&emsp;&emsp;&emsp;影响力：` + i.importance).style('font-size', 8 + 'px')
                card.append('p').html(`&emsp;&emsp;对华态度：` + String(i.influence).replace(/^(.*\..{4}).*$/, "$1")).style('font-size', 12 + 'px')
            })
            .on('mouseout', function (d) {
                d3.select('.card-lala').remove();
            });
        async function processList(list) {
            try {
                for (let index in list) {
                    const base64 = await convertToBase64(list[index].url); // 获取图片的Base64编码
                    list[index].url = base64
                    //console.log(list[index])
                    // 在这里可以对每个元素的Base64编码进行操作
                }
            } catch (error) {
                console.error(error);
                // 处理错误情况
            }
        }
    }

    useEffect(() => {
        d3.csv('people1026.csv').then(
            function (d) {
                console.log(d)
                setData(d)
            }
        )
    }, [])

    useEffect(() => {
        readyShow(selectedOption)
    }, [data, selectedOption])

    return (
        <Card title={
            <Popover placement="bottom" content={props.description}>
                <div style={{ width: '100%', cursor: 'pointer' }}>
                    {props.title}
                </div>
            </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue='2023年平均'
                    onChange={handleChange}
                    options={options}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
        >
            <div style={{ height:"100%"}}>
                <div id="attitude-rank-chart" style={{ height:"100%",overflowY: "auto", }}></div>
            </div>
        </Card>
    );
}

export default AttitudeRank;