import React from 'react';
import { Row, Col } from 'antd';
import ChartCard from '../../component/ChartCart';
import MediaOverview from '../../charts/MediaOverview';
import MediaBarChart from '../../charts/MediaBarChart';
import MediaAttitude from '../../charts/MediaAttitude';
import MediaKeywordTrend from '../../charts/MediaKeywordTrend';
import MediaPolicits from '../../charts/MediaPolitics';
import MediaTheme from '../../charts/MediaTheme';
import MediaAuthor from '../../charts/MediaAuthor';
import MediaSingleArticle from '../../charts/MediaSingleArticle';
import AuthorOverview from '../../charts/AuthorOverview';
import MediaAttitudeRank from '../../charts/MediaAttitudeRank';

function Media() {
    return (
        <div>
        <Row gutter={10} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
            <ChartCard title='1. 媒体库数据结构与量级概括'>
                <MediaOverview></MediaOverview>
            </ChartCard>
        </Row>
        <Row gutter={10} style={{ height: '92vh', width: '80wh', margin: '10px' }}>
            <MediaBarChart title='2. 主流媒体渉华文章影响力评价算法' description='依据综合报道字数、文章类型、所在版面、涉及主题及涉华主题词频率等多个指标，并采用知识驱动赋权方法的影响力算法计算单一主流媒体涉华文章的影响力排序。'>
            </MediaBarChart>
        </Row>
        <Row gutter={10} style={{ height: '70vh', width: '80wh', margin: '10px' }}>
                <Col span={18} style={{ height: '70vh' }}>
                    <MediaAttitude title='3. 主流媒体对华态度算法' description='运用情感分析算法对每家媒体报道进行情感极性与强度评估，并计算基于年度、月度、周度等不同时间维度的情感变化趋势及全时段情感平均值，同时针对奥巴马、特朗普、及拜登等不同美总统任期进行了阶段性情感评估。'>
                    </MediaAttitude>
                </Col>
                <Col span={6} style={{ height: '70vh' }}>
                    <MediaAttitudeRank title='4. 主流媒体对华态度排名推荐算法' description='基于大规模数据集训练及细粒度情感分析识别、量化并综合评估文本中包含隐含情感的情感极性和强度，并对不同时间段主流媒体对华态度进行排序。'>
                    </MediaAttitudeRank>
                </Col>
        </Row>
        <Row gutter={10} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
            <ChartCard title='5. 主流媒体渉华报道主题分析算法' description='根据主题聚类算法对主权、安全、发展三大领域及其细分子域的警示词监测，统计不同媒体年度报道的篇幅数量及平均情感，观测美国主流媒体涉华报道在不同领域的影响力及态度。'>
                <MediaTheme></MediaTheme>
            </ChartCard>
        </Row>
        <Row gutter={10} style={{ height: '70vh', width: '80wh', margin: '10px' }}>
                <Col span={8} style={{ height: '70vh' }}>
                    <MediaSingleArticle title='6. 主流媒体新闻报道对华态度算法' description='选取单家媒体影响力排名前20的新闻报道，进一步基于深度学习的情感分析方法计算报道的对华态度。'>
                    </MediaSingleArticle>
                </Col>
                <Col span={16} style={{ height: '70vh' }}>
                    <MediaKeywordTrend title='7. 媒体涉华新闻主题词库及涉华关键主题词报道数量分析算法' description='根据涉华核心话题构建主题词库，并使用基于关键词库的文本匹配算法，精准地定位报道中与特定主题相关的内容段落。经初步文本分析后再使用基于深度学习的情感分析以计算不同媒体、不同时间段每个涉华主题的情感极性和强度，以综合评估特定涉华主题的状况与趋势。'>

                    </MediaKeywordTrend>
                </Col>
        </Row>
        <Row gutter={10} style={{ height: '75vh', width: '80wh', margin: '10px' }}>
            <ChartCard title='8. 主流媒体对华政治人物态度分析算法' description='根据时间段准确筛选特定政治人物相关报道，并采用情感分析对其进行深度评估，以计算主流媒体对华政治人物的情感变化趋势。'>
                <MediaPolicits></MediaPolicits>
            </ChartCard>
        </Row>
        <Row gutter={10} style={{ height: '70vh', width: '80wh', margin: '10px' }}>
                <Col span={9} style={{ height: '70vh' }}>
                    <ChartCard title='9. 主流媒体涉华作者影响力排名推荐算法' description='经过对媒体涉华报道作者数据去噪声、规范化等预处理，应用统计学方法计算各主流媒体中涉华报道作者的影响力并进行排名。'>
                        <AuthorOverview></AuthorOverview>
                    </ChartCard>
                </Col>
                <Col span={15} style={{ height: '70vh' }}>
                    <MediaAuthor title='10. 主流媒体涉华作者影响力-对华态度坐标系构建' description='采用情感分析量化涉华作者每篇涉华新闻报道的情感极性和强度，以计算各主流媒体涉华作者的整体情感。使用对华态度影响力算法通过对涉华作者对华态度的累计测量及标准化处理，将涉华作者划分为团结域、支持域、斗争域、风险域四个象限。'></MediaAuthor>
                </Col>
        </Row>
        </div>
    );
}

export default Media;