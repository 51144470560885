import React from 'react';
import { Row} from 'antd';
import ChartCard from '../../component/ChartCart';
import VideoComponent from '../../charts/GPTVideo';
import ImageComponent from '../../charts/CongressDistribution';
import CongressOverview from '../../charts/CongressOverview';
import ThemeRiver from '../../charts/ThemeRiver';
import SearchComponent from '../../charts/SearchBar';
import Chord from '../../charts/Chord';

function Congress() {
    return (
        <div>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='1. 议案库数据结构与量级概况'>
                    <CongressOverview></CongressOverview>
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='2. 涉华法案文本分析模型算法' description='该模块溯源了1980年以来美国涉华法案的领域主题与分布，其中绿色区域代表涉华主权类法案、红色区域代表涉华安全类法案、蓝色区域代表涉华发展类法案。
该模块使用了涉华法案文本分析模型算法。该算法根据美国政府32个官方政策领域建立了包含1082个术语的政策主题词典，再将关键术语映射到涉我核心利益的领域分类中，最后根据不同涉华法案的术语含量识别该法案的对华政策观点、界定其涉华领域。'>
                    <ThemeRiver />
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='3. 法案通过概率预测算法' description='该模块支持对1980年以来美国涉华法案的文本特征搜索与关键信息展示，并可预测特定政策法案的通过概率。
该模块使用法案通过概率预测算法。该算法采用机器学习和特征工程的方法，开发出迄今最精细的基于政治情势的预测方法，从法案政策属性（如关联议题数量、法案行动频率、政策领域总体通过率）、国会权力结构（党派分布、委员会权势等）与议员政治能量（发起人、联署人资历与影响力等）3个维度计算法案的政治势能，并对其进程结果进行实时动态测算。'>
                    <SearchComponent />
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='4. 国会议员社会网络计算与涉华决策子群识别算法' description='该模块展示了各届国会的议员影响力和涉华决策关键子群，红色节点代表众议员、蓝色节点代表参议院，节点越大代表该议员影响力越大，点选议员后高亮的节点代表该议员所属的涉华决策关键子群。
该模块使用了国会议员社会网络计算与决策子群识别算法。相关算法将美国国会建模为由通过联署法案、共同参与委员会等政治行为所构成的社会网络，将每个议员视为网络中的点，将每次涉华法案的发起与决策计为一次行为联系，通过计算每个议员发起涉华法案的可能性来测算其影响力，通过计算议员在涉华决策众的合作频次来识别关键决策子群。'>
                    <Chord />
                </ChartCard>
            </Row>
            <Row gutter={16} style={{ height: '90vh', width: '80wh', margin: '10px' }}>
                <ChartCard title='5. 基于大语言模型的外宣决策支持系统'>
                <VideoComponent />
                </ChartCard>
            </Row>
        </div>
    );
}

export default Congress;