export const topicsLineData=[
    {
        "division": "芝加哥论坛报",
        "date": "2014-01",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-02",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-03",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-04",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-05",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-06",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-07",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-08",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-09",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-10",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-11",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2014-12",
        "emotion": 4
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-01",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-02",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-03",
        "emotion": 3
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-04",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-05",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-06",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-07",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-08",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-09",
        "emotion": 7
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-10",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-11",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2015-12",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-01",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-02",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-03",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-04",
        "emotion": 3
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-05",
        "emotion": 31
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-06",
        "emotion": 8
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-07",
        "emotion": 3
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-08",
        "emotion": 105
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-09",
        "emotion": 71
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-10",
        "emotion": 46
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-11",
        "emotion": 232
    },
    {
        "division": "芝加哥论坛报",
        "date": "2016-12",
        "emotion": 48
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-01",
        "emotion": 24
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-02",
        "emotion": 80
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-03",
        "emotion": 28
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-04",
        "emotion": 33
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-05",
        "emotion": 40
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-06",
        "emotion": 6
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-07",
        "emotion": 22
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-08",
        "emotion": 187
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-09",
        "emotion": 17
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-10",
        "emotion": 43
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-11",
        "emotion": 38
    },
    {
        "division": "芝加哥论坛报",
        "date": "2017-12",
        "emotion": 32
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-01",
        "emotion": 49
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-02",
        "emotion": 16
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-03",
        "emotion": 20
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-04",
        "emotion": 36
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-05",
        "emotion": 15
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-06",
        "emotion": 26
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-07",
        "emotion": 12
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-08",
        "emotion": 119
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-09",
        "emotion": 20
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-10",
        "emotion": 10
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-11",
        "emotion": 25
    },
    {
        "division": "芝加哥论坛报",
        "date": "2018-12",
        "emotion": 4
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-01",
        "emotion": 7
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-02",
        "emotion": 12
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-03",
        "emotion": 26
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-04",
        "emotion": 9
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-05",
        "emotion": 12
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-06",
        "emotion": 13
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-07",
        "emotion": 18
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-08",
        "emotion": 32
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-09",
        "emotion": 3
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-10",
        "emotion": 19
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-11",
        "emotion": 44
    },
    {
        "division": "芝加哥论坛报",
        "date": "2019-12",
        "emotion": 5
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-01",
        "emotion": 2
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-02",
        "emotion": 8
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-03",
        "emotion": 3
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-04",
        "emotion": 8
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-05",
        "emotion": 22
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-06",
        "emotion": 5
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-07",
        "emotion": 16
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-08",
        "emotion": 52
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-09",
        "emotion": 88
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-10",
        "emotion": 65
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-11",
        "emotion": 61
    },
    {
        "division": "芝加哥论坛报",
        "date": "2020-12",
        "emotion": 89
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-01",
        "emotion": 141
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-02",
        "emotion": 89
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-03",
        "emotion": 28
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-04",
        "emotion": 36
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-05",
        "emotion": 6
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-06",
        "emotion": 32
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-07",
        "emotion": 13
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-08",
        "emotion": 19
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-09",
        "emotion": 30
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-10",
        "emotion": 14
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-11",
        "emotion": 43
    },
    {
        "division": "芝加哥论坛报",
        "date": "2021-12",
        "emotion": 14
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-01",
        "emotion": 8
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-02",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-03",
        "emotion": 6
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-04",
        "emotion": 8
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-05",
        "emotion": 34
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-06",
        "emotion": 4
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-07",
        "emotion": 34
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-08",
        "emotion": 2
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-09",
        "emotion": 17
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-10",
        "emotion": 4
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-11",
        "emotion": 8
    },
    {
        "division": "芝加哥论坛报",
        "date": "2022-12",
        "emotion": 52
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-01",
        "emotion": 16
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-02",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-03",
        "emotion": 3
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-04",
        "emotion": 0
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-05",
        "emotion": 1
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-06",
        "emotion": 5
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-07",
        "emotion": 2
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-08-01",
        "emotion": 15
    },
    {
        "division": "芝加哥论坛报",
        "date": "2023-09-01",
        "emotion": 2
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-01",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-02",
        "emotion": 1
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-03",
        "emotion": 1
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-04",
        "emotion": 8
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-05",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-06",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-07",
        "emotion": 1
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-08",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-09",
        "emotion": 4
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-10",
        "emotion": 21
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-11",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2014-12",
        "emotion": 9
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-01",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-02",
        "emotion": 8
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-03",
        "emotion": 3
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-04",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-05",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-06",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-07",
        "emotion": 21
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-08",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-09",
        "emotion": 9
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-10",
        "emotion": 28
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-11",
        "emotion": 2
    },
    {
        "division": "洛杉矶时报",
        "date": "2015-12",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-01",
        "emotion": 6
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-02",
        "emotion": 0
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-03",
        "emotion": 32
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-04",
        "emotion": 4
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-05",
        "emotion": 1
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-06",
        "emotion": 16
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-07",
        "emotion": 8
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-08",
        "emotion": 96
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-09",
        "emotion": 51
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-10",
        "emotion": 53
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-11",
        "emotion": 263
    },
    {
        "division": "洛杉矶时报",
        "date": "2016-12",
        "emotion": 235
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-01",
        "emotion": 59
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-02",
        "emotion": 79
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-03",
        "emotion": 143
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-04",
        "emotion": 139
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-05",
        "emotion": 30
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-06",
        "emotion": 111
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-07",
        "emotion": 13
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-08",
        "emotion": 373
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-09",
        "emotion": 101
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-10",
        "emotion": 44
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-11",
        "emotion": 14
    },
    {
        "division": "洛杉矶时报",
        "date": "2017-12",
        "emotion": 46
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-01",
        "emotion": 41
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-02",
        "emotion": 30
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-03",
        "emotion": 8
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-04",
        "emotion": 22
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-05",
        "emotion": 31
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-06",
        "emotion": 10
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-07",
        "emotion": 78
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-08",
        "emotion": 91
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-09",
        "emotion": 19
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-10",
        "emotion": 85
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-11",
        "emotion": 13
    },
    {
        "division": "洛杉矶时报",
        "date": "2018-12",
        "emotion": 11
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-01",
        "emotion": 3
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-02",
        "emotion": 39
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-03",
        "emotion": 24
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-04",
        "emotion": 28
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-05",
        "emotion": 80
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-06",
        "emotion": 22
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-07",
        "emotion": 16
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-08",
        "emotion": 119
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-09",
        "emotion": 36
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-10",
        "emotion": 26
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-11",
        "emotion": 27
    },
    {
        "division": "洛杉矶时报",
        "date": "2019-12",
        "emotion": 30
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-01",
        "emotion": 11
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-02",
        "emotion": 7
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-03",
        "emotion": 12
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-04",
        "emotion": 1
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-05",
        "emotion": 38
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-06",
        "emotion": 21
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-07",
        "emotion": 67
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-08",
        "emotion": 66
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-09",
        "emotion": 112
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-10",
        "emotion": 327
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-11",
        "emotion": 154
    },
    {
        "division": "洛杉矶时报",
        "date": "2020-12",
        "emotion": 26
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-01",
        "emotion": 314
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-02",
        "emotion": 210
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-03",
        "emotion": 160
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-04",
        "emotion": 40
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-05",
        "emotion": 56
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-06",
        "emotion": 89
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-07",
        "emotion": 7
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-08",
        "emotion": 33
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-09",
        "emotion": 29
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-10",
        "emotion": 22
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-11",
        "emotion": 25
    },
    {
        "division": "洛杉矶时报",
        "date": "2021-12",
        "emotion": 58
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-01",
        "emotion": 34
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-02",
        "emotion": 36
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-03",
        "emotion": 28
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-04",
        "emotion": 66
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-05",
        "emotion": 124
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-06",
        "emotion": 18
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-07",
        "emotion": 43
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-08",
        "emotion": 11
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-09",
        "emotion": 3
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-10",
        "emotion": 122
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-11",
        "emotion": 70
    },
    {
        "division": "洛杉矶时报",
        "date": "2022-12",
        "emotion": 81
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-01",
        "emotion": 29
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-02",
        "emotion": 14
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-03",
        "emotion": 61
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-04",
        "emotion": 28
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-05",
        "emotion": 10
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-06",
        "emotion": 24
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-07",
        "emotion": 26
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-08-01",
        "emotion": 25
    },
    {
        "division": "洛杉矶时报",
        "date": "2023-09-01",
        "emotion": 18
    },
    {
        "division": "华尔街日报",
        "date": "2014-01",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2014-02",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-03",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-04",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-05",
        "emotion": 3
    },
    {
        "division": "华尔街日报",
        "date": "2014-06",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-07",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-08",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-09",
        "emotion": 7
    },
    {
        "division": "华尔街日报",
        "date": "2014-10",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2014-11",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2014-12",
        "emotion": 1
    },
    {
        "division": "华尔街日报",
        "date": "2015-01",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-02",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-03",
        "emotion": 7
    },
    {
        "division": "华尔街日报",
        "date": "2015-04",
        "emotion": 1
    },
    {
        "division": "华尔街日报",
        "date": "2015-05",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2015-06",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-07",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-08",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2015-09",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-10",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-11",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2015-12",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2016-01",
        "emotion": 4
    },
    {
        "division": "华尔街日报",
        "date": "2016-02",
        "emotion": 1
    },
    {
        "division": "华尔街日报",
        "date": "2016-03",
        "emotion": 7
    },
    {
        "division": "华尔街日报",
        "date": "2016-04",
        "emotion": 4
    },
    {
        "division": "华尔街日报",
        "date": "2016-05",
        "emotion": 12
    },
    {
        "division": "华尔街日报",
        "date": "2016-06",
        "emotion": 1
    },
    {
        "division": "华尔街日报",
        "date": "2016-07",
        "emotion": 16
    },
    {
        "division": "华尔街日报",
        "date": "2016-08",
        "emotion": 107
    },
    {
        "division": "华尔街日报",
        "date": "2016-09",
        "emotion": 45
    },
    {
        "division": "华尔街日报",
        "date": "2016-10",
        "emotion": 23
    },
    {
        "division": "华尔街日报",
        "date": "2016-11",
        "emotion": 185
    },
    {
        "division": "华尔街日报",
        "date": "2016-12",
        "emotion": 69
    },
    {
        "division": "华尔街日报",
        "date": "2017-01",
        "emotion": 51
    },
    {
        "division": "华尔街日报",
        "date": "2017-02",
        "emotion": 99
    },
    {
        "division": "华尔街日报",
        "date": "2017-03",
        "emotion": 34
    },
    {
        "division": "华尔街日报",
        "date": "2017-04",
        "emotion": 17
    },
    {
        "division": "华尔街日报",
        "date": "2017-05",
        "emotion": 18
    },
    {
        "division": "华尔街日报",
        "date": "2017-06",
        "emotion": 90
    },
    {
        "division": "华尔街日报",
        "date": "2017-07",
        "emotion": 12
    },
    {
        "division": "华尔街日报",
        "date": "2017-08",
        "emotion": 298
    },
    {
        "division": "华尔街日报",
        "date": "2017-09",
        "emotion": 96
    },
    {
        "division": "华尔街日报",
        "date": "2017-10",
        "emotion": 81
    },
    {
        "division": "华尔街日报",
        "date": "2017-11",
        "emotion": 21
    },
    {
        "division": "华尔街日报",
        "date": "2017-12",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2018-01",
        "emotion": 74
    },
    {
        "division": "华尔街日报",
        "date": "2018-02",
        "emotion": 5
    },
    {
        "division": "华尔街日报",
        "date": "2018-03",
        "emotion": 11
    },
    {
        "division": "华尔街日报",
        "date": "2018-04",
        "emotion": 19
    },
    {
        "division": "华尔街日报",
        "date": "2018-05",
        "emotion": 33
    },
    {
        "division": "华尔街日报",
        "date": "2018-06",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2018-07",
        "emotion": 18
    },
    {
        "division": "华尔街日报",
        "date": "2018-08",
        "emotion": 87
    },
    {
        "division": "华尔街日报",
        "date": "2018-09",
        "emotion": 40
    },
    {
        "division": "华尔街日报",
        "date": "2018-10",
        "emotion": 139
    },
    {
        "division": "华尔街日报",
        "date": "2018-11",
        "emotion": 19
    },
    {
        "division": "华尔街日报",
        "date": "2018-12",
        "emotion": 19
    },
    {
        "division": "华尔街日报",
        "date": "2019-01",
        "emotion": 6
    },
    {
        "division": "华尔街日报",
        "date": "2019-02",
        "emotion": 5
    },
    {
        "division": "华尔街日报",
        "date": "2019-03",
        "emotion": 49
    },
    {
        "division": "华尔街日报",
        "date": "2019-04",
        "emotion": 7
    },
    {
        "division": "华尔街日报",
        "date": "2019-05",
        "emotion": 4
    },
    {
        "division": "华尔街日报",
        "date": "2019-06",
        "emotion": 3
    },
    {
        "division": "华尔街日报",
        "date": "2019-07",
        "emotion": 6
    },
    {
        "division": "华尔街日报",
        "date": "2019-08",
        "emotion": 30
    },
    {
        "division": "华尔街日报",
        "date": "2019-09",
        "emotion": 5
    },
    {
        "division": "华尔街日报",
        "date": "2019-10",
        "emotion": 21
    },
    {
        "division": "华尔街日报",
        "date": "2019-11",
        "emotion": 19
    },
    {
        "division": "华尔街日报",
        "date": "2019-12",
        "emotion": 23
    },
    {
        "division": "华尔街日报",
        "date": "2020-01",
        "emotion": 4
    },
    {
        "division": "华尔街日报",
        "date": "2020-02",
        "emotion": 2
    },
    {
        "division": "华尔街日报",
        "date": "2020-03",
        "emotion": 12
    },
    {
        "division": "华尔街日报",
        "date": "2020-04",
        "emotion": 24
    },
    {
        "division": "华尔街日报",
        "date": "2020-05",
        "emotion": 7
    },
    {
        "division": "华尔街日报",
        "date": "2020-06",
        "emotion": 63
    },
    {
        "division": "华尔街日报",
        "date": "2020-07",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2020-08",
        "emotion": 218
    },
    {
        "division": "华尔街日报",
        "date": "2020-09",
        "emotion": 106
    },
    {
        "division": "华尔街日报",
        "date": "2020-10",
        "emotion": 210
    },
    {
        "division": "华尔街日报",
        "date": "2020-11",
        "emotion": 179
    },
    {
        "division": "华尔街日报",
        "date": "2020-12",
        "emotion": 71
    },
    {
        "division": "华尔街日报",
        "date": "2021-01",
        "emotion": 1236
    },
    {
        "division": "华尔街日报",
        "date": "2021-02",
        "emotion": 550
    },
    {
        "division": "华尔街日报",
        "date": "2021-03",
        "emotion": 172
    },
    {
        "division": "华尔街日报",
        "date": "2021-04",
        "emotion": 77
    },
    {
        "division": "华尔街日报",
        "date": "2021-05",
        "emotion": 130
    },
    {
        "division": "华尔街日报",
        "date": "2021-06",
        "emotion": 80
    },
    {
        "division": "华尔街日报",
        "date": "2021-07",
        "emotion": 8
    },
    {
        "division": "华尔街日报",
        "date": "2021-08",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2021-09",
        "emotion": 25
    },
    {
        "division": "华尔街日报",
        "date": "2021-10",
        "emotion": 58
    },
    {
        "division": "华尔街日报",
        "date": "2021-11",
        "emotion": 40
    },
    {
        "division": "华尔街日报",
        "date": "2021-12",
        "emotion": 11
    },
    {
        "division": "华尔街日报",
        "date": "2022-01",
        "emotion": 14
    },
    {
        "division": "华尔街日报",
        "date": "2022-02",
        "emotion": 4
    },
    {
        "division": "华尔街日报",
        "date": "2022-03",
        "emotion": 9
    },
    {
        "division": "华尔街日报",
        "date": "2022-04",
        "emotion": 20
    },
    {
        "division": "华尔街日报",
        "date": "2022-05",
        "emotion": 10
    },
    {
        "division": "华尔街日报",
        "date": "2022-06",
        "emotion": 0
    },
    {
        "division": "华尔街日报",
        "date": "2022-07",
        "emotion": 18
    },
    {
        "division": "华尔街日报",
        "date": "2022-08",
        "emotion": 9
    },
    {
        "division": "华尔街日报",
        "date": "2022-09",
        "emotion": 41
    },
    {
        "division": "华尔街日报",
        "date": "2022-10",
        "emotion": 64
    },
    {
        "division": "华尔街日报",
        "date": "2022-11",
        "emotion": 17
    },
    {
        "division": "华尔街日报",
        "date": "2022-12",
        "emotion": 123
    },
    {
        "division": "华尔街日报",
        "date": "2023-01",
        "emotion": 11
    },
    {
        "division": "华尔街日报",
        "date": "2023-02",
        "emotion": 3
    },
    {
        "division": "华尔街日报",
        "date": "2023-03",
        "emotion": 22
    },
    {
        "division": "华尔街日报",
        "date": "2023-04",
        "emotion": 21
    },
    {
        "division": "华尔街日报",
        "date": "2023-05",
        "emotion": 12
    },
    {
        "division": "华尔街日报",
        "date": "2023-06",
        "emotion": 15
    },
    {
        "division": "华尔街日报",
        "date": "2023-07",
        "emotion": 31
    },
    {
        "division": "华尔街日报",
        "date": "2023-08-01",
        "emotion": 7
    },
    {
        "division": "华尔街日报",
        "date": "2023-09-01",
        "emotion": 22
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-01",
        "emotion": 2
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-02",
        "emotion": 9
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-03",
        "emotion": 9
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-04",
        "emotion": 1
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-05",
        "emotion": 1
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-06",
        "emotion": 4
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-07",
        "emotion": 3
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-08",
        "emotion": 10
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-09",
        "emotion": 0
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-10",
        "emotion": 10
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-11",
        "emotion": 2
    },
    {
        "division": "华盛顿邮报",
        "date": "2014-12",
        "emotion": 5
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-01",
        "emotion": 1
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-02",
        "emotion": 7
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-03",
        "emotion": 3
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-04",
        "emotion": 9
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-05",
        "emotion": 0
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-06",
        "emotion": 1
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-07",
        "emotion": 14
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-08",
        "emotion": 0
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-09",
        "emotion": 5
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-10",
        "emotion": 0
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-11",
        "emotion": 3
    },
    {
        "division": "华盛顿邮报",
        "date": "2015-12",
        "emotion": 4
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-01",
        "emotion": 55
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-02",
        "emotion": 8
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-03",
        "emotion": 58
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-04",
        "emotion": 5
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-05",
        "emotion": 1
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-06",
        "emotion": 0
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-07",
        "emotion": 5
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-08",
        "emotion": 138
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-09",
        "emotion": 24
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-10",
        "emotion": 45
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-11",
        "emotion": 307
    },
    {
        "division": "华盛顿邮报",
        "date": "2016-12",
        "emotion": 109
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-01",
        "emotion": 116
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-02",
        "emotion": 440
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-03",
        "emotion": 280
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-04",
        "emotion": 237
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-05",
        "emotion": 97
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-06",
        "emotion": 170
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-07",
        "emotion": 41
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-08",
        "emotion": 595
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-09",
        "emotion": 190
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-10",
        "emotion": 143
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-11",
        "emotion": 290
    },
    {
        "division": "华盛顿邮报",
        "date": "2017-12",
        "emotion": 126
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-01",
        "emotion": 288
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-02",
        "emotion": 134
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-03",
        "emotion": 121
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-04",
        "emotion": 116
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-05",
        "emotion": 47
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-06",
        "emotion": 28
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-07",
        "emotion": 112
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-08",
        "emotion": 364
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-09",
        "emotion": 86
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-10",
        "emotion": 154
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-11",
        "emotion": 152
    },
    {
        "division": "华盛顿邮报",
        "date": "2018-12",
        "emotion": 59
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-01",
        "emotion": 129
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-02",
        "emotion": 44
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-03",
        "emotion": 197
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-04",
        "emotion": 52
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-05",
        "emotion": 126
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-06",
        "emotion": 39
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-07",
        "emotion": 119
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-08",
        "emotion": 180
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-09",
        "emotion": 98
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-10",
        "emotion": 102
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-11",
        "emotion": 230
    },
    {
        "division": "华盛顿邮报",
        "date": "2019-12",
        "emotion": 117
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-01",
        "emotion": 67
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-02",
        "emotion": 49
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-03",
        "emotion": 29
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-04",
        "emotion": 28
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-05",
        "emotion": 105
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-06",
        "emotion": 68
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-07",
        "emotion": 205
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-08",
        "emotion": 317
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-09",
        "emotion": 254
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-10",
        "emotion": 706
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-11",
        "emotion": 465
    },
    {
        "division": "华盛顿邮报",
        "date": "2020-12",
        "emotion": 206
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-01",
        "emotion": 1267
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-02",
        "emotion": 498
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-03",
        "emotion": 170
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-04",
        "emotion": 129
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-05",
        "emotion": 207
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-06",
        "emotion": 82
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-07",
        "emotion": 107
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-08",
        "emotion": 78
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-09",
        "emotion": 156
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-10",
        "emotion": 91
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-11",
        "emotion": 347
    },
    {
        "division": "华盛顿邮报",
        "date": "2021-12",
        "emotion": 203
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-01",
        "emotion": 231
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-02",
        "emotion": 52
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-03",
        "emotion": 117
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-04",
        "emotion": 115
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-05",
        "emotion": 96
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-06",
        "emotion": 136
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-07",
        "emotion": 89
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-08",
        "emotion": 121
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-09",
        "emotion": 149
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-10",
        "emotion": 188
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-11",
        "emotion": 126
    },
    {
        "division": "华盛顿邮报",
        "date": "2022-12",
        "emotion": 131
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-01",
        "emotion": 264
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-02",
        "emotion": 63
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-03",
        "emotion": 117
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-04",
        "emotion": 85
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-05",
        "emotion": 47
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-06",
        "emotion": 57
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-07",
        "emotion": 134
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-08-01",
        "emotion": 79
    },
    {
        "division": "华盛顿邮报",
        "date": "2023-09-01",
        "emotion": 33
    },
    {
        "division": "纽约时报",
        "date": "2014-01",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2014-02",
        "emotion": 22
    },
    {
        "division": "纽约时报",
        "date": "2014-03",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2014-04",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2014-05",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2014-06",
        "emotion": 7
    },
    {
        "division": "纽约时报",
        "date": "2014-07",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2014-08",
        "emotion": 6
    },
    {
        "division": "纽约时报",
        "date": "2014-09",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2014-10",
        "emotion": 27
    },
    {
        "division": "纽约时报",
        "date": "2014-11",
        "emotion": 6
    },
    {
        "division": "纽约时报",
        "date": "2014-12",
        "emotion": 3
    },
    {
        "division": "纽约时报",
        "date": "2015-01",
        "emotion": 3
    },
    {
        "division": "纽约时报",
        "date": "2015-02",
        "emotion": 1
    },
    {
        "division": "纽约时报",
        "date": "2015-03",
        "emotion": 5
    },
    {
        "division": "纽约时报",
        "date": "2015-04",
        "emotion": 1
    },
    {
        "division": "纽约时报",
        "date": "2015-05",
        "emotion": 6
    },
    {
        "division": "纽约时报",
        "date": "2015-06",
        "emotion": 20
    },
    {
        "division": "纽约时报",
        "date": "2015-07",
        "emotion": 36
    },
    {
        "division": "纽约时报",
        "date": "2015-08",
        "emotion": 15
    },
    {
        "division": "纽约时报",
        "date": "2015-09",
        "emotion": 3
    },
    {
        "division": "纽约时报",
        "date": "2015-10",
        "emotion": 53
    },
    {
        "division": "纽约时报",
        "date": "2015-11",
        "emotion": 0
    },
    {
        "division": "纽约时报",
        "date": "2015-12",
        "emotion": 4
    },
    {
        "division": "纽约时报",
        "date": "2016-01",
        "emotion": 3
    },
    {
        "division": "纽约时报",
        "date": "2016-02",
        "emotion": 1
    },
    {
        "division": "纽约时报",
        "date": "2016-03",
        "emotion": 27
    },
    {
        "division": "纽约时报",
        "date": "2016-04",
        "emotion": 14
    },
    {
        "division": "纽约时报",
        "date": "2016-05",
        "emotion": 16
    },
    {
        "division": "纽约时报",
        "date": "2016-06",
        "emotion": 48
    },
    {
        "division": "纽约时报",
        "date": "2016-07",
        "emotion": 65
    },
    {
        "division": "纽约时报",
        "date": "2016-08",
        "emotion": 301
    },
    {
        "division": "纽约时报",
        "date": "2016-09",
        "emotion": 102
    },
    {
        "division": "纽约时报",
        "date": "2016-10",
        "emotion": 85
    },
    {
        "division": "纽约时报",
        "date": "2016-11",
        "emotion": 577
    },
    {
        "division": "纽约时报",
        "date": "2016-12",
        "emotion": 340
    },
    {
        "division": "纽约时报",
        "date": "2017-01",
        "emotion": 187
    },
    {
        "division": "纽约时报",
        "date": "2017-02",
        "emotion": 354
    },
    {
        "division": "纽约时报",
        "date": "2017-03",
        "emotion": 243
    },
    {
        "division": "纽约时报",
        "date": "2017-04",
        "emotion": 163
    },
    {
        "division": "纽约时报",
        "date": "2017-05",
        "emotion": 148
    },
    {
        "division": "纽约时报",
        "date": "2017-06",
        "emotion": 174
    },
    {
        "division": "纽约时报",
        "date": "2017-07",
        "emotion": 105
    },
    {
        "division": "纽约时报",
        "date": "2017-08",
        "emotion": 659
    },
    {
        "division": "纽约时报",
        "date": "2017-09",
        "emotion": 103
    },
    {
        "division": "纽约时报",
        "date": "2017-10",
        "emotion": 170
    },
    {
        "division": "纽约时报",
        "date": "2017-11",
        "emotion": 142
    },
    {
        "division": "纽约时报",
        "date": "2017-12",
        "emotion": 118
    },
    {
        "division": "纽约时报",
        "date": "2018-01",
        "emotion": 192
    },
    {
        "division": "纽约时报",
        "date": "2018-02",
        "emotion": 108
    },
    {
        "division": "纽约时报",
        "date": "2018-03",
        "emotion": 80
    },
    {
        "division": "纽约时报",
        "date": "2018-04",
        "emotion": 40
    },
    {
        "division": "纽约时报",
        "date": "2018-05",
        "emotion": 96
    },
    {
        "division": "纽约时报",
        "date": "2018-06",
        "emotion": 127
    },
    {
        "division": "纽约时报",
        "date": "2018-07",
        "emotion": 124
    },
    {
        "division": "纽约时报",
        "date": "2018-08",
        "emotion": 239
    },
    {
        "division": "纽约时报",
        "date": "2018-09",
        "emotion": 161
    },
    {
        "division": "纽约时报",
        "date": "2018-10",
        "emotion": 165
    },
    {
        "division": "纽约时报",
        "date": "2018-11",
        "emotion": 119
    },
    {
        "division": "纽约时报",
        "date": "2018-12",
        "emotion": 106
    },
    {
        "division": "纽约时报",
        "date": "2019-01",
        "emotion": 51
    },
    {
        "division": "纽约时报",
        "date": "2019-02",
        "emotion": 53
    },
    {
        "division": "纽约时报",
        "date": "2019-03",
        "emotion": 182
    },
    {
        "division": "纽约时报",
        "date": "2019-04",
        "emotion": 30
    },
    {
        "division": "纽约时报",
        "date": "2019-05",
        "emotion": 72
    },
    {
        "division": "纽约时报",
        "date": "2019-06",
        "emotion": 107
    },
    {
        "division": "纽约时报",
        "date": "2019-07",
        "emotion": 78
    },
    {
        "division": "纽约时报",
        "date": "2019-08",
        "emotion": 266
    },
    {
        "division": "纽约时报",
        "date": "2019-09",
        "emotion": 62
    },
    {
        "division": "纽约时报",
        "date": "2019-10",
        "emotion": 232
    },
    {
        "division": "纽约时报",
        "date": "2019-11",
        "emotion": 305
    },
    {
        "division": "纽约时报",
        "date": "2019-12",
        "emotion": 158
    },
    {
        "division": "纽约时报",
        "date": "2020-01",
        "emotion": 90
    },
    {
        "division": "纽约时报",
        "date": "2020-02",
        "emotion": 126
    },
    {
        "division": "纽约时报",
        "date": "2020-03",
        "emotion": 73
    },
    {
        "division": "纽约时报",
        "date": "2020-04",
        "emotion": 114
    },
    {
        "division": "纽约时报",
        "date": "2020-05",
        "emotion": 111
    },
    {
        "division": "纽约时报",
        "date": "2020-06",
        "emotion": 141
    },
    {
        "division": "纽约时报",
        "date": "2020-07",
        "emotion": 190
    },
    {
        "division": "纽约时报",
        "date": "2020-08",
        "emotion": 748
    },
    {
        "division": "纽约时报",
        "date": "2020-09",
        "emotion": 593
    },
    {
        "division": "纽约时报",
        "date": "2020-10",
        "emotion": 928
    },
    {
        "division": "纽约时报",
        "date": "2020-11",
        "emotion": 488
    },
    {
        "division": "纽约时报",
        "date": "2020-12",
        "emotion": 170
    },
    {
        "division": "纽约时报",
        "date": "2021-01",
        "emotion": 1630
    },
    {
        "division": "纽约时报",
        "date": "2021-02",
        "emotion": 565
    },
    {
        "division": "纽约时报",
        "date": "2021-03",
        "emotion": 249
    },
    {
        "division": "纽约时报",
        "date": "2021-04",
        "emotion": 258
    },
    {
        "division": "纽约时报",
        "date": "2021-05",
        "emotion": 96
    },
    {
        "division": "纽约时报",
        "date": "2021-06",
        "emotion": 158
    },
    {
        "division": "纽约时报",
        "date": "2021-07",
        "emotion": 73
    },
    {
        "division": "纽约时报",
        "date": "2021-08",
        "emotion": 140
    },
    {
        "division": "纽约时报",
        "date": "2021-09",
        "emotion": 221
    },
    {
        "division": "纽约时报",
        "date": "2021-10",
        "emotion": 184
    },
    {
        "division": "纽约时报",
        "date": "2021-11",
        "emotion": 150
    },
    {
        "division": "纽约时报",
        "date": "2021-12",
        "emotion": 99
    },
    {
        "division": "纽约时报",
        "date": "2022-01",
        "emotion": 183
    },
    {
        "division": "纽约时报",
        "date": "2022-02",
        "emotion": 344
    },
    {
        "division": "纽约时报",
        "date": "2022-03",
        "emotion": 180
    },
    {
        "division": "纽约时报",
        "date": "2022-04",
        "emotion": 228
    },
    {
        "division": "纽约时报",
        "date": "2022-05",
        "emotion": 467
    },
    {
        "division": "纽约时报",
        "date": "2022-06",
        "emotion": 215
    },
    {
        "division": "纽约时报",
        "date": "2022-07",
        "emotion": 320
    },
    {
        "division": "纽约时报",
        "date": "2022-08",
        "emotion": 255
    },
    {
        "division": "纽约时报",
        "date": "2022-09",
        "emotion": 291
    },
    {
        "division": "纽约时报",
        "date": "2022-10",
        "emotion": 521
    },
    {
        "division": "纽约时报",
        "date": "2022-11",
        "emotion": 353
    },
    {
        "division": "纽约时报",
        "date": "2022-12",
        "emotion": 322
    },
    {
        "division": "纽约时报",
        "date": "2023-01",
        "emotion": 172
    },
    {
        "division": "纽约时报",
        "date": "2023-02",
        "emotion": 70
    },
    {
        "division": "纽约时报",
        "date": "2023-03",
        "emotion": 158
    },
    {
        "division": "纽约时报",
        "date": "2023-04",
        "emotion": 105
    },
    {
        "division": "纽约时报",
        "date": "2023-05",
        "emotion": 76
    },
    {
        "division": "纽约时报",
        "date": "2023-06",
        "emotion": 75
    },
    {
        "division": "纽约时报",
        "date": "2023-07",
        "emotion": 88
    },
    {
        "division": "纽约时报",
        "date": "2023-08-01",
        "emotion": 111
    },
    {
        "division": "纽约时报",
        "date": "2023-09-01",
        "emotion": 65
    }
]