import React, { useEffect } from "react";
import * as d3 from "d3";

const MediaPolicits = () => {
    return (
        <div style={{ textAlign: "center" }}>
            <img src="media-05.jpg" style={{width: window.innerWidth * 0.97}}></img>
        </div>
    );
}

export default MediaPolicits