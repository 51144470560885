/**
 * Author: chying
 * github: chenying2929
 * Date: 2023-10-21
 */
import React, { useState } from 'react';
import "./style.css"

const TopicsOverview = () => {
    const [topicsData, setTopicsData] = useState([
        { id: 1, imageSrc: './topicsOverviewPic/overview1.png', title: '右翼民粹' },
        { id: 2, imageSrc: './topicsOverviewPic/overview2.png', title: '种族冲突' },
        { id: 3, imageSrc: './topicsOverviewPic/overview3.png', title: '移民群体' },
        { id: 4, imageSrc: './topicsOverviewPic/overview4.png', title: '枪支暴力' },
        { id: 5, imageSrc: './topicsOverviewPic/overview5.png', title: '性别平权' },
        { id: 6, imageSrc: './topicsOverviewPic/overview6.png', title: '劳工运动' },
      ]);
  

    return(
        <div className='topic-overview-box'>
            {topicsData.map((item)=>(
                <div className='topic-overview-card'>
                    <div className='topic-overview-card-img'>
                        <img className='topic-overview-card-img-png' src={item.imageSrc}></img>
                    </div>
                    <div className='topic-overview-card-title'>
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    )

}

export default TopicsOverview

