import React from 'react';
import video from './video.m4v'
import { Player,BigPlayButton  } from 'video-react';
import 'video-react/dist/video-react.css';

function HomePage() {
    return (
        <div>
            <img src='01.png' style={{width: "100%"}}></img>
            <img src='02.png' style={{width: "100%"}}></img>
            <img src='03.png' style={{width: "100%"}}></img>
            <Player width="100%" >
                <source src={video} />
                <BigPlayButton position="center" />
            </Player>
        </div>
    );
}

export default HomePage;