import React, { Component } from 'react'; 
import './styles.css'

class ThinkList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            tmpData: [],
            activeButton: null, 
        };
    }

    componentDidMount() {
        // Fetch data when the component mounts
        this.fetchData();
    }

    fetchData() {
        fetch('think/ThinkList.json')
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    data: json,
                    tmpData: json["FPIA"],
                    activeButton: 'FPIA', // Initialize with the default active button
                }, () => {
                    this.drawTable(); // Call drawTable after setting the state
                });
                console.log(json["FPIA"]);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }
    

    handleButtonClick = (buttonId) => {
        const { data } = this.state;
        if (data) {
            const tmpData = data[buttonId];
            this.setState({ tmpData, activeButton: buttonId }, () => {
                this.drawTable();
            });
        }
    };    
    

    drawTable() {
        this.clearTable();
        const { tmpData } = this.state;
        console.log(tmpData)
        const table = document.querySelector('.table-container table');
        if (table) {
            tmpData.forEach((item) => {
                const row = document.createElement('tr');
                const rankCell = document.createElement('td');
                const nameCell = document.createElement('td');
                rankCell.textContent = `No.${item.rank}`;
                nameCell.textContent = item.name;
                rankCell.classList.add('center-first-button');
                nameCell.classList.add('center-second-and-third-buttons');
                row.appendChild(rankCell);
                row.appendChild(nameCell);
                table.appendChild(row);
            });
        }
    }

    clearTable() {
        const table = document.querySelector('.table-container table');
        if (table) {
            while (table.firstChild) {
                table.removeChild(table.firstChild);
            }
        }
    }

    render() {
        return (
            <div className="container" id="container">
                <div className="btn-container">
                    <div className="button-group">
                    <button
                        className={this.state.activeButton === 'FPIA' ? 'active' : ''}
                        id="FPIA"
                        onClick={() => this.handleButtonClick('FPIA')}
                    >
                        外交政策与国际事务
                    </button>
                    <button
                        className={this.state.activeButton === 'IEP' ? 'active' : ''}
                        id="IEP"
                        onClick={() => this.handleButtonClick('IEP')}
                    >
                        国际经济政策
                    </button>
                    <button
                        className={this.state.activeButton === 'IDP' ? 'active' : ''}
                        id="IDP"
                        onClick={() => this.handleButtonClick('IDP')}
                    >
                        国际发展政策
                    </button>

                    </div>
                </div>
                <div className="table-container">
                    <table></table>
                </div>
            </div>
        );
    }
}

export default ThinkList;
