import './App.css';
import { Layout, Select, Space } from 'antd'
import { useState } from 'react';
import Cream from './pages/cream';
import Media from './pages/media';
import Think from './pages/think';
import Congress from './pages/congress';
import Topic from "./pages/topics"
import Metaphor from "./pages/metaphor"
import Case from './pages/case';
import Case2 from './pages/case2';
import Case3 from './pages/case3';
import { DownOutlined } from '@ant-design/icons'
import HomePage from './pages/homepage';

const { Header, Content } = Layout;

function App() {
  const [library, setLibrary] = useState('0');
  const items = [
    {
      label: '首页',
      value: '0'
    },
    {
      label: '修辞库',
      value: '1',
    },
    {
      label: '议案库',
      value: '2',
    },
    {
      label: '精英库',
      value: '3',
    },
    {
      label: '智库库',
      value: '4',
    },
    {
      label: '媒体库',
      value: '5',
    },
    {
      label: '议题库',
      value: '6',
    },
    {
      label: '案例实验（一）',
      value: '7',
    },
    {
      label: '案例实验（二）',
      value: '8',
    },
    {
      label: '案例实验（三）',
      value: '9',
    }
  ];
  const handlePageChange = (value) => {
    setLibrary(value)
    window.scrollTo(0, 0)
  }
  return (
    <Layout className="App">
      < DownOutlined fill={'white'} height={'20px'} width={'20px'} style={{ fontSize: '20px', position: 'absolute', top: '10px', left: '90px', height: '10px', width: '10px', display: 'none' }} Component={{ height: '10px', width: '10px' }} />
      <Header style={{ position: 'sticky', top: 0, zIndex: 1, display: 'flex', alignItems: 'center', backgroundColor: '#D9D9D9' }}>
        <Space wrap>
          <Select
            size='large'
            defaultValue={'0'}
            options={items}
            onChange={handlePageChange}
            style={{ width: 160}}
            maxTagCount={9}
          >
          </Select>
        </Space>
      </Header>
      <Content>
        {library === '0' ? <HomePage /> : null}
        {library === '1' ? <Metaphor /> : null}
        {library === '2' ? <Congress /> : null}
        {library === '3' ? <Cream /> : null}
        {library === '4' ? <Think /> : null}
        {library === '5' ? <Media /> : null}
        {library === '6' ? <Topic /> : null}
        {library === '7' ? <Case /> : null}
        {library === '8' ? <Case2 /> : null}
        {library === '9' ? <Case3 /> : null}
      </Content>
    </Layout>
  );
}

export default App;
