import React, { useEffect } from 'react';
import { init } from 'echarts';

const ChordRep = () => {
    useEffect(() => {
    const dom = document.getElementById('container');
    const myChart = init(dom, null, {
        renderer: 'canvas',
        useDirtyRect: false,
    });
    const app = {};
    const ROOT_PATH = './';

    const graph = {
        links: [{"id": "0",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "493",
        "value": 1
    },
    {
        "id": "1",
        "lineStyle": {
            "normal": {
                "width": 4.5
            }
        },
        "source": "384",
        "target": "476",
        "value": 7
    },
    {
        "id": "2",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "149",
        "target": "476",
        "value": 3
    },
    {
        "id": "3",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "431",
        "target": "419",
        "value": 1
    },
    {
        "id": "4",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "137",
        "target": "401",
        "value": 1
    },
    {
        "id": "5",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "468",
        "target": "137",
        "value": 3
    },
    {
        "id": "6",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "137",
        "value": 1
    },
    {
        "id": "7",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "104",
        "target": "175",
        "value": 2
    },
    {
        "id": "8",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "384",
        "target": "104",
        "value": 4
    },
    {
        "id": "9",
        "lineStyle": {
            "normal": {
                "width": 3.5
            }
        },
        "source": "265",
        "target": "104",
        "value": 5
    },
    {
        "id": "10",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "104",
        "target": "514",
        "value": 3
    },
    {
        "id": "11",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "63",
        "target": "104",
        "value": 3
    },
    {
        "id": "12",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "104",
        "target": "523",
        "value": 3
    },
    {
        "id": "13",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "104",
        "target": "403",
        "value": 2
    },
    {
        "id": "14",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "104",
        "target": "476",
        "value": 3
    },
    {
        "id": "15",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "104",
        "target": "516",
        "value": 2
    },
    {
        "id": "16",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "104",
        "target": "491",
        "value": 2
    },
    {
        "id": "17",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "104",
        "target": "260",
        "value": 2
    },
    {
        "id": "18",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "356",
        "target": "104",
        "value": 3
    },
    {
        "id": "19",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "1",
        "target": "104",
        "value": 2
    },
    {
        "id": "20",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "265",
        "target": "476",
        "value": 4
    },
    {
        "id": "21",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "141",
        "target": "265",
        "value": 3
    },
    {
        "id": "22",
        "lineStyle": {
            "normal": {
                "width": 4.5
            }
        },
        "source": "28",
        "target": "493",
        "value": 7
    },
    {
        "id": "23",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "487",
        "target": "419",
        "value": 3
    },
    {
        "id": "24",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "468",
        "target": "487",
        "value": 2
    },
    {
        "id": "25",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "487",
        "target": "434",
        "value": 1
    },
    {
        "id": "26",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "356",
        "target": "476",
        "value": 2
    },
    {
        "id": "27",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "390",
        "value": 1
    },
    {
        "id": "28",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "401",
        "value": 1
    },
    {
        "id": "29",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "155",
        "value": 1
    },
    {
        "id": "30",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "114",
        "target": "390",
        "value": 2
    },
    {
        "id": "31",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "303",
        "target": "390",
        "value": 2
    },
    {
        "id": "32",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "452",
        "value": 1
    },
    {
        "id": "33",
        "lineStyle": {
            "normal": {
                "width": 3.5
            }
        },
        "source": "491",
        "target": "476",
        "value": 5
    },
    {
        "id": "34",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "493",
        "target": "516",
        "value": 4
    },
    {
        "id": "35",
        "lineStyle": {
            "normal": {
                "width": 5.5
            }
        },
        "source": "384",
        "target": "493",
        "value": 9
    },
    {
        "id": "36",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "260",
        "target": "493",
        "value": 2
    },
    {
        "id": "37",
        "lineStyle": {
            "normal": {
                "width": 7
            }
        },
        "source": "493",
        "target": "476",
        "value": 12
    },
    {
        "id": "38",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "404",
        "target": "155",
        "value": 1
    },
    {
        "id": "39",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "404",
        "target": "434",
        "value": 2
    },
    {
        "id": "40",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "471",
        "target": "404",
        "value": 3
    },
    {
        "id": "41",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "38",
        "target": "434",
        "value": 3
    },
    {
        "id": "42",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "280",
        "target": "260",
        "value": 3
    },
    {
        "id": "43",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "280",
        "target": "384",
        "value": 3
    },
    {
        "id": "44",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "280",
        "target": "149",
        "value": 2
    },
    {
        "id": "45",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "158",
        "target": "419",
        "value": 3
    },
    {
        "id": "46",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "411",
        "target": "419",
        "value": 2
    },
    {
        "id": "47",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "321",
        "target": "419",
        "value": 3
    },
    {
        "id": "48",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "137",
        "target": "419",
        "value": 1
    },
    {
        "id": "49",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "101",
        "target": "419",
        "value": 3
    },
    {
        "id": "50",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "434",
        "target": "419",
        "value": 2
    },
    {
        "id": "51",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "419",
        "target": "401",
        "value": 1
    },
    {
        "id": "52",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "471",
        "target": "419",
        "value": 1
    },
    {
        "id": "53",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "468",
        "target": "404",
        "value": 1
    },
    {
        "id": "54",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "404",
        "value": 1
    },
    {
        "id": "55",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "404",
        "value": 1
    },
    {
        "id": "56",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "163",
        "target": "169",
        "value": 3
    },
    {
        "id": "57",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "356",
        "target": "493",
        "value": 2
    },
    {
        "id": "58",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "523",
        "target": "403",
        "value": 1
    },
    {
        "id": "59",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "321",
        "target": "158",
        "value": 3
    },
    {
        "id": "60",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "137",
        "target": "158",
        "value": 1
    },
    {
        "id": "61",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "158",
        "target": "155",
        "value": 2
    },
    {
        "id": "62",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "433",
        "target": "158",
        "value": 1
    },
    {
        "id": "63",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "403",
        "target": "493",
        "value": 3
    },
    {
        "id": "64",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "514",
        "target": "493",
        "value": 2
    },
    {
        "id": "65",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "431",
        "value": 1
    },
    {
        "id": "66",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "368",
        "target": "402",
        "value": 1
    },
    {
        "id": "67",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "104",
        "target": "493",
        "value": 3
    },
    {
        "id": "68",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "141",
        "target": "104",
        "value": 2
    },
    {
        "id": "69",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "414",
        "target": "493",
        "value": 3
    },
    {
        "id": "70",
        "lineStyle": {
            "normal": {
                "width": 4
            }
        },
        "source": "17",
        "target": "448",
        "value": 6
    },
    {
        "id": "71",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "57",
        "target": "17",
        "value": 4
    },
    {
        "id": "72",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "17",
        "target": "493",
        "value": 3
    },
    {
        "id": "73",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "414",
        "target": "17",
        "value": 4
    },
    {
        "id": "74",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "216",
        "target": "155",
        "value": 3
    },
    {
        "id": "75",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "149",
        "target": "448",
        "value": 3
    },
    {
        "id": "76",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "104",
        "target": "448",
        "value": 2
    },
    {
        "id": "77",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "448",
        "target": "493",
        "value": 4
    },
    {
        "id": "78",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "448",
        "target": "516",
        "value": 1
    },
    {
        "id": "79",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "280",
        "target": "448",
        "value": 3
    },
    {
        "id": "80",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "63",
        "target": "448",
        "value": 2
    },
    {
        "id": "81",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "141",
        "target": "491",
        "value": 1
    },
    {
        "id": "82",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "28",
        "target": "384",
        "value": 1
    },
    {
        "id": "83",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "414",
        "target": "486",
        "value": 1
    },
    {
        "id": "84",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "229",
        "target": "486",
        "value": 1
    },
    {
        "id": "85",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "373",
        "target": "486",
        "value": 3
    },
    {
        "id": "86",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "163",
        "target": "155",
        "value": 1
    },
    {
        "id": "87",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "155",
        "target": "367",
        "value": 3
    },
    {
        "id": "88",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "347",
        "target": "155",
        "value": 1
    },
    {
        "id": "89",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "471",
        "target": "155",
        "value": 1
    },
    {
        "id": "90",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "155",
        "value": 1
    },
    {
        "id": "91",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "368",
        "target": "155",
        "value": 2
    },
    {
        "id": "92",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "321",
        "target": "155",
        "value": 1
    },
    {
        "id": "93",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "155",
        "target": "38",
        "value": 1
    },
    {
        "id": "94",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "411",
        "target": "155",
        "value": 2
    },
    {
        "id": "95",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "155",
        "target": "110",
        "value": 1
    },
    {
        "id": "96",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "169",
        "target": "155",
        "value": 2
    },
    {
        "id": "97",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "155",
        "target": "434",
        "value": 2
    },
    {
        "id": "98",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "132",
        "target": "390",
        "value": 3
    },
    {
        "id": "99",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "102",
        "target": "390",
        "value": 2
    },
    {
        "id": "100",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "125",
        "value": 1
    },
    {
        "id": "101",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "376",
        "value": 1
    },
    {
        "id": "102",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "188",
        "target": "155",
        "value": 3
    },
    {
        "id": "103",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "188",
        "target": "169",
        "value": 1
    },
    {
        "id": "104",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "319",
        "target": "401",
        "value": 1
    },
    {
        "id": "105",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "471",
        "target": "401",
        "value": 1
    },
    {
        "id": "106",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "363",
        "target": "401",
        "value": 3
    },
    {
        "id": "107",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "401",
        "value": 1
    },
    {
        "id": "108",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "441",
        "target": "401",
        "value": 1
    },
    {
        "id": "109",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "286",
        "target": "401",
        "value": 1
    },
    {
        "id": "110",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "401",
        "value": 1
    },
    {
        "id": "111",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "163",
        "target": "401",
        "value": 1
    },
    {
        "id": "112",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "376",
        "target": "401",
        "value": 1
    },
    {
        "id": "113",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "402",
        "target": "401",
        "value": 1
    },
    {
        "id": "114",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "205",
        "target": "401",
        "value": 1
    },
    {
        "id": "115",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "61",
        "target": "401",
        "value": 1
    },
    {
        "id": "116",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "149",
        "target": "493",
        "value": 2
    },
    {
        "id": "117",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "491",
        "target": "493",
        "value": 1
    },
    {
        "id": "118",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "63",
        "target": "493",
        "value": 2
    },
    {
        "id": "119",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "63",
        "target": "149",
        "value": 1
    },
    {
        "id": "120",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "104",
        "target": "149",
        "value": 1
    },
    {
        "id": "121",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "28",
        "target": "104",
        "value": 1
    },
    {
        "id": "122",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "370",
        "target": "172",
        "value": 3
    },
    {
        "id": "123",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "172",
        "value": 1
    },
    {
        "id": "124",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "496",
        "target": "172",
        "value": 3
    },
    {
        "id": "125",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "182",
        "target": "172",
        "value": 3
    },
    {
        "id": "126",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "375",
        "target": "172",
        "value": 3
    },
    {
        "id": "127",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "15",
        "target": "172",
        "value": 3
    },
    {
        "id": "128",
        "lineStyle": {
            "normal": {
                "width": 3.5
            }
        },
        "source": "90",
        "target": "172",
        "value": 5
    },
    {
        "id": "129",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "319",
        "target": "172",
        "value": 3
    },
    {
        "id": "130",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "365",
        "target": "172",
        "value": 3
    },
    {
        "id": "131",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "451",
        "target": "172",
        "value": 3
    },
    {
        "id": "132",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "188",
        "target": "172",
        "value": 3
    },
    {
        "id": "133",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "286",
        "target": "172",
        "value": 3
    },
    {
        "id": "134",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "61",
        "target": "172",
        "value": 3
    },
    {
        "id": "135",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "501",
        "target": "172",
        "value": 3
    },
    {
        "id": "136",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "477",
        "target": "172",
        "value": 3
    },
    {
        "id": "137",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "303",
        "target": "172",
        "value": 3
    },
    {
        "id": "138",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "150",
        "target": "172",
        "value": 3
    },
    {
        "id": "139",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "205",
        "target": "172",
        "value": 3
    },
    {
        "id": "140",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "172",
        "value": 1
    },
    {
        "id": "141",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "402",
        "target": "172",
        "value": 3
    },
    {
        "id": "142",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "102",
        "target": "172",
        "value": 4
    },
    {
        "id": "143",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "136",
        "target": "172",
        "value": 3
    },
    {
        "id": "144",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "172",
        "value": 1
    },
    {
        "id": "145",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "200",
        "value": 3
    },
    {
        "id": "146",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "170",
        "value": 3
    },
    {
        "id": "147",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "114",
        "target": "172",
        "value": 3
    },
    {
        "id": "148",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "274",
        "value": 3
    },
    {
        "id": "149",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "441",
        "value": 3
    },
    {
        "id": "150",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "452",
        "value": 3
    },
    {
        "id": "151",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "439",
        "value": 3
    },
    {
        "id": "152",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "211",
        "value": 3
    },
    {
        "id": "153",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "172",
        "target": "125",
        "value": 4
    },
    {
        "id": "154",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "124",
        "value": 3
    },
    {
        "id": "155",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "324",
        "value": 3
    },
    {
        "id": "156",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "376",
        "value": 3
    },
    {
        "id": "157",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "484",
        "value": 3
    },
    {
        "id": "158",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "386",
        "value": 3
    },
    {
        "id": "159",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "82",
        "value": 3
    },
    {
        "id": "160",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "39",
        "value": 3
    },
    {
        "id": "161",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "172",
        "target": "291",
        "value": 3
    },
    {
        "id": "162",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "265",
        "target": "493",
        "value": 3
    },
    {
        "id": "163",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "433",
        "target": "163",
        "value": 2
    },
    {
        "id": "164",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "216",
        "target": "367",
        "value": 1
    },
    {
        "id": "165",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "367",
        "value": 1
    },
    {
        "id": "166",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "90",
        "target": "125",
        "value": 3
    },
    {
        "id": "167",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "370",
        "target": "90",
        "value": 2
    },
    {
        "id": "168",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "132",
        "target": "90",
        "value": 1
    },
    {
        "id": "169",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "324",
        "value": 2
    },
    {
        "id": "170",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "170",
        "value": 2
    },
    {
        "id": "171",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "61",
        "value": 2
    },
    {
        "id": "172",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "451",
        "value": 2
    },
    {
        "id": "173",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "189",
        "target": "90",
        "value": 2
    },
    {
        "id": "174",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "477",
        "value": 1
    },
    {
        "id": "175",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "496",
        "target": "90",
        "value": 2
    },
    {
        "id": "176",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "441",
        "value": 2
    },
    {
        "id": "177",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "114",
        "target": "90",
        "value": 2
    },
    {
        "id": "178",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "136",
        "value": 1
    },
    {
        "id": "179",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "376",
        "value": 2
    },
    {
        "id": "180",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "365",
        "value": 1
    },
    {
        "id": "181",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "150",
        "value": 1
    },
    {
        "id": "182",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "39",
        "value": 2
    },
    {
        "id": "183",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "303",
        "value": 2
    },
    {
        "id": "184",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "182",
        "target": "90",
        "value": 1
    },
    {
        "id": "185",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "90",
        "target": "102",
        "value": 3
    },
    {
        "id": "186",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "205",
        "value": 2
    },
    {
        "id": "187",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "15",
        "target": "90",
        "value": 2
    },
    {
        "id": "188",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "375",
        "target": "90",
        "value": 1
    },
    {
        "id": "189",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "402",
        "value": 1
    },
    {
        "id": "190",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "291",
        "value": 1
    },
    {
        "id": "191",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "188",
        "value": 1
    },
    {
        "id": "192",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "229",
        "target": "448",
        "value": 2
    },
    {
        "id": "193",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "149",
        "target": "414",
        "value": 2
    },
    {
        "id": "194",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "384",
        "target": "149",
        "value": 1
    },
    {
        "id": "195",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "149",
        "target": "276",
        "value": 1
    },
    {
        "id": "196",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "383",
        "target": "149",
        "value": 1
    },
    {
        "id": "197",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "155",
        "value": 1
    },
    {
        "id": "198",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "286",
        "target": "155",
        "value": 1
    },
    {
        "id": "199",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "155",
        "target": "484",
        "value": 1
    },
    {
        "id": "200",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "155",
        "target": "401",
        "value": 1
    },
    {
        "id": "201",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "155",
        "value": 1
    },
    {
        "id": "202",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "439",
        "target": "155",
        "value": 1
    },
    {
        "id": "203",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "61",
        "target": "379",
        "value": 1
    },
    {
        "id": "204",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "379",
        "target": "169",
        "value": 3
    },
    {
        "id": "205",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "403",
        "target": "476",
        "value": 1
    },
    {
        "id": "206",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "157",
        "target": "192",
        "value": 1
    },
    {
        "id": "207",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "383",
        "target": "157",
        "value": 1
    },
    {
        "id": "208",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "163",
        "target": "386",
        "value": 2
    },
    {
        "id": "209",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "28",
        "target": "403",
        "value": 1
    },
    {
        "id": "210",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "189",
        "target": "379",
        "value": 4
    },
    {
        "id": "211",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "375",
        "value": 1
    },
    {
        "id": "212",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "189",
        "value": 1
    },
    {
        "id": "213",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "205",
        "value": 1
    },
    {
        "id": "214",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "189",
        "target": "319",
        "value": 3
    },
    {
        "id": "215",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "286",
        "value": 1
    },
    {
        "id": "216",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "373",
        "target": "175",
        "value": 3
    },
    {
        "id": "217",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "63",
        "target": "373",
        "value": 1
    },
    {
        "id": "218",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "163",
        "target": "156",
        "value": 1
    },
    {
        "id": "219",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "487",
        "target": "156",
        "value": 1
    },
    {
        "id": "220",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "433",
        "target": "156",
        "value": 4
    },
    {
        "id": "221",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "523",
        "target": "476",
        "value": 1
    },
    {
        "id": "222",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "141",
        "target": "476",
        "value": 1
    },
    {
        "id": "223",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "411",
        "target": "110",
        "value": 3
    },
    {
        "id": "224",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "411",
        "target": "347",
        "value": 3
    },
    {
        "id": "225",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "280",
        "target": "175",
        "value": 2
    },
    {
        "id": "226",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "280",
        "target": "63",
        "value": 1
    },
    {
        "id": "227",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "280",
        "target": "1",
        "value": 3
    },
    {
        "id": "228",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "280",
        "target": "141",
        "value": 2
    },
    {
        "id": "229",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "280",
        "target": "514",
        "value": 2
    },
    {
        "id": "230",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "280",
        "target": "493",
        "value": 3
    },
    {
        "id": "231",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "280",
        "target": "476",
        "value": 2
    },
    {
        "id": "232",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "384",
        "target": "141",
        "value": 1
    },
    {
        "id": "233",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "141",
        "target": "523",
        "value": 1
    },
    {
        "id": "234",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "280",
        "target": "28",
        "value": 1
    },
    {
        "id": "235",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "280",
        "target": "491",
        "value": 1
    },
    {
        "id": "236",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "280",
        "target": "265",
        "value": 2
    },
    {
        "id": "237",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "280",
        "target": "356",
        "value": 1
    },
    {
        "id": "238",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "280",
        "target": "403",
        "value": 1
    },
    {
        "id": "239",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "523",
        "target": "493",
        "value": 2
    },
    {
        "id": "240",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "220",
        "target": "516",
        "value": 1
    },
    {
        "id": "241",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "363",
        "target": "330",
        "value": 3
    },
    {
        "id": "242",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "319",
        "target": "379",
        "value": 3
    },
    {
        "id": "243",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "379",
        "target": "155",
        "value": 2
    },
    {
        "id": "244",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "431",
        "value": 1
    },
    {
        "id": "245",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "163",
        "target": "379",
        "value": 2
    },
    {
        "id": "246",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "433",
        "target": "189",
        "value": 1
    },
    {
        "id": "247",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "363",
        "target": "431",
        "value": 4
    },
    {
        "id": "248",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "137",
        "target": "110",
        "value": 1
    },
    {
        "id": "249",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "434",
        "target": "110",
        "value": 1
    },
    {
        "id": "250",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "290",
        "target": "493",
        "value": 2
    },
    {
        "id": "251",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "265",
        "target": "448",
        "value": 1
    },
    {
        "id": "252",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "265",
        "target": "356",
        "value": 1
    },
    {
        "id": "253",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "265",
        "target": "149",
        "value": 2
    },
    {
        "id": "254",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "265",
        "target": "514",
        "value": 1
    },
    {
        "id": "255",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "163",
        "value": 1
    },
    {
        "id": "256",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "476",
        "target": "175",
        "value": 1
    },
    {
        "id": "257",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "368",
        "target": "367",
        "value": 4
    },
    {
        "id": "258",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "28",
        "target": "476",
        "value": 2
    },
    {
        "id": "259",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "373",
        "target": "476",
        "value": 1
    },
    {
        "id": "260",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "290",
        "target": "448",
        "value": 1
    },
    {
        "id": "261",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "28",
        "target": "448",
        "value": 1
    },
    {
        "id": "262",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "414",
        "target": "448",
        "value": 1
    },
    {
        "id": "263",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "220",
        "target": "448",
        "value": 1
    },
    {
        "id": "264",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "356",
        "target": "448",
        "value": 1
    },
    {
        "id": "265",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "448",
        "value": 1
    },
    {
        "id": "266",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "448",
        "target": "486",
        "value": 1
    },
    {
        "id": "267",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "448",
        "target": "175",
        "value": 1
    },
    {
        "id": "268",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "448",
        "target": "192",
        "value": 1
    },
    {
        "id": "269",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "384",
        "target": "448",
        "value": 1
    },
    {
        "id": "270",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "514",
        "target": "448",
        "value": 1
    },
    {
        "id": "271",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "491",
        "target": "448",
        "value": 1
    },
    {
        "id": "272",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "448",
        "value": 1
    },
    {
        "id": "273",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "448",
        "target": "476",
        "value": 2
    },
    {
        "id": "274",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "220",
        "target": "493",
        "value": 1
    },
    {
        "id": "275",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "379",
        "value": 1
    },
    {
        "id": "276",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "376",
        "value": 1
    },
    {
        "id": "277",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "291",
        "value": 1
    },
    {
        "id": "278",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "401",
        "value": 1
    },
    {
        "id": "279",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "286",
        "target": "379",
        "value": 1
    },
    {
        "id": "280",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "15",
        "target": "379",
        "value": 2
    },
    {
        "id": "281",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "402",
        "value": 1
    },
    {
        "id": "282",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "432",
        "target": "294",
        "value": 3
    },
    {
        "id": "283",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "294",
        "value": 1
    },
    {
        "id": "284",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "220",
        "target": "294",
        "value": 3
    },
    {
        "id": "285",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "178",
        "target": "294",
        "value": 3
    },
    {
        "id": "286",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "294",
        "target": "414",
        "value": 1
    },
    {
        "id": "287",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "294",
        "target": "157",
        "value": 4
    },
    {
        "id": "288",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "294",
        "target": "381",
        "value": 1
    },
    {
        "id": "289",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "294",
        "target": "17",
        "value": 2
    },
    {
        "id": "290",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "294",
        "target": "290",
        "value": 1
    },
    {
        "id": "291",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "294",
        "target": "160",
        "value": 3
    },
    {
        "id": "292",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "294",
        "target": "276",
        "value": 3
    },
    {
        "id": "293",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "294",
        "target": "192",
        "value": 3
    },
    {
        "id": "294",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "294",
        "target": "421",
        "value": 3
    },
    {
        "id": "295",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "383",
        "target": "294",
        "value": 3
    },
    {
        "id": "296",
        "lineStyle": {
            "normal": {
                "width": 3
            }
        },
        "source": "149",
        "target": "17",
        "value": 4
    },
    {
        "id": "297",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "229",
        "target": "17",
        "value": 3
    },
    {
        "id": "298",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "90",
        "target": "211",
        "value": 2
    },
    {
        "id": "299",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "484",
        "value": 1
    },
    {
        "id": "300",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "82",
        "value": 1
    },
    {
        "id": "301",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "319",
        "value": 1
    },
    {
        "id": "302",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "468",
        "target": "419",
        "value": 1
    },
    {
        "id": "303",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "347",
        "target": "419",
        "value": 1
    },
    {
        "id": "304",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "38",
        "target": "419",
        "value": 1
    },
    {
        "id": "305",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "487",
        "target": "401",
        "value": 1
    },
    {
        "id": "306",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "137",
        "target": "487",
        "value": 1
    },
    {
        "id": "307",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "216",
        "target": "487",
        "value": 1
    },
    {
        "id": "308",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "487",
        "value": 1
    },
    {
        "id": "309",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "487",
        "value": 1
    },
    {
        "id": "310",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "487",
        "target": "321",
        "value": 1
    },
    {
        "id": "311",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "487",
        "target": "158",
        "value": 1
    },
    {
        "id": "312",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "451",
        "target": "102",
        "value": 1
    },
    {
        "id": "313",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "496",
        "target": "102",
        "value": 1
    },
    {
        "id": "314",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "102",
        "value": 1
    },
    {
        "id": "315",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "477",
        "target": "102",
        "value": 1
    },
    {
        "id": "316",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "274",
        "value": 1
    },
    {
        "id": "317",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "132",
        "target": "102",
        "value": 2
    },
    {
        "id": "318",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "291",
        "value": 1
    },
    {
        "id": "319",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "330",
        "value": 1
    },
    {
        "id": "320",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "375",
        "target": "102",
        "value": 1
    },
    {
        "id": "321",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "124",
        "value": 1
    },
    {
        "id": "322",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "303",
        "target": "102",
        "value": 1
    },
    {
        "id": "323",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "205",
        "target": "102",
        "value": 1
    },
    {
        "id": "324",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "386",
        "value": 1
    },
    {
        "id": "325",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "484",
        "value": 1
    },
    {
        "id": "326",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "182",
        "target": "102",
        "value": 1
    },
    {
        "id": "327",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "439",
        "value": 1
    },
    {
        "id": "328",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "125",
        "value": 1
    },
    {
        "id": "329",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "102",
        "value": 1
    },
    {
        "id": "330",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "39",
        "value": 1
    },
    {
        "id": "331",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "188",
        "target": "102",
        "value": 1
    },
    {
        "id": "332",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "441",
        "value": 1
    },
    {
        "id": "333",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "452",
        "value": 1
    },
    {
        "id": "334",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "150",
        "target": "102",
        "value": 1
    },
    {
        "id": "335",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "82",
        "value": 1
    },
    {
        "id": "336",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "324",
        "value": 1
    },
    {
        "id": "337",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "61",
        "target": "102",
        "value": 1
    },
    {
        "id": "338",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "466",
        "target": "102",
        "value": 3
    },
    {
        "id": "339",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "286",
        "target": "169",
        "value": 1
    },
    {
        "id": "340",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "169",
        "value": 1
    },
    {
        "id": "341",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "367",
        "value": 1
    },
    {
        "id": "342",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "404",
        "value": 1
    },
    {
        "id": "343",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "158",
        "value": 1
    },
    {
        "id": "344",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "439",
        "value": 1
    },
    {
        "id": "345",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "363",
        "value": 1
    },
    {
        "id": "346",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "468",
        "target": "321",
        "value": 1
    },
    {
        "id": "347",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "276",
        "target": "493",
        "value": 1
    },
    {
        "id": "348",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "466",
        "target": "172",
        "value": 3
    },
    {
        "id": "349",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "471",
        "target": "101",
        "value": 1
    },
    {
        "id": "350",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "110",
        "value": 1
    },
    {
        "id": "351",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "17",
        "target": "290",
        "value": 3
    },
    {
        "id": "352",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "17",
        "target": "486",
        "value": 2
    },
    {
        "id": "353",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "63",
        "target": "17",
        "value": 1
    },
    {
        "id": "354",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "169",
        "target": "363",
        "value": 2
    },
    {
        "id": "355",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "466",
        "target": "439",
        "value": 2
    },
    {
        "id": "356",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "466",
        "value": 1
    },
    {
        "id": "357",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "375",
        "target": "466",
        "value": 2
    },
    {
        "id": "358",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "90",
        "target": "466",
        "value": 1
    },
    {
        "id": "359",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "452",
        "value": 1
    },
    {
        "id": "360",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "370",
        "target": "466",
        "value": 1
    },
    {
        "id": "361",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "291",
        "value": 1
    },
    {
        "id": "362",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "466",
        "target": "125",
        "value": 2
    },
    {
        "id": "363",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "451",
        "target": "466",
        "value": 1
    },
    {
        "id": "364",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "324",
        "value": 1
    },
    {
        "id": "365",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "15",
        "target": "466",
        "value": 1
    },
    {
        "id": "366",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "132",
        "target": "466",
        "value": 1
    },
    {
        "id": "367",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "182",
        "target": "466",
        "value": 1
    },
    {
        "id": "368",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "466",
        "target": "211",
        "value": 2
    },
    {
        "id": "369",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "501",
        "value": 1
    },
    {
        "id": "370",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "376",
        "value": 1
    },
    {
        "id": "371",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "466",
        "target": "150",
        "value": 1
    },
    {
        "id": "372",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "368",
        "target": "150",
        "value": 1
    },
    {
        "id": "373",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "150",
        "target": "441",
        "value": 1
    },
    {
        "id": "374",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "149",
        "target": "175",
        "value": 1
    },
    {
        "id": "375",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "220",
        "target": "17",
        "value": 1
    },
    {
        "id": "376",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "381",
        "target": "17",
        "value": 1
    },
    {
        "id": "377",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "432",
        "target": "17",
        "value": 3
    },
    {
        "id": "378",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "383",
        "target": "17",
        "value": 2
    },
    {
        "id": "379",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "17",
        "target": "421",
        "value": 1
    },
    {
        "id": "380",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "17",
        "target": "160",
        "value": 1
    },
    {
        "id": "381",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "17",
        "target": "276",
        "value": 2
    },
    {
        "id": "382",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "157",
        "target": "17",
        "value": 1
    },
    {
        "id": "383",
        "lineStyle": {
            "normal": {
                "width": 2.5
            }
        },
        "source": "381",
        "target": "290",
        "value": 3
    },
    {
        "id": "384",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "319",
        "target": "155",
        "value": 1
    },
    {
        "id": "385",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "175",
        "target": "516",
        "value": 1
    },
    {
        "id": "386",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "229",
        "target": "175",
        "value": 1
    },
    {
        "id": "387",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "290",
        "target": "175",
        "value": 1
    },
    {
        "id": "388",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "476",
        "target": "486",
        "value": 1
    },
    {
        "id": "389",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "381",
        "target": "421",
        "value": 1
    },
    {
        "id": "390",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "178",
        "target": "403",
        "value": 1
    },
    {
        "id": "391",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "383",
        "target": "178",
        "value": 1
    },
    {
        "id": "392",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "523",
        "target": "178",
        "value": 1
    },
    {
        "id": "393",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "125",
        "value": 1
    },
    {
        "id": "394",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "125",
        "target": "82",
        "value": 1
    },
    {
        "id": "395",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "125",
        "target": "39",
        "value": 1
    },
    {
        "id": "396",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "163",
        "target": "431",
        "value": 1
    },
    {
        "id": "397",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "375",
        "target": "431",
        "value": 1
    },
    {
        "id": "398",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "57",
        "target": "414",
        "value": 2
    },
    {
        "id": "399",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "260",
        "target": "160",
        "value": 1
    },
    {
        "id": "400",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "414",
        "target": "290",
        "value": 2
    },
    {
        "id": "401",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "432",
        "target": "290",
        "value": 2
    },
    {
        "id": "402",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "290",
        "target": "160",
        "value": 1
    },
    {
        "id": "403",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "57",
        "target": "290",
        "value": 2
    },
    {
        "id": "404",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "229",
        "target": "265",
        "value": 1
    },
    {
        "id": "405",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "63",
        "target": "265",
        "value": 1
    },
    {
        "id": "406",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "265",
        "target": "290",
        "value": 1
    },
    {
        "id": "407",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "28",
        "target": "265",
        "value": 1
    },
    {
        "id": "408",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "265",
        "value": 1
    },
    {
        "id": "409",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "220",
        "target": "265",
        "value": 1
    },
    {
        "id": "410",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "384",
        "target": "265",
        "value": 1
    },
    {
        "id": "411",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "265",
        "target": "414",
        "value": 1
    },
    {
        "id": "412",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "220",
        "target": "383",
        "value": 1
    },
    {
        "id": "413",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "82",
        "value": 1
    },
    {
        "id": "414",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "370",
        "target": "211",
        "value": 1
    },
    {
        "id": "415",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "496",
        "target": "211",
        "value": 1
    },
    {
        "id": "416",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "114",
        "target": "211",
        "value": 1
    },
    {
        "id": "417",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "452",
        "target": "211",
        "value": 1
    },
    {
        "id": "418",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "451",
        "target": "211",
        "value": 1
    },
    {
        "id": "419",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "441",
        "target": "211",
        "value": 1
    },
    {
        "id": "420",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "291",
        "value": 1
    },
    {
        "id": "421",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "39",
        "value": 1
    },
    {
        "id": "422",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "170",
        "target": "211",
        "value": 1
    },
    {
        "id": "423",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "132",
        "target": "211",
        "value": 1
    },
    {
        "id": "424",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "477",
        "target": "211",
        "value": 1
    },
    {
        "id": "425",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "439",
        "target": "211",
        "value": 1
    },
    {
        "id": "426",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "102",
        "target": "211",
        "value": 1
    },
    {
        "id": "427",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "390",
        "target": "211",
        "value": 1
    },
    {
        "id": "428",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "375",
        "target": "211",
        "value": 1
    },
    {
        "id": "429",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "200",
        "target": "211",
        "value": 1
    },
    {
        "id": "430",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "136",
        "target": "211",
        "value": 1
    },
    {
        "id": "431",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "150",
        "target": "211",
        "value": 1
    },
    {
        "id": "432",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "484",
        "value": 1
    },
    {
        "id": "433",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "324",
        "value": 1
    },
    {
        "id": "434",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "376",
        "value": 1
    },
    {
        "id": "435",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "365",
        "target": "211",
        "value": 1
    },
    {
        "id": "436",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "303",
        "target": "211",
        "value": 1
    },
    {
        "id": "437",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "124",
        "target": "211",
        "value": 1
    },
    {
        "id": "438",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "211",
        "target": "386",
        "value": 1
    },
    {
        "id": "439",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "319",
        "target": "211",
        "value": 1
    },
    {
        "id": "440",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "61",
        "target": "211",
        "value": 1
    },
    {
        "id": "441",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "384",
        "target": "491",
        "value": 1
    },
    {
        "id": "442",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "491",
        "target": "403",
        "value": 1
    },
    {
        "id": "443",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "381",
        "target": "486",
        "value": 1
    },
    {
        "id": "444",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "493",
        "target": "486",
        "value": 1
    },
    {
        "id": "445",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "433",
        "target": "169",
        "value": 1
    },
    {
        "id": "446",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "149",
        "target": "290",
        "value": 1
    },
    {
        "id": "447",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "63",
        "target": "290",
        "value": 1
    },
    {
        "id": "448",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "290",
        "target": "486",
        "value": 1
    },
    {
        "id": "449",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "384",
        "target": "403",
        "value": 1
    },
    {
        "id": "450",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "433",
        "target": "319",
        "value": 2
    },
    {
        "id": "451",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "319",
        "target": "431",
        "value": 1
    },
    {
        "id": "452",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "319",
        "target": "169",
        "value": 1
    },
    {
        "id": "453",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "368",
        "target": "286",
        "value": 1
    },
    {
        "id": "454",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "368",
        "target": "441",
        "value": 2
    },
    {
        "id": "455",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "229",
        "target": "493",
        "value": 1
    },
    {
        "id": "456",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "216",
        "target": "411",
        "value": 1
    },
    {
        "id": "457",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "216",
        "target": "347",
        "value": 1
    },
    {
        "id": "458",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "216",
        "target": "169",
        "value": 1
    },
    {
        "id": "459",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "216",
        "target": "471",
        "value": 1
    },
    {
        "id": "460",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "330",
        "value": 1
    },
    {
        "id": "461",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "102",
        "value": 1
    },
    {
        "id": "462",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "381",
        "target": "493",
        "value": 1
    },
    {
        "id": "463",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "17",
        "target": "373",
        "value": 1
    },
    {
        "id": "464",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "17",
        "target": "476",
        "value": 1
    },
    {
        "id": "465",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "17",
        "target": "516",
        "value": 1
    },
    {
        "id": "466",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "17",
        "target": "175",
        "value": 1
    },
    {
        "id": "467",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "229",
        "value": 1
    },
    {
        "id": "468",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "381",
        "value": 1
    },
    {
        "id": "469",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "160",
        "value": 1
    },
    {
        "id": "470",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "57",
        "value": 1
    },
    {
        "id": "471",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "421",
        "value": 1
    },
    {
        "id": "472",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "15",
        "target": "368",
        "value": 1
    },
    {
        "id": "473",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "368",
        "target": "169",
        "value": 1
    },
    {
        "id": "474",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "319",
        "target": "363",
        "value": 2
    },
    {
        "id": "475",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "319",
        "target": "330",
        "value": 1
    },
    {
        "id": "476",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "404",
        "value": 1
    },
    {
        "id": "477",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "486",
        "value": 1
    },
    {
        "id": "478",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "178",
        "value": 1
    },
    {
        "id": "479",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "160",
        "value": 1
    },
    {
        "id": "480",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "57",
        "target": "229",
        "value": 1
    },
    {
        "id": "481",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "491",
        "target": "157",
        "value": 1
    },
    {
        "id": "482",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "157",
        "value": 1
    },
    {
        "id": "483",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "321",
        "value": 1
    },
    {
        "id": "484",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "411",
        "target": "156",
        "value": 1
    },
    {
        "id": "485",
        "lineStyle": {
            "normal": {
                "width": 2
            }
        },
        "source": "379",
        "target": "156",
        "value": 2
    },
    {
        "id": "486",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "260",
        "target": "373",
        "value": 1
    },
    {
        "id": "487",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "280",
        "target": "373",
        "value": 1
    },
    {
        "id": "488",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "169",
        "target": "156",
        "value": 1
    },
    {
        "id": "489",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "432",
        "target": "1",
        "value": 1
    },
    {
        "id": "490",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "448",
        "value": 1
    },
    {
        "id": "491",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "57",
        "value": 1
    },
    {
        "id": "492",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "260",
        "value": 1
    },
    {
        "id": "493",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "229",
        "value": 1
    },
    {
        "id": "494",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "178",
        "value": 1
    },
    {
        "id": "495",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "356",
        "value": 1
    },
    {
        "id": "496",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "1",
        "target": "516",
        "value": 1
    },
    {
        "id": "497",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "155",
        "value": 1
    },
    {
        "id": "498",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "367",
        "value": 1
    },
    {
        "id": "499",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "38",
        "value": 1
    },
    {
        "id": "500",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "286",
        "target": "363",
        "value": 1
    },
    {
        "id": "501",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "379",
        "target": "363",
        "value": 1
    },
    {
        "id": "502",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "172",
        "value": 1
    },
    {
        "id": "503",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "189",
        "target": "363",
        "value": 1
    },
    {
        "id": "504",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "376",
        "value": 1
    },
    {
        "id": "505",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "150",
        "target": "363",
        "value": 1
    },
    {
        "id": "506",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "101",
        "target": "363",
        "value": 1
    },
    {
        "id": "507",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "484",
        "value": 1
    },
    {
        "id": "508",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "205",
        "target": "363",
        "value": 1
    },
    {
        "id": "509",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "402",
        "target": "363",
        "value": 1
    },
    {
        "id": "510",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "291",
        "value": 1
    },
    {
        "id": "511",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "404",
        "value": 1
    },
    {
        "id": "512",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "386",
        "value": 1
    },
    {
        "id": "513",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "441",
        "value": 1
    },
    {
        "id": "514",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "451",
        "target": "363",
        "value": 1
    },
    {
        "id": "515",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "156",
        "target": "363",
        "value": 1
    },
    {
        "id": "516",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "163",
        "target": "363",
        "value": 1
    },
    {
        "id": "517",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "419",
        "value": 1
    },
    {
        "id": "518",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "188",
        "target": "363",
        "value": 1
    },
    {
        "id": "519",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "434",
        "value": 1
    },
    {
        "id": "520",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "61",
        "target": "363",
        "value": 1
    },
    {
        "id": "521",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "363",
        "target": "439",
        "value": 1
    },
    {
        "id": "522",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "229",
        "target": "294",
        "value": 1
    },
    {
        "id": "523",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "523",
        "target": "17",
        "value": 1
    },
    {
        "id": "524",
        "lineStyle": {
            "normal": {
                "width": 1.5
            }
        },
        "source": "178",
        "target": "17",
        "value": 1
    }],
        nodes: [{
        "attributes": {
            "modularity_class": 1
        },
        "id": "211",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "S\u00e1nchez, Linda T.",
        "symbolSize": 38.9594,
        "url": "https://www.congress.gov/member/linda-snchez/S001156",
        "value": 19.4797
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "1",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Capito, Shelley Moore",
        "symbolSize": 22.3246,
        "url": "https://www.congress.gov/member/shelley-capito/C001047",
        "value": 11.1623
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "330",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Meeks, Gregory W.",
        "symbolSize": 24.6118,
        "url": "https://www.congress.gov/member/gregory-meeks/M001137",
        "value": 12.3059
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "401",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Wilson, Joe",
        "symbolSize": 29.7506,
        "url": "https://www.congress.gov/member/joe-wilson/W000795",
        "value": 14.8753
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "274",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Omar, Ilhan",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/ilhan-omar/O000173",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "141",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Budd, Ted",
        "symbolSize": 20.8278,
        "url": "https://www.congress.gov/member/ted-budd/B001305",
        "value": 10.4139
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "276",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Rosen, Jacky",
        "symbolSize": 20.655,
        "url": "https://www.congress.gov/member/jacklyn-rosen/R000608",
        "value": 10.3275
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "402",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "美中战略竞争委员会",
        "name": "Krishnamoorthi, Raja",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/raja-krishnamoorthi/K000391",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "403",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Marshall, Roger",
        "symbolSize": 20.6592,
        "url": "https://www.congress.gov/member/roger-marshall/M001198",
        "value": 10.3296
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "404",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Pfluger, August",
        "symbolSize": 28.1464,
        "url": "https://www.congress.gov/member/august-pfluger/P000048",
        "value": 14.0732
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "216",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Bice, Stephanie I.",
        "symbolSize": 25.985,
        "url": "https://www.congress.gov/member/stephanie-bice/B000740",
        "value": 12.9925
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "82",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Velazquez, Nydia M.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/nydia-velazquez/V000081",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "280",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Barrasso, John",
        "symbolSize": 23.1388,
        "url": "https://www.congress.gov/member/john-barrasso/B001261",
        "value": 11.5694
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "411",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Donalds, Byron",
        "symbolSize": 24.1186,
        "url": "https://www.congress.gov/member/byron-donalds/D000032",
        "value": 12.0593
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "466",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Grijalva, Ra\u00fal M.",
        "symbolSize": 31.435200000000002,
        "url": "https://www.congress.gov/member/ral-grijalva/G000551",
        "value": 15.717600000000001
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "220",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Cortez Masto, Catherine",
        "symbolSize": 20.3366,
        "url": "https://www.congress.gov/member/catherine-cortez-masto/C001113",
        "value": 10.1683
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "286",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Gottheimer, Josh",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/josh-gottheimer/G000583",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "414",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Kaine, Tim",
        "symbolSize": 20.5498,
        "url": "https://www.congress.gov/member/timothy-kaine/K000384",
        "value": 10.2749
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "468",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Biggs, Andy",
        "symbolSize": 20.8032,
        "url": "https://www.congress.gov/member/andy-biggs/B001302",
        "value": 10.4016
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "290",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Merkley, Jeff",
        "symbolSize": 21.0024,
        "url": "https://www.congress.gov/member/jeff-merkley/M001176",
        "value": 10.5012
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "291",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Williams, Nikema",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/nikema-williams/W000788",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "15",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Case, Ed",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/ed-case/C001055",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "294",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Hirono, Mazie K.",
        "symbolSize": 24.0086,
        "url": "https://www.congress.gov/member/mazie-hirono/H001042",
        "value": 12.0043
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "90",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Chu, Judy",
        "symbolSize": 32.379400000000004,
        "url": "https://www.congress.gov/member/judy-chu/C001080",
        "value": 16.189700000000002
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "17",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Menendez, Robert",
        "symbolSize": 25.6306,
        "url": "https://www.congress.gov/member/robert-menendez/M000639",
        "value": 12.8153
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "229",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Coons, Christopher A.",
        "symbolSize": 21.3722,
        "url": "https://www.congress.gov/member/christopher-coons/C001088",
        "value": 10.6861
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "471",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Ellzey, Jake",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/jake-ellzey/E000071",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "347",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Nehls, Troy E.",
        "symbolSize": 20.272,
        "url": "https://www.congress.gov/member/troy-nehls/N000026",
        "value": 10.136
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "419",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Tiffany, Thomas P.",
        "symbolSize": 27.916800000000002,
        "url": "https://www.congress.gov/member/thomas-tiffany/T000165",
        "value": 13.958400000000001
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "421",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Warren, Elizabeth",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/elizabeth-warren/W000817",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "149",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Hagerty, Bill",
        "symbolSize": 21.6164,
        "url": "https://www.congress.gov/member/bill-hagerty/H000601",
        "value": 10.8082
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "476",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Scott, Rick",
        "symbolSize": 24.5166,
        "url": "https://www.congress.gov/member/rick-scott/S001217",
        "value": 12.2583
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "477",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Jacobs, Sara",
        "symbolSize": 20.4204,
        "url": "https://www.congress.gov/member/sara-jacobs/J000305",
        "value": 10.2102
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "150",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Kilmer, Derek",
        "symbolSize": 21.8436,
        "url": "https://www.congress.gov/member/derek-kilmer/K000381",
        "value": 10.9218
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "484",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Trone, David J.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/david-trone/T000483",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "101",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Gooden, Lance",
        "symbolSize": 21.1048,
        "url": "https://www.congress.gov/member/lance-gooden/G000589",
        "value": 10.5524
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "102",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Lee, Barbara",
        "symbolSize": 40.384,
        "url": "https://www.congress.gov/member/barbara-lee/L000551",
        "value": 20.192
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "486",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Van Hollen, Chris",
        "symbolSize": 21.2524,
        "url": "https://www.congress.gov/member/chris-van-hollen/V000128",
        "value": 10.6262
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "104",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Cruz, Ted",
        "symbolSize": 24.3142,
        "url": "https://www.congress.gov/member/ted-cruz/C001098",
        "value": 12.1571
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "487",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Lesko, Debbie",
        "symbolSize": 32.5654,
        "url": "https://www.congress.gov/member/debbie-lesko/L000589",
        "value": 16.2827
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "155",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Salazar, Maria Elvira",
        "symbolSize": 62.4704,
        "url": "https://www.congress.gov/member/maria-salazar/S000168",
        "value": 31.2352
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "356",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Cramer, Kevin",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/kevin-cramer/C001096",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "28",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Blackburn, Marsha",
        "symbolSize": 21.0146,
        "url": "https://www.congress.gov/member/marsha-blackburn/B001243",
        "value": 10.5073
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "158",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Perry, Scott",
        "symbolSize": 24.7598,
        "url": "https://www.congress.gov/member/scott-perry/P000605",
        "value": 12.3799
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "156",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "美中战略竞争委员会",
        "name": "Luetkemeyer, Blaine",
        "symbolSize": 24.7952,
        "url": "https://www.congress.gov/member/blaine-luetkemeyer/L000569",
        "value": 12.3976
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "491",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Hawley, Josh",
        "symbolSize": 20.8004,
        "url": "https://www.congress.gov/member/josh-hawley/H001089",
        "value": 10.4002
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "157",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Klobuchar, Amy",
        "symbolSize": 22.3544,
        "url": "https://www.congress.gov/member/amy-klobuchar/K000367",
        "value": 11.1772
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "431",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Smith, Christopher H.",
        "symbolSize": 29.5958,
        "url": "https://www.congress.gov/member/christopher-smith/S000522",
        "value": 14.7979
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "493",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Rubio, Marco",
        "symbolSize": 25.4938,
        "url": "https://www.congress.gov/member/marco-rubio/R000595",
        "value": 12.7469
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "432",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Booker, Cory A.",
        "symbolSize": 21.6042,
        "url": "https://www.congress.gov/member/cory-booker/B001288",
        "value": 10.8021
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "303",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Jayapal, Pramila",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/pramila-jayapal/J000298",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "110",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Williams, Brandon",
        "symbolSize": 22.7588,
        "url": "https://www.congress.gov/member/brandon-williams/W000828",
        "value": 11.3794
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "160",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Padilla, Alex",
        "symbolSize": 20.2538,
        "url": "https://www.congress.gov/member/alex-padilla/P000145",
        "value": 10.1269
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "433",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "美中战略竞争委员会",
        "name": "Barr, Andy",
        "symbolSize": 23.628,
        "url": "https://www.congress.gov/member/andy-barr/B001282",
        "value": 11.814
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "163",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Hill, J. French",
        "symbolSize": 22.014400000000002,
        "url": "https://www.congress.gov/member/j-hill/H001072",
        "value": 11.007200000000001
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "434",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Steube, W. Gregory",
        "symbolSize": 20.3288,
        "url": "https://www.congress.gov/member/w-steube/S001214",
        "value": 10.1644
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "496",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Bonamici, Suzanne",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/suzanne-bonamici/B001278",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "169",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Lawler, Michael",
        "symbolSize": 30.0068,
        "url": "https://www.congress.gov/member/michael-lawler/L000599",
        "value": 15.0034
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "170",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Napolitano, Grace F.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/grace-napolitano/N000179",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "363",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "McCaul, Michael T.",
        "symbolSize": 64.3972,
        "url": "https://www.congress.gov/member/michael-mccaul/M001157",
        "value": 33.1986
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "172",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Meng, Grace",
        "symbolSize": 40.114000000000004,
        "url": "https://www.congress.gov/member/grace-meng/M001188",
        "value": 20.057000000000002
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "365",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Davis, Danny K.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/danny-davis/D000096",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "38",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Self, Keith",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/keith-self/S001224",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "501",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Jackson Lee, Sheila",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/sheila-jackson-lee/J000032",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "114",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Del. Norton, Eleanor Holmes",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/eleanor-norton/N000147",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "39",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Watson Coleman, Bonnie",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/bonnie-watson-coleman/W000822",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "175",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": "Sullivan, Dan",
        "symbolSize": 20.896,
        "url": "https://www.congress.gov/member/dan-sullivan/S001198",
        "value": 10.448
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "367",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Stefanik, Elise M.",
        "symbolSize": 31.146,
        "url": "https://www.congress.gov/member/elise-stefanik/S001196",
        "value": 15.573
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "178",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Gillibrand, Kirsten E.",
        "symbolSize": 20.398,
        "url": "https://www.congress.gov/member/kirsten-gillibrand/G000555",
        "value": 10.199
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "368",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "美中战略竞争委员会",
        "name": "Gallagher, Mike",
        "symbolSize": 32.4356,
        "url": "https://www.congress.gov/member/mike-gallagher/G000579",
        "value": 17.2178
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "439",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Porter, Katie",
        "symbolSize": 21.2908,
        "url": "https://www.congress.gov/member/katie-porter/P000618",
        "value": 10.6454
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "370",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Barragan, Nanette Diaz",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/nanette-barragan/B001300",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "441",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Panetta, Jimmy",
        "symbolSize": 20.4294,
        "url": "https://www.congress.gov/member/jimmy-panetta/P000613",
        "value": 10.2147
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "182",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Bowman, Jamaal",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/jamaal-bowman/B001223",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "373",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Romney, Mitt",
        "symbolSize": 22.5752,
        "url": "https://www.congress.gov/member/mitt-romney/R000615",
        "value": 11.2876
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "375",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Carson, Andre",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/andre-carson/C001072",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "376",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Tokuda, Jill N.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/jill-tokuda/T000487",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "188",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Espaillat, Adriano",
        "symbolSize": 22.8886,
        "url": "https://www.congress.gov/member/adriano-espaillat/E000297",
        "value": 11.4443
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "189",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Bera, Ami",
        "symbolSize": 24.5332,
        "url": "https://www.congress.gov/member/ami-bera/B001287",
        "value": 12.2666
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "379",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Kim, Young",
        "symbolSize": 36.0398,
        "url": "https://www.congress.gov/member/young-kim/K000397",
        "value": 19.0199
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "192",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Smith, Tina",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/tina-smith/S001203",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "57",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Cardin, Benjamin L.",
        "symbolSize": 20.923000000000002,
        "url": "https://www.congress.gov/member/ben-cardin/C000141",
        "value": 10.461500000000001
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "381",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Markey, Edward J.",
        "symbolSize": 20.9644,
        "url": "https://www.congress.gov/member/edward-markey/M000133",
        "value": 10.4822
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "514",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Hoeven, John",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/john-hoeven/H001061",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "448",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Risch, James E.",
        "symbolSize": 26.7802,
        "url": "https://www.congress.gov/member/james-risch/R000584",
        "value": 13.3901
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "383",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Feinstein, Dianne",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/dianne-feinstein/F000062",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "516",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Wicker, Roger F.",
        "symbolSize": 20.274,
        "url": "https://www.congress.gov/member/roger-wicker/W000437",
        "value": 10.137
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "384",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Braun, Mike",
        "symbolSize": 21.451,
        "url": "https://www.congress.gov/member/mike-braun/B001310",
        "value": 10.7255
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "260",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Lummis, Cynthia M.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/cynthia-lummis/L000571",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "61",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Green, Al",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/al-green/G000553",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "124",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Strickland, Marilyn",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/marilyn-strickland/S001159",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "125",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Schakowsky, Janice D.",
        "symbolSize": 24.4074,
        "url": "https://www.congress.gov/member/janice-schakowsky/S001145",
        "value": 12.2037
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "386",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Vargas, Juan",
        "symbolSize": 20.3938,
        "url": "https://www.congress.gov/member/juan-vargas/V000130",
        "value": 10.1969
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "63",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Cornyn, John",
        "symbolSize": 21.582,
        "url": "https://www.congress.gov/member/john-cornyn/C001056",
        "value": 10.791
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "319",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Connolly, Gerald E.",
        "symbolSize": 32.9374,
        "url": "https://www.congress.gov/member/gerald-connolly/C001078",
        "value": 16.4687
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "523",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Ernst, Joni",
        "symbolSize": 20.4012,
        "url": "https://www.congress.gov/member/joni-ernst/E000295",
        "value": 10.2006
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "451",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "DelBene, Suzan K.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/suzan-delbene/D000617",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "452",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Pocan, Mark",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/mark-pocan/P000607",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "390",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "McGovern, James P.",
        "symbolSize": 31.622999999999998,
        "url": "https://www.congress.gov/member/james-mcgovern/M000312",
        "value": 15.811499999999999
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "321",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Ogles, Andrew",
        "symbolSize": 22.241,
        "url": "https://www.congress.gov/member/andrew-ogles/O000175",
        "value": 11.1205
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "200",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Nadler, Jerrold",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/jerrold-nadler/N000002",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 0
        },
        "id": "265",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(0,0,255)",
                "color": "rgb(90,90,255)"
            }
        },
        "category": "参议院",
        "name": " Cotton, Tom",
        "symbolSize": 24.413,
        "url": "https://www.congress.gov/member/tom-cotton/C001095",
        "value": 12.2065
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "132",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Blumenauer, Earl",
        "symbolSize": 23.0606,
        "url": "https://www.congress.gov/member/earl-blumenauer/B000574",
        "value": 11.5303
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "324",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Takano, Mark",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/mark-takano/T000472",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "205",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "美中战略竞争委员会",
        "name": "Kim, Andy",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/andy-kim/K000394",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "136",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Matsui, Doris O.",
        "symbolSize": 20.2382,
        "url": "https://www.congress.gov/member/doris-matsui/M001163",
        "value": 10.1191
    },
    {
        "attributes": {
            "modularity_class": 1
        },
        "id": "137",
        "itemStyle": {
            "normal": {
                "borderColor": "rgb(255,0,0)",
                "color": "rgb(255,190,190)"
            }
        },
        "category": "众议院",
        "name": "Good, Bob",
        "symbolSize": 21.3684,
        "url": "https://www.congress.gov/member/bob-good/G000595",
        "value": 10.6842
    }],
    };

    const categories = [
      {
        name: '众议院',
      },
      {
        name: '美中战略竞争委员会',
      },
    ];

    myChart.showLoading();
    myChart.hideLoading();

    graph.nodes.forEach(function (node) {
      if (node.attributes.modularity_class == 0) {
        node.itemStyle = null;
        // Use random x, y
        node.x = node.y = null;
        node.draggable = true;
        node.label = {
          normal: {
            show: false,
          },
        };
      } else {
        node.itemStyle = null;
        // Use random x, y
        node.x = node.y = null;
        node.draggable = true;
        node.label = {
          normal: {
            show: node.symbolSize > 10,
          },
        };
      }
    });

    const option = {
      title: {
        text: '众议院影响力网络',
        subtext: 'Circular layout',
        top: 'bottom',
        left: 'right',
      },
      tooltip: {},
      legend: [
        {
          data: categories.map(function (a) {
            return a.name;
          }),
        },
      ],
      animationDurationUpdate: 1500,
      animationEasingUpdate: 'quinticInOut',
      color: ['#ed4e3e', '#b51404'],
      series: [
        {
          type: 'graph',
          layout: 'circular',
          circular: {
            rotateLabel: true,
          },
          data: graph.nodes,
          links: graph.links,
          categories: categories,
          roam: true,
          focusNodeAdjacency: true,
          label: {
            position: 'right',
            formatter: '{b}',
          },
          lineStyle: {
            color: 'source',
            curveness: 0.3,
          },
        },
      ],
    };

    myChart.setOption(option);

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', myChart.resize);

    // Cleanup when unmounting
    return () => {
      myChart.dispose();
    };
  }, []);

  return (
    <div className="chordrep-container" style={chartContainerStyle}>
      <div id="container" style={{ width: '100%', height: '93%' }}></div>
    </div>
  );
};

const chartContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh'
  };

export default ChordRep;
