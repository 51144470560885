import React, { useRef } from "react";
import { Card, Popover } from "antd";

function ChartCard(props) {
    if (props.description) {
        return (
            <Card title={
                <Popover placement="bottom" content={props.description}>
                    <div style={{ width: '100%', cursor: 'pointer' }}>
                        {props.title}
                    </div>
                </Popover>}
                headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
                style={{ width: '100%', height: '100%'}}
            >
                {props.children}
            </Card>
        )
    }
    return (
        <Card title={props.title}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
        >
            {props.children}
        </Card>
    );
};

export default ChartCard;