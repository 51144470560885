import React, { useEffect, useState, useRef } from "react";
import { Select, Card, Popover } from "antd";
import * as d3 from "d3";
import "./style.css"

//影响力小数点后四舍五入取三位
function formatInfluence(influence) {
    influence = parseFloat(influence);
    // 使用 toFixed 将数字保留小数点后三位
    if (!isNaN(influence)) {
        influence = parseFloat(influence.toFixed(3));
    }
    return influence;
}

//改写日期
function formatDate(originalDateStr) {
    // 使用Date对象解析日期
    var originalDate = new Date(originalDateStr);
    // 获取年、月和日
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的，因此需要加1，并确保两位数字
    var day = originalDate.getDate().toString().padStart(2, '0'); // 确保两位数字
    // 构建格式化后的日期字符串
    var formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
}

//展示作者省略
function formatAuthor(info) {
    const titleLength = info.title.length
    const authorLength = info.author.length
    const suitableLength = titleLength > 26 ? 17 : titleLength - 7
    if (info.author) {
        if (suitableLength < 10) return `${info.time} ${info.author.substring(0, 10)} ...`
        else return authorLength > suitableLength ? `${info.time} ${info.author.substring(0, suitableLength)} ...` :
            `${info.time}  ${info.author}`;
    } else {
        return `${info.time}  No Author`
    }
}

//按照影响力由大到小排序
function sortInfluence(data) {
    let newData = data.sort(function (a, b) {
        return b.influence - a.influence;
    });
    return newData;
}

//跳转文章页面
function jumpPaperWebpage() {
    d3.selectAll(".paper-title")
        .on("click", function () {
            // 在点击时跳转到指定的URL
            var paperUrl = d3.select(this).attr("paperUrl");
            console.log("Open Website:" + paperUrl)
            if (paperUrl) {
                window.open(paperUrl, "_blank");
            }
        });
}

const MediaBarChart = (props) => {
    const containerRef = useRef();
    const [listHeight, setListHeight] = useState(100);
    const items = [
        {
            "label": "1980-至今",
            "value": "All"
        },
        {
            "label": "2009-2017",
            "value": "2009-2017"
        },
        {
            "label": "2017-2021",
            "value": "2017-2021"
        },
        {
            "label": "2021-至今",
            "value": "2021"
        },
        {
            "label": "近一年",
            "value": "Year"
        },
        {
            "label": "近一月",
            "value": "Month"
        },
        {
            "label": "近一周",
            "value": "Week"
        },
    ]
    const margin = 5
    const width = window.innerWidth * 0.17;
    const gHeight = 60
    const textHeight = 48
    const rectHeight = 12
    const xscale = d3.scaleLinear().range([50, width + 50]);
    let TheNewYorkTimesData, TheWallStreetData, ChicagoTribuneData, TheWashingtonPostData, LosAngelesTimesData;
    let nytSrc, wsjSrc, ctSrc, wpSrc, ltSrc;

    //初始化文件路径
    function initSrc() {
        nytSrc = `data/MediumArticles/The New York Times/${timeType}.csv`;
        wsjSrc = `data/MediumArticles/The Wall Street Journal/${timeType}.csv`;
        ctSrc = `data/MediumArticles/Chicago Tribune/${timeType}.csv`;
        wpSrc = `data/MediumArticles/The Washington Post/${timeType}.csv`;
        ltSrc = `data/MediumArticles/Los Angeles Times/${timeType}.csv`;
    }

    //处理数据函数
    function formatData(data) {
        var newData = sortInfluence(data)
        for (var i = 0; i < newData.length; i++) {
            newData[i].influence = formatInfluence(newData[i].influence);
        }
        return newData;
    }

    //绘制一张图表函数
    function drawChart(data, chartName) {
        xscale.domain([0, 1.7]);
        var svg = d3.select(`#${chartName}`)
            .append("svg")
            .attr("width", width)
            .attr("height", gHeight * data.length);

        var g = svg.selectAll("g")
            .data(data)
            .enter()
            .append("g")
            .attr("transform", function (d, i) {
                return `translate(0, ${i * gHeight + textHeight})`;
            });

        g.append("rect")
            .attr("width", function (data) {
                return xscale(data.influence);
            })
            .attr("height", rectHeight)
            .attr("fill", function (d) {
                return '#60A2CD';
            });

        //显示文章标题
        g.append("text")
            .attr("x", 0)
            .attr("y", -20)
            .attr("font-size", "12px")
            .attr("font-weight", "bold")
            .attr("paperUrl", function (d) {
                if (d.url) return d.url;
                else return "";
            })
            .attr("text-decoration", function (d) {
                if (d.url) return "underline";
                else return "";
            })
            .text(function (d) {
                return d.title.length > 26 ? d.title.substring(0, 26) + "..." : d.title;
            })
            .classed("paper-title", true);

        //显示作者和文章日期
        g.append("text")
            .attr("x", 0)
            .attr("y", -7)
            .attr("font-size", "10px")
            .attr("fill", "grey")
            .text(function (d) {
                return formatAuthor(d)
            })

        //展示文章的影响力
        g.append("text")
            .attr("x", function (d) {
                return (xscale(d.influence) + 5);
            })
            .attr("y", rectHeight / 2 + 3)
            .attr("font-size", "x-small")
            .text(function (d) {
                return d.influence;
            });
    }

    function clear() {
        const chartDivs = document.querySelectorAll(".chart-div");
        chartDivs.forEach((chartDiv) => {
            chartDiv.innerHTML = "";
        });
    }
    function draw() {
        initSrc();
        clear();
        d3.csv(nytSrc).then(function (d) {
            TheNewYorkTimesData = formatData(d)
            drawChart(TheNewYorkTimesData, "nyt-chart")
            jumpPaperWebpage();
        });
        d3.csv(wsjSrc).then(function (d) {
            TheWallStreetData = formatData(d)
            drawChart(TheWallStreetData, "wsj-chart")
            jumpPaperWebpage();
        });
        d3.csv(ctSrc).then(function (d) {
            ChicagoTribuneData = formatData(d)
            drawChart(ChicagoTribuneData, "ct-chart")
            jumpPaperWebpage();
        });
        d3.csv(wpSrc).then(function (d) {
            TheWashingtonPostData = formatData(d)
            drawChart(TheWashingtonPostData, "wp-chart")
            jumpPaperWebpage();
        });
        d3.csv(ltSrc).then(function (d) {
            LosAngelesTimesData = formatData(d)
            drawChart(LosAngelesTimesData, "lt-chart")
            jumpPaperWebpage();
        });
    }

    const [timeType, setTimeType] = useState('All');
    const handleTimeChange = (value) => {
        setTimeType(value);
        console.log(value, "value")
        console.log(timeType, "timeTypeNow")
        // draw();
    }

    useEffect(() => {
        draw();
        setListHeight(containerRef.current.clientHeight - 60)
    }, [timeType])



    return (
        <Card title={<Popover placement="bottom" content='提出了一个综合的影响力算法，该算法综合考虑了多个关键指标，如报道的字数、文章类型、章节、所在的页码位置、涉及的主题以及特定涉华主题词的出现频率。为了确保这些指标在算法中的相对重要性得到恰当的体现，我们采用了知识驱动的赋权方法，结合了统计学原理，对每个变量的权重进行了精确设定。采用了人工审核与机器学习相结合的策略验证和优化模型。'>
            <div style={{ width: '100%', cursor: 'pointer' }}>
                {props.title}
            </div>
        </Popover>}
            headStyle={{ backgroundColor: '#D9D9D9', height: '40px', minHeight: '40px', textAlign: 'left' }}
            style={{ width: '100%', height: '100%' }}
            extra={
                <Select
                    size='large'
                    defaultValue={'All'}
                    options={items}
                    onChange={handleTimeChange}
                    style={{ minWidth: "100px", height: "30px" }}
                >
                </Select>
            }
            ref={containerRef}
        >
            <div class="media-barchart-container" style={{ overflow: "auto", height: listHeight }}>
                <div class="one-medium-charts-div" style={{ height: listHeight - 20 }}>
                    <div class="medium-logo-div">
                        <img style={{ height: "30px" }} src="./icons/The New York Times Logo.png" />
                    </div>
                    <div class="chart-div" id="nyt-chart" style={{ height: listHeight - 80 }}>
                    </div>
                </div>
                <div class="one-medium-charts-div" style={{ height: listHeight - 20 }}>
                    <div class="medium-logo-div">
                        <img style={{ height: "30px" }} src="./icons/The Wall Street Journal Logo.png" />
                    </div>
                    <div class="chart-div" id="wsj-chart" style={{ height: listHeight - 80 }}>
                    </div>
                </div>
                <div class="one-medium-charts-div" style={{ height: listHeight - 20 }}>
                    <div class="medium-logo-div">
                        <img src="./icons/Chicago Tribune Logo.png" alt="" />
                    </div>
                    <div class="chart-div" id="ct-chart" style={{ height: listHeight - 80 }}>
                    </div>
                </div>
                <div class="one-medium-charts-div" style={{ height: listHeight - 20 }}>
                    <div class="medium-logo-div">
                        <img src="./icons/The Washington Post Logo.png" alt="" />
                    </div>
                    <div class="chart-div" id="wp-chart" style={{ height: listHeight - 80 }}>
                    </div>
                </div>
                <div class="one-medium-charts-div" style={{ height: listHeight - 20 }}>
                    <div class="medium-logo-div">
                        <img src="./icons/Los Angeles Times Logo.png" alt="" />
                    </div>
                    <div class="chart-div" id="lt-chart" style={{ height: listHeight - 80 }}>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default MediaBarChart;