import React, { useEffect } from "react";
import * as d3 from "d3";

const MediaTheme = () => {
    return (
        <div>
            <img src="image34.GIF" style={{width: window.innerWidth * 0.97, height:window.innerHeight * 0.4}}></img>
            <div style={{display: "flex"}}>
                <img src="image33.GIF" style={{width: window.innerWidth * 0.47, height:window.innerHeight * 0.4}}></img>
                <img src="image35.GIF" style={{width: window.innerWidth * 0.50, height:window.innerHeight * 0.4}}></img>
            </div>
        </div>
    );
}

export default MediaTheme