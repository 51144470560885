import React, {useState} from "react";
import "./style.css"

const EliteOverview = () => {
    const [eliteData, setEliteData] = useState([
        { color: "#656B7C", num: 410, name: "行政精英",style:"middle-card-low2",upStyle:"middle-card-up2",row:1 },
        { color: "#3E455E", num: 1901, name: "立法精英",style:"middle-card-low2" ,upStyle:"middle-card-up2", row:1},
        { color: "#ECECEC", num: 16, name: "司法精英" ,style:"middle-card-low",upStyle:"middle-card-up", row:1 },
        { color: "#9CA1B3", num: 219, name: "经济精英",style:"middle-card-low2" ,upStyle:"middle-card-up2", row:1},
        { color: "#B4B7C5", num: 151, name: "智库精英" ,style:"middle-card-low2",upStyle:"middle-card-up2", row:2},
        { color: "#DADADF", num: 50, name: "知华精英" ,style:"middle-card-low3",upStyle:"middle-card-up", row:2 },
        { color: "#8D94A5", num: 167, name: "学术精英" ,style:"middle-card-low2",upStyle:"middle-card-up2", row:2 },
        { color: "#B9BCC2", num: 116, name: "退隐精英" ,style:"middle-card-low2",upStyle:"middle-card-up2" , row:2}
      ]);
    return (
        <div id="elite-overview">
            <div className="asides">
                <div className="aside1">
                    <div className="aside-up">2980</div>
                    <div className="aside-low">人物数量</div>
                </div>
                <div className="aside">
                    <div class="aside-up">29</div>
                    <div class="aside-low">分析维度数量</div>
                </div>
            </div>

            <div className="middle">
                <div className="middle-row1">
                    {
                        eliteData.map((item)=>(
                            item.row===1?
                            <div className="middle-card" style={{backgroundColor:item.color}}>
                                <div class={item.upStyle}>{item.num}</div>
                                <div class={item.style}>{item.name}</div>
                            </div >:null
                        ))
                    }
                </div>
                <div className="middle-row2">
                {
                        eliteData.map((item)=>(
                            item.row===2?
                            <div className="middle-card" style={{backgroundColor:item.color}}>
                                <div class={item.upStyle}>{item.num}</div>
                                <div class={item.style}>{item.name}</div>
                            </div >:null
                        ))
                    }
                </div>
                {/* <div className="middle-up">
                    {
                        eliteData.map((item)=>(
                            <div className="middle-card" style={{backgroundColor:item.color}}>
                                <div class={item.upStyle}>{item.num}</div>
                                <div class={item.style}>{item.name}</div>
                            </div >
                        ))
                    }
                </div> */}
            </div>
            <div className="aside2">
                    <div class="aside-up">2008-2023</div>
                    <div class="aside-low">时间范围</div>
            </div>
        </div>
    );
}

export default EliteOverview;