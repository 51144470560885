import React, { useEffect } from "react";
import * as d3 from "d3";
import "./style.css"

//影响力小数点后四舍五入取三位
function formatInfluence(influence) {
    influence = parseFloat(influence);
    // 使用 toFixed 将数字保留小数点后三位
    if (!isNaN(influence)) {
        influence = parseFloat(influence.toFixed(3));
    }
    return influence;
}

//改写日期
function formatDate(originalDateStr) {
    // 使用Date对象解析日期
    var originalDate = new Date(originalDateStr);
    // 获取年、月和日
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的，因此需要加1，并确保两位数字
    var day = originalDate.getDate().toString().padStart(2, '0'); // 确保两位数字
    // 构建格式化后的日期字符串
    var formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
}

//展示作者省略
function formatAuthor(info) {
    const titleLength = info.title.length
    const authorLength = info.author.length
    const suitableLength = titleLength > 26 ? 17 : titleLength - 7
    if (info.author) {
        if (suitableLength < 10) return `${info.time} ${info.author.substring(0, 10)} ...`
        else return authorLength > suitableLength ? `${info.time} ${info.author.substring(0, suitableLength)} ...` :
            `${info.time}  ${info.author}`;
    } else {
        return `${info.time}  No Author`
    }
}

//按照影响力由大到小排序
function sortInfluence(data) {
    let newData = data.sort(function (a, b) {
        return b.influence - a.influence;
    });
    return newData;
}

//绘制legend
function drawLegend(legendData, colorScale) {
    var legendContainer = d3.select(".legends");

    // 创建一个SVG元素作为图例的容器
    var svg = legendContainer.append("svg")
        .attr("width", 350) // 设置SVG宽度为100%
        .attr("height", 22); // 设置SVG高度

    // 创建图例矩形和文本
    var legend = svg.selectAll(".legend")
        .data(legendData)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d, i) {
            return "translate(" + i * 100 + ",0)";
        });

    // 添加彩色方块
    legend.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 18)
        .attr("height", 20)
        .style("fill", colorScale);

    // 添加文本标签
    legend.append("text")
        .attr("x", 25)
        .attr("y", 15)
        .attr("font-size", "16px")
        .attr("font-family", "Source Han Sans")
        .text(function (d) {
            return d;
        });
}

//跳转文章页面
function jumpPaperWebpage() {
    d3.selectAll(".paper-title")
        .on("click", function () {
            // 在点击时跳转到指定的URL
            var paperUrl = d3.select(this).attr("paperUrl");
            console.log("Open Website:" + paperUrl)
            if (paperUrl) {
                window.open(paperUrl, "_blank");
            }
        });
}

const ArticleInfluence = () => {
    useEffect(() => {
        const gHeight = 52
        const textHeight = 40
        const rectHeight = 12
        const width = '90%';
        const height = 280;
        //解析csv数据
        let data;
        var legendData = ["经济政策", "全球治理", "国际事务"];
        // 颜色比例尺
        var colorScale = d3.scaleOrdinal()
            .domain(legendData)
            .range(["#60A2CD", "#836F93", "#D9B52C"]);
        d3.csv("ThinkTanks.csv").then(function (d) {
            data = formatData(d);
            drawDiv(data)
            jumpPaperWebpage();
            drawLegend(legendData, colorScale);
        });

        function formatData(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].influence = formatInfluence(data[i].influence);
            }
            return data;
        }

        const xscale = d3.scaleLinear().range([20, 135]);

        //绘制div
        function drawDiv(data) {
            // 使用循环生成div并添加到容器中
            const container = document.getElementById("article-influence-chart-container");
            for (let i = 0; i < 20; i++) {
                //外边框
                const outerDiv = document.createElement("div");
                outerDiv.className = "box";
                container.appendChild(outerDiv);
                //标题div
                const titleDiv = document.createElement("div");
                titleDiv.classList.add("title-box");
                outerDiv.appendChild(titleDiv);
                //智库logo
                const logoImg = document.createElement("img");
                logoImg.classList.add("logo-img")
                const chartName = data[i * 5].name.replace(/\s/g, '')
                const chartId = data[i * 5]._id
                logoImg.src = `./icons/ThinkTanksLogos/${chartId}.svg`
                titleDiv.appendChild(logoImg);
                //图表div
                const chartDiv = document.createElement("div");
                chartDiv.classList.add("chart-box");
                chartDiv.setAttribute("id", chartName);
                outerDiv.appendChild(chartDiv);
                drawChart(i, chartName)
            }
        }
        //绘制图表
        function drawChart(id, chartName) {
            // xscale.domain([d3.min(data, (d) => d.influence), d3.max(data, (d) => d.influence)]);
            xscale.domain([0, 75]);
            var svg = d3.select(`#${chartName}`)
                .append("svg")
                .attr("width", width)
                .attr("height", height);

            const newData = data.slice(id * 5, id * 5 + 5)
            var g = svg.selectAll("g")
                .data(newData)
                .enter()
                .append("g")
                .attr("transform", function (d, i) {
                    return `translate(0, ${i * gHeight + textHeight})`;
                });

            g.append("rect")
                .attr("width", function (data) {
                    return xscale(data.influence);
                })
                .attr("height", rectHeight)
                .attr("fill", function (d) {
                    return colorScale(d.theme);
                })

            //显示文章标题
            g.append("text")
                .attr("x", 0)
                .attr("y", -18)
                .attr("font-size", "12px")
                .attr("font-weight", "bold")
                .attr("font-family", "Source Han Sans")
                .attr("paperUrl", function (d) {
                    if (d.reportUrl) return d.reportUrl;
                    else return "";
                })
                .attr("text-decoration", function (d) {
                    if (d.reportUrl) return "underline";
                    else return "";
                })
                .text(function (d) {
                    // 显示省略号的文本
                    if (d.title) {
                        return d.title.length > 26 ? d.title.substring(0, 26) + "..." : d.title;
                    } else {
                        return "No Title"
                    }
                })
                .classed("paper-title", true);

            //显示作者
            g.append("text")
                .attr("x", 0)
                .attr("y", -5)
                .attr("font-size", "10px")
                .attr("font-family", "Source Han Sans")
                .attr("fill", "grey")
                .text(function (d) {
                    if (d.author) {
                        return d.author.length > 30 ? d.author.substring(0, 30) + "..." : d.author;
                    } else {
                        return "No Author"
                    }
                })

            //展示文章的影响力
            g.append("text")
                .attr("x", function (d) {
                    return (xscale(d.influence) + 5);
                })
                .attr("y", rectHeight / 2 + 3)
                .attr("font-size", "x-small")
                .text(function (d) {
                    return d.influence;
                })
                .attr("fill", function (d) {
                    return colorScale(d.theme);
                });
        }
    }, []);

    return (
        <div style={{height: "100%" }}>
            <div class="legend-container" id="legend-container">
                <div class="legends" id="legends"></div>
            </div>
            <div  id="article-influence-chart-container" style={{ overflow: "auto", maxHeight: "95%" }}>
            </div>
        </div>
    );
}

export default ArticleInfluence;