import React, { useEffect, useState } from "react";
import * as d3 from "d3";
// import "./style.css"

const EliteScatterPlot = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    useEffect(() => {
        const margin = { top: 40, right: 20, bottom: 80, left: 30 };
        const width = window.innerWidth * 0.59 - margin.left - margin.right;
        const height = window.innerHeight * 0.59 - margin.top - margin.bottom;
        
        // Create an SVG element for the scatter plot.
        const svg = d3.select("#elite-scatter-chart-container")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("style", "max-width: 100%; height: auto; ")
            .attr("viewBox", [0, 0, width+ margin.left + margin.right, height+ margin.top + margin.bottom])
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);
        
        // Define your custom colors.
        const customColors = {
            '司法精英': '#a65628',
            '行政精英': '#ff7f00',
            '经济精英': '#377eb8',
            '知识精英': '#984ea3',
            '国会精英': '#f781bf',
            '华裔精英': '#e41a1c',
            '退隐精英': '#3AB09B',
            '智库精英': '#4daf4a'
        };
        
        // Create a tooltip container div
        const tooltip = d3.select("#elite-scatter-chart-container")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0); // Initially hide the tooltip
        
        // Load data from data.csv and create the scatter plot.
        d3.csv("elite-scatter.csv").then(function(data) {
            // Parse attitude and significance as numbers.
            data.forEach(function(d) {
                d.attitude = +d.attitude;
                d.significance = +d.significance;
            });
        
            // Assign custom colors to categories based on their index.
            data.forEach(function(d) {
                d.color = customColors[d.category];
            });
        
            // Add background colors to the four quadrants.
            svg.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#FBF8EA");
            
            svg.append("rect")
                .attr("x", 0)
                .attr("y", height/2)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#EDF0FE");
            
            svg.append("rect")
                .attr("x", width/2)
                .attr("y", 0)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#FCEFEE");
            
            svg.append("rect")
                .attr("x", width/2)
                .attr("y", height/2)
                .attr("width", width/2)
                .attr("height", height/2)
                .style("fill", "#ECF7F1");
        
            svg.append("text")
                .attr("x", 5)
                .attr("y", height/2 - 10)
                .style("text-anchor", "start")
                .text("斗争域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            svg.append("text")
                .attr("x", width - margin.right + 15)
                .attr("y", 0.5*height - 10)
                .style("text-anchor", "end")
                .text("团结域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            svg.append("text")
                .attr("x", 5)
                .attr("y", height - 10)
                .style("text-anchor", "start")
                .text("风险域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            svg.append("text")
                .attr("x", width - margin.right + 15)
                .attr("y", height - 10)
                .style("text-anchor", "end")
                .text("支持域")
                .style("fill", "#B4B4B4")
                .style("font-size", "70px")
                .style("font-weight", "bold")
                .style("opacity", 0.3);
        
            // Create scales for x and y axes.
            const xScale = d3.scaleLinear()
                .domain([-0.8, 1])
                .range([0, width]);
        
            const yScale = d3.scaleLinear()
                .domain([-0.4, 1])
                .range([height, 0]);
        
            // Create x and y axes.
            const xAxis = d3.axisBottom(xScale).ticks(20);
            const yAxis = d3.axisRight(yScale);
        
            // Append axes to the SVG.
            svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0, ${height})`)
                .call(xAxis)
                
            // Add grid lines
            svg.selectAll(".x-axis")
                .append("g")
                .attr("class", "grid-lines")
                .selectAll("line")
                .data(xScale.ticks(20))
                .enter()
                .append("line")
                .attr("class", "grid-line")
                .attr("x1", d => xScale(d))
                .attr("x2", d => xScale(d))
                .attr("y1", -height)
                .attr("y2", 0) 
                .style("stroke", "#D3D6DF")
                .style("stroke-dasharray", "2,2");
        
            svg.append("text")
                .attr("class", "x-label")
                .attr("x", width-20)
                .attr("y", height+40)
                .style("text-anchor", "middle")
                .text("对华态度")
                .style("font-size", "14px");
        
        
            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${width/2}, 0)`)
                .call(yAxis);
                
            svg.selectAll(".y-axis")
                .append("g")
                .attr("class", "grid-lines")
                .selectAll("line")
                .data(yScale.ticks())
                .enter()
                .append("line")
                .attr("class", "grid-line")
                .attr("x1", -width/2)
                .attr("x2", width/2)
                .attr("y1", d => yScale(d)) 
                .attr("y2", d => yScale(d)) 
                .style("stroke", "#D3D6DF")
                .style("stroke-dasharray", "2,2");
        
            svg.append("text")
                .attr("class", "y-label")
                .attr("x", width/2)
                .attr("y", -20)
                .style("text-anchor", "middle")
                .text("影响力")
                .style("font-size", "14px");
        
        
            // Create legend for categories.
            const categories = data.map(d => d.category);
            const uniqueCategories = [...new Set(categories)];
        
            const legend = svg.selectAll(".legend")
            .data(uniqueCategories)
            .enter()
            .append("g")
            .attr("class", "legend")
            .on("click", handleLegendClick);

            console.log(selectedCategory)
        
            // Calculate the total width of the legend
            const legendWidth = uniqueCategories.length * 97;
            
            // Position the legend in the center horizontally
            legend.attr("transform", function(d, i) {
                const xOffset = (width - legendWidth) / 2; // Calculate the horizontal offset
                console.log(xOffset)
                return `translate(${xOffset + i * 97}, ${height + 50})`;
            });        
        
            legend.append("circle") 
                .attr("cx", 7.5) 
                .attr("cy", 7.5)
                .attr("r", 7.5)
                .style("fill", function(d){
                    if (selectedCategory === null){
                        return customColors[d];
                    } else{
                        if (d === selectedCategory){
                            return customColors[d]
                        }else{
                            return '#ccc'
                        }
                    }
                });
        
            legend.append("text")
                .attr("x", 50)
                .attr("y", 7.5)
                .attr("dy", ".35em")
                .style("text-anchor", "middle")
                .style("font-size", "12px")
                .text(d => d);
        
            // Create circles for the scatter plot.
            svg.selectAll("circle2")
                .data(data.filter(d => selectedCategory === null || d.category === selectedCategory))
                .enter()
                .append("circle")
                .attr("cx", d => xScale(d.attitude))
                .attr("cy", d => yScale(d.significance))
                .attr("r", 5)
                .style("stroke", "white")
                .style("stroke-width", 0.2)
                .style("fill", d => d.color)
                .on("mouseover", function (event, d) {
                    // Show the tooltip when hovering over a circle
                    const [mouseX, mouseY] = d3.pointer(event);
                    // Position the tooltip next to the mouse pointer and near the data point
                    this.getBoundingClientRect();
                    const myData =  d3.select(this).data()[0]
        
                    // Set tooltip position initially at the mouse position.
                    let tooltipX = mouseX+50; // Adjust the horizontal offset
                    let tooltipY = mouseY+50; // Adjust the vertical offset
        
                    tooltip.transition()
                        .duration(200)
                        .style("opacity", 0.9);
        
                    tooltip.html(`影响力: ${myData.significance}<br>对华态度: ${myData.attitude}<br>中文名: ${myData.Chinese}<br>精英类型: ${myData.category}<br>部门: ${myData.department}<br>职务: ${myData.job}<br>党派: ${myData.party}`)
                        .style("left", tooltipX + "px")
                        .style("top", tooltipY + "px") // Adjust the top position
                        .style("font-size", "12px");
        
                    // Highlight the data point
                    d3.select(this)
                        .attr("r", 8) // Increase the radius
                })
                .on("mouseout", function(d) {
                        // Hide the tooltip when moving the mouse away from a circle
                        tooltip.transition()
                            .duration(500)
                            .style("opacity", 0);
        
                        // Restore the data point's appearance
                        d3.select(this)
                            .attr("r", 5)
                });
        });
    }, [selectedCategory]);

    // Function to handle legend click events
    const handleLegendClick = (event, clickedCategory) => {
        d3.select("#elite-scatter-chart-container").selectAll("*").remove();
        setSelectedCategory(selectedCategory === clickedCategory ? null : clickedCategory);
    };

    return (
        <div id="elite-scatter-chart-container" style={{ maxHeight: "100%" ,height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}></div>
    )
}

export default EliteScatterPlot;